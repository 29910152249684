import React, {useContext, useState, useEffect} from 'react';
import {fetchDataFromAPI} from '../../../utils/api';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';

const EditIntensityPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const state = useParams();
  console.log('state', state?.id);

  useEffect(() => {
    getIntensity();
    // getData();
  }, []);

  // get intensity api call
  const getIntensity = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`intensity/getintensity?id=${state?.id}`, 'get', '', '')
      .then((res) => {
        console.log('res -->', res?.data);
        let data = res?.data;
        setData(res?.data);
        formik.setFieldValue('intensity', data?.intensity_name);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      intensity: '',
    },
    validationSchema: Yup.object({
      intensity: Yup.string().required('Please enter color type'),
    }),
    onSubmit: (values) => {
      const {intensity} = values;

      const body = {
        intensity_name: intensity,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI(
          `intensity/updateintensity?id=${state?.id}`,
          'put',
          body,
          '',
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/intensity');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/intensity">Intensity</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Intensity</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="intensity" className="form-label">
                          Intensity
                        </label>

                        <input
                          name="intensity"
                          type="text"
                          className="form-control"
                          id="intensity"
                          value={formik.values.intensity}
                          // onChange={(e) => {
                          //   formik.setFieldValue(
                          //     'intensity',
                          //     e.target.value?.trimStart(),
                          //   );
                          // }}

                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[A-Za-z\s]*$/.test(value)) {
                              formik.setFieldValue(
                                'intensity',
                                value.trimStart(),
                              );
                            }
                          }}
                        />
                        {formik.touched.intensity &&
                          formik.errors.intensity && (
                            <div className="text-danger">
                              {formik.errors.intensity}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Edit Intensity"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditIntensityPage;
