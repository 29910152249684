import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const EditGradingPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const [data, setData] = useState([]);

  const state = useParams();
  console.log('state', state?.id);

  useEffect(() => {
    getGrading();
  }, []);

  const getGrading = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`grading/get?id=${state?.id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res -->', res?.data);
        let data = res?.data;
        setData(res?.data);
        formik.setFieldValue('grading', data?.grading);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      grading: '',
    },
    validationSchema: Yup.object({
      grading: Yup.string().required('Please enter Grading'),
    }),
    onSubmit: (values) => {
      const {grading} = values;

      const body = {
        grading: grading,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI(
          `grading/update?id=${state?.id}`,
          'put',
          body,
          user?.authToken,
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/grading');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/grading">Grading</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Edit Grading</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="from" className="form-label">
                            Grading
                          </label>

                          <input
                            name="color"
                            type="text"
                            min={0}
                            className="form-control"
                            id="color"
                            value={formik.values.grading}
                            // onChange={(e) => {
                            //   formik.setFieldValue(
                            //     "grading",
                            //     e.target.value?.trimStart()
                            //   );
                            // }}

                            onChange={(e) => {
                              const value = e.target.value;

                              // Check if the value contains only letters and spaces
                              if (/^[A-Za-z\s]*$/.test(value)) {
                                // Trim leading whitespace
                                const trimmedValue = value.trimStart();

                                // Capitalize the first letter
                                const capitalizedValue =
                                  trimmedValue.charAt(0).toUpperCase() +
                                  trimmedValue.slice(1);

                                formik.setFieldValue(
                                  'grading',
                                  capitalizedValue,
                                ); // Set capitalized value to 'grading' field
                              }
                            }}
                          />
                          {formik.touched.grading && formik.errors.grading && (
                            <div className="text-danger">
                              {formik.errors.grading}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Edit Grading"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditGradingPage;
