import React, {useContext, useState, useEffect} from 'react';
import {fetchDataFromAPI} from '../../../utils/api';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const EditCoreFeturesPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const user = JSON.parse(getUser());

  const state = useParams();
  console.log('state', state?.id);

  useEffect(() => {
    dataContext?.setLoading(true);
    getFeatures();
    getData();
  }, []);

  // get  api call
  const getFeatures = () => {
    fetchDataFromAPI(
      `features/getAllfeatures?id=${state?.id}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res -->', res?.data);
        let data = res?.data;
        setData(res?.data);
        formik.setFieldValue('corefeature', data?.name);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const getData = () => {
    formik.setFieldValue('corefeature', data?.name);
  };

  const formik = useFormik({
    initialValues: {
      corefeature: '',
    },
    validationSchema: Yup.object({
      corefeature: Yup.string().required('Please enter color type'),
    }),
    onSubmit: (values) => {
      const {corefeature} = values;

      const body = {
        name: corefeature,
      };

      console.log('body', body);
      dataContext.setLoading(true);
      try {
        fetchDataFromAPI(
          `features/updatecorefeatures?id=${state?.id}`,
          'put',
          body,
          user?.authToken,
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/core-feature');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/core-feature">Core Feature</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Core Feature</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="corefeature" className="form-label">
                          Core Feature
                        </label>

                        <input
                          name="corefeature"
                          type="text"
                          className="form-control"
                          id="corefeature"
                          value={formik.values.corefeature}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'corefeature',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.corefeature &&
                          formik.errors.corefeature && (
                            <div className="text-danger">
                              {formik.errors.corefeature}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Edit Core Feature"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditCoreFeturesPage;
