import React, {useContext} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import Processing from '../../../components/Processing/Processing';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {fetchDataFromAPI} from '../../../utils/api';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const AddCertCommentPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Please enter CertComment'),
    }),
    onSubmit: (values) => {
      const {comment} = values;

      const body = {
        comment: comment,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('comment/add', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/certcomment');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/certcomment">Cert Comment</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Cert Comment</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="from" className="form-label">
                            Cert Comment
                          </label>

                          <input
                            name="color"
                            type="text"
                            className="form-control"
                            id="color"
                            value={formik.values.comment}
                            // onChange={(e) => {
                            //   formik.setFieldValue(
                            //     'comment',
                            //     e.target.value?.trimStart(),
                            //   );
                            // }}

                            onChange={(e) => {
                              const value = e.target.value;

                              // Check if the value contains only letters and spaces
                              if (/^[A-Za-z\s]*$/.test(value)) {
                                // Trim leading whitespace
                                const trimmedValue = value.trimStart();

                                // Capitalize the first letter
                                const capitalizedValue =
                                  trimmedValue.charAt(0).toUpperCase() +
                                  trimmedValue.slice(1);

                                formik.setFieldValue(
                                  'comment',
                                  capitalizedValue,
                                ); // Set capitalized value to 'comment' field
                              }
                            }}
                          />
                          {formik.touched.comment && formik.errors.comment && (
                            <div className="text-danger">
                              {formik.errors.comment}
                            </div>
                          )}
                        </div>

                        {/* <div className="col-md-6">
                      <label htmlFor="to" className="form-label">
                        To
                      </label>

                      <input
                        name="to"
                        type="number"
                        className="form-control"
                        id="to"
                        value={formik.values.to}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "to",
                            e.target.value?.trimStart()
                          );
                        }}
                      />
                      {formik.touched.to && formik.errors.to && (
                        <div className="text-danger">
                          {formik.errors.to}
                        </div>
                      )}
                    </div> */}
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Add CertComment"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddCertCommentPage;
