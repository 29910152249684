import React, {useContext, useState, useEffect} from 'react';
import {fetchDataFromAPI} from '../../../utils/api';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const EditCaratPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());

  const [data, setData] = useState([]);

  const state = useParams();
  console.log('state', state?.id);

  useEffect(() => {
    getIntensity();
  }, []);

  // get intensity api call
  const getIntensity = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `carat/getcarat?id=${state?.id}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res -->', res?.data);
        let data = res?.data;
        setData(res?.data);
        formik.setFieldValue('from', data?.from);
        formik.setFieldValue('to', data?.to);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      from: '',
      to: '',
    },
    validationSchema: Yup.object({
      from: Yup.string().required('Please enter from'),
      to: Yup.string().required('Please enter to'),
    }),
    onSubmit: (values) => {
      const {from, to} = values;

      const body = {
        from: from,
        to: to,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI(`carat/updatecarat?id=${state?.id}`, 'put', body, '')
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/carat');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/carat">Carat</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Carat</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="from" className="form-label">
                          From
                        </label>

                        <input
                          name="from"
                          type="number"
                          min={0}
                          className="form-control"
                          id="from"
                          value={formik.values.from}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'from',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.from && formik.errors.from && (
                          <div className="text-danger">
                            {formik.errors.from}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="to" className="form-label">
                          To
                        </label>

                        <input
                          name="to"
                          type="number"
                          min={0}
                          className="form-control"
                          id="to"
                          value={formik.values.to}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'to',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.to && formik.errors.to && (
                          <div className="text-danger">{formik.errors.to}</div>
                        )}
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Edit Carat"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditCaratPage;
