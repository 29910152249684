import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import moment from 'moment';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

const CrownPage = () => {
  const navigate = useNavigate();
  const [crown, setCrown] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getCrown();
  }, []);

  // this use Effect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getCrown = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('crown', 'get', '', '')
      .then((res) => {
        console.log('res crown', res);
        setCrown(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`crown?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getCrown();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Crown</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-lg-between btn-View">
                    <div></div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn-custom"
                        onClick={() => navigate('/add crown')}>
                        Add Crown
                      </button>
                    </div>
                  </div>
                  <h5 className="card-title">Crown Data</h5>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Angle From</th>
                        <th scope="col">Angle To</th>
                        <th scope="col">Height From</th>
                        <th scope="col">Height To</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {crown.map((item, index) => (
                        <tr className="align-middle" key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.crown?.cr_angle?.from}</td>
                          <td>{item?.crown?.cr_angle?.to}</td>
                          <td>{item?.crown?.cr_height?.from}</td>
                          <td>{item?.crown?.cr_height?.to}</td>
                          <td>
                            {moment(item?.updatedAt).format('MMMM Do YYYY')}
                          </td>
                          <td>
                            {moment(item?.createdAt).format('MMMM Do YYYY')}
                          </td>
                          <td className="">
                            <div className="d-flex">
                              <div
                                className="edit-icon btn btn-sm btn-primary me-2"
                                role="button"
                                onClick={() => {
                                  navigate(`/edit Crown/${item?._id}`, {
                                    state: {user: item},
                                  });
                                }}>
                                <i className="bi bi-pencil-square"></i>
                              </div>
                              <div
                                role="button"
                                className="delete-icon btn btn-sm btn-danger"
                                onClick={() => {
                                  setDeleteId(item?._id);
                                }}>
                                <i className="bi bi-trash"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <DeleteModal
          show={dataContext.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Crown Data"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default CrownPage;
