import React, {useContext, useEffect, useState} from 'react';
import {fetchDataFromAPI} from '../../../utils/api';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';

const ContactForm = () => {
  const [contactus, setContactUs] = useState([]);
  console.log('contactussssss', contactus);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState(null);
  console.log('deleteiddddddd', deleteId);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    getContactUs();
  }, []);

  // this use Dffect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);
  const getContactUs = () => {
    dataContext?.setLoading(true);

    fetchDataFromAPI(`ContactUs`, 'get', '', '')
      .then((res) => {
        console.log('res contactus', res);
        setContactUs(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error contactus', error);
        dataContext?.setLoading(false);
      });
  };
  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`ContactUs?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getContactUs();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };
  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitreamente">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Contact Us </li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-lg-between btn-View">
                    <div></div>
                    {/* <div className="text-center">
                    <button
                      type="submit"
                      className="btn-custom"
                      onClick={() => navigate('/add-treatment')}>
                      Add Treatment
                    </button>
                  </div> */}
                  </div>
                  <h5 className="card-titreamente">Contact Us Data</h5>
                  {contactus?.length === 0 ? (
                    <p className="text-center">No data available</p>
                  ) : (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Message</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactus.map((item, index) => (
                          <tr className="align-middle" key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.first_name ? item?.first_name : '-'}</td>
                            <td>{item?.last_name ? item?.last_name : '-'}</td>
                            <td>{item?.email ? item?.email : '-'}</td>
                            <td>{item?.phone ? item?.phone : '-'}</td>
                            <td>{item?.message ? item?.message : '-'}</td>

                            <td className="">
                              <div className="d-flex">
                                <div
                                  className="edit-icon btn btn-sm btn-primary me-2"
                                  role="button"
                                  onClick={() => {
                                    navigate(`/view-contactus/${item?._id}`, {
                                      state: {item},
                                    });
                                  }}>
                                  <i class="bi bi-eye"></i>
                                </div>
                                {/* <div
                                role="button"
                                className="delete-icon btn btn-sm btn-danger"
                                onClick={() => {
                                  setDeleteId(item?._id);
                                }}>
                                <i className="bi bi-trash"></i>
                              </div> */}
                                <div
                                  role="button"
                                  className="delete-icon btn btn-sm btn-danger"
                                  // onClick={() => deleteContent(item?._id)}
                                  onClick={(e) => {
                                    setDeleteId(item?._id);
                                  }}>
                                  <i class="bi bi-trash"></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <DeleteModal
          show={dataContext.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Asking Data"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default ContactForm;
