import React, {useContext, useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {FormGroup, Pagination} from 'react-bootstrap';
import * as Yup from 'yup';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';
import moment from 'moment';

const EditUserVerifyPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [url, setURL] = useState(null);
  const user = JSON.parse(getUser());
  const [sales, setSales] = useState([]);
  const [coupon, setCoupon] = useState([]);
  const [plans, setPlans] = useState([]);
  const [data, setData] = useState([]);

  console.log('data', data);
  const [diamond, setDiamond] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const state = useParams();

  useEffect(() => {
    getEditUser();
    getSales();
    getCoupon();
    getPlans();
  }, []);

  useEffect(() => {
    getDiamond(currentPage);
  }, [currentPage]);

  // get Shape api call
  const getSales = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('contact', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res', res?.data);
        setSales(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const getCoupon = () => {
    dataContext?.setLoading(true);

    fetchDataFromAPI('planpurchase/coupon', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res coupan', res);
        setCoupon(res.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  // get Shape api call
  const getEditUser = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`getuser?id=${state?.id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res user-->', res?.data);
        setData(res?.data);
        formik.setFieldValue('first_name', res?.data?.users?.first_name);
        formik.setFieldValue('last_name', res?.data?.users?.last_name);
        formik.setFieldValue('email', res?.data?.users?.email);
        formik.setFieldValue('phone', res?.data?.users?.phone);
        formik.setFieldValue('account_type', res?.data?.users?.account_type);
        formik.setFieldValue('isverified', res?.data?.users?.isverified);
        formik.setFieldValue('rap_id', res?.data?.users?.rapnet_id);

        formik.setFieldValue(
          'additional_mark_up',
          res?.data?.users?.additional_mark_up,
        );
        formik.setFieldValue('mark_up', res?.data?.users?.mark_up);
        formik_coupon.setFieldValue(
          'coupon_id',
          res?.data?.planPurchase[0]?.coupon_id?._id,
        );

        formik_plan.setFieldValue(
          'plan_id',
          res?.data?.memberPlan?.plan_id?._id,
        );
        formik.setFieldValue('salesId', res?.data?.users?.contactsales_id?._id);
        formik.setFieldValue('coupon_id', res?.data?.users?.coupon_id);
        formik.setFieldValue('companyName', res?.data?.company?.name);
        formik.setFieldValue('companyEmail', res?.data?.company?.email);
        formik.setFieldValue('companyCountry', res?.data?.company?.country);
        formik.setFieldValue('companyState', res?.data?.company?.state);
        formik.setFieldValue('companyCity', res?.data?.company?.city);

        formik.setFieldValue('companyPhone', res?.data?.company?.phone);
        formik.setFieldValue('companyZip', res?.data?.company?.zip_code);
        formik.setFieldValue('companyWebsite', res?.data?.company?.website);
        formik.setFieldValue('gst_num', res?.data?.company?.gst_num);
        formik.setFieldValue(
          'gst_certificate',
          res?.data?.company?.gst_certificate,
        );
        formik.setFieldValue(
          'incorporation_certificate',
          res?.data?.company?.incorporation_certificate,
        );

        formik.setFieldValue('companyType', res?.data?.company?.type);
        formik.setFieldValue('companyAddress', res?.data?.company?.address);

        formik.setFieldValue('addressCountry', res?.data?.address?.country);
        formik.setFieldValue('addressState', res?.data?.address?.state);

        formik.setFieldValue('addressCity', res?.data?.address?.city);
        formik.setFieldValue('addressZipCode', res?.data?.address?.zipCode);
        formik.setFieldValue('address', res?.data?.address?.address);

        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      account_type: '',
      mark_up: 0,
      additional_mark_up: 0,
      salesId: '',
      rap_id: '',
      isverified: false,
      companyName: '',
      companyEmail: '',
      companyCountry: '',
      companyState: '',
      companyCity: '',
      companyPhone: '',
      companyZip: '',
      companyWebsite: '',
      companyType: '',
      companyAddress: '',
      addressCountry: '',
      addressState: '',
      addressCity: '',
      addressZipCode: '',
      address: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.mixed().required('Please enter first name'),
      last_name: Yup.mixed().required('Please enter last name'),
      email: Yup.string()
        .email('Invalid email')
        .required('Please enter your email'),
      phone: Yup.mixed().required('Please enter phone'),
      account_type: Yup.mixed().required('Please select account type'),
      isverified: Yup.mixed().required('Please select verified'),
      mark_up: Yup.string().notRequired(),
      additional_mark_up: Yup.string().notRequired(),
      salesId: Yup.string().notRequired(),
      companyName: Yup.string().notRequired(),
      companyEmail: Yup.string().notRequired(),
      companyCountry: Yup.string().notRequired(),
      companyState: Yup.string().notRequired(),
      companyCity: Yup.string().notRequired(),
      companyPhone: Yup.string().notRequired(),
      companyZip: Yup.string().notRequired(),
      companyWebsite: Yup.string().notRequired(),
      companyType: Yup.string().notRequired(),
      companyAddress: Yup.string().notRequired(),
      addressCity: Yup.string().notRequired(),
      addressZipCode: Yup.string().notRequired(),
      address: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      const {
        first_name,
        last_name,
        email,
        phone,
        account_type,
        isverified,
        salesId,
        mark_up,
        additional_mark_up,
        companyName,
        companyEmail,
        companyCountry,
        companyState,
        companyCity,
        companyPhone,
        companyZip,
        companyType,
        companyAddress,
        addressCity,
        addressZipCode,
        address,
        coupon_id,
      } = values;

      const body = {
        first_name: first_name,
        email: email,
        last_name: last_name,
        phone: phone,
        account_type: account_type,
        isverified: isverified,
        contactsales_id: salesId,
        mark_up: mark_up,
        additional_mark_up: additional_mark_up,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI(
          `verifyuser?id=${state?.id}`,
          'put',
          body,
          user?.authToken,
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/user-verify');
            console.log('res -->', res?.data);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  const applyCoupon = () => {
    const body = {
      user_id: state?._id,
      coupon_id: formik_coupon.values.coupon_id,
    };

    fetchDataFromAPI(
      'planPurchase/addPlanByUser',
      'post',
      body,
      user?.authToken,
    )
      .then((res) => {
        console.log('res: ' + res);
      })
      .catch((error) => {
        console.log('error: ' + error);
      });
  };

  const formik_coupon = useFormik({
    initialValues: {
      coupon_id: '',
    },
    validationSchema: Yup.object({
      coupon_id: Yup.string().required('Coupon code is required'),
    }),
    onSubmit: (values) => {
      console.log('Form values:', values);

      const body = {
        user_id: state?.id,
        coupon_id: values?.coupon_id,
      };

      console.log('body', body);

      fetchDataFromAPI(
        'planPurchase/addPlanByUser',
        'post',
        body,
        user?.authToken,
      )
        .then((res) => {
          console.log('res: ', res);
          getEditUser();
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('error: ', error);
        });
    },
  });

  const formik_plan = useFormik({
    initialValues: {
      plan_id: '',
    },
    validationSchema: Yup.object({
      plan_id: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      console.log('Form values:', values);

      const body = {
        is_active: true,
      };

      console.log('body', body);
      dataContext?.setLoading(true);

      fetchDataFromAPI(
        `planPurchase/updatestatus?user_id=${state?.id}&is_admin=true&plan_id=${values?.plan_id}`,
        'put',
        body,
        user?.authToken,
      )
        .then((res) => {
          console.log('res: ', res);
          getEditUser();
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          dataContext?.setLoading(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          dataContext?.setLoading(false);

          console.log('error: ', error);
        });
    },
  });

  const getDiamond = (page) => {
    dataContext?.setLoading(true);

    fetchDataFromAPI(
      `diamond/getdiamond?user_id=${state?.id}&page=${page}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res user diamond', res);
        setDiamond(res?.data);
        setTotalPages(res?.pages?.totalPages);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext?.setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getDiamond(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getDiamond(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            className={'custom-pagination'}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
            linkClassName={i === currentPage ? 'link-pagination' : ''}>
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  const getPlans = () => {
    dataContext?.setLoading(true);

    fetchDataFromAPI('features/getAllpackage', 'get', '', '')
      .then((res) => {
        console.log('res plan', res);
        setPlans(res?.data?.result);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext?.setLoading(false);
      });
  };

  const removeCoupon = (e) => {
    e.preventDefault();
    dataContext?.setLoading(true);

    fetchDataFromAPI(
      `planPurchase/deletePlanPurchase?planPurchase_id=${data?.planPurchase[0]?._id}`,
      'delete',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('delete coupon', res);
        getEditUser();
        dataContext?.setLoading(false);
        window.location.reload();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
      });
  };

  const removePlans = (e) => {
    e.preventDefault();
    dataContext?.setLoading(true);

    fetchDataFromAPI(
      `planPurchase/deletePlanPurchase?planPurchase_id=${data?.memberPlan?._id}`,
      'delete',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('delete coupon', res);
        getEditUser();
        dataContext?.setLoading(false);
        window.location.reload();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/user-verify">Users</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Users</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <div className=" text-center mb-3">
                          {data?.users?.profileImage ? (
                            <img
                              src={data?.users?.profileImage}
                              alt="Profile"
                              className="img-thumbnail"
                              style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <img
                              src={require('../../../assets/images/account.png')}
                              alt="Profile"
                              className="img-thumbnail"
                              style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">
                              USER INFORMATION
                            </span>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label
                                htmlFor="first_name"
                                className="form-label">
                                First Name
                              </label>

                              <input
                                name="first_name"
                                type="text"
                                className="form-control"
                                id="first_name"
                                value={formik.values.first_name}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[A-Za-z\s]*$/.test(value)) {
                                    formik.setFieldValue(
                                      'first_name',
                                      value.trimStart(),
                                    );
                                  }
                                }}
                              />
                              {formik.touched.first_name &&
                                formik.errors.first_name && (
                                  <div className="text-danger">
                                    {formik.errors.first_name}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-6">
                              <label htmlFor="last_name" className="form-label">
                                Last Name
                              </label>

                              <input
                                name="last_name"
                                type="text"
                                className="form-control"
                                id="last_name"
                                value={formik.values.last_name}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[A-Za-z\s]*$/.test(value)) {
                                    formik.setFieldValue(
                                      'last_name',
                                      value.trimStart(),
                                    );
                                  }
                                }}
                              />
                              {formik.touched.last_name &&
                                formik.errors.last_name && (
                                  <div className="text-danger">
                                    {formik.errors.last_name}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="email" className="form-label">
                                Email
                              </label>

                              <input
                                name="email"
                                type="text"
                                className="form-control"
                                id="email"
                                value={formik.values.email}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'email',
                                    e.target.value?.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.email && formik.errors.email && (
                                <div className="text-danger">
                                  {formik.errors.email}
                                </div>
                              )}
                            </div>

                            <div className="col-md-6">
                              <label htmlFor="phone" className="form-label">
                                Phone
                              </label>

                              <input
                                name="phone"
                                type="text"
                                className="form-control"
                                id="phone"
                                value={formik.values.phone}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'phone',
                                    value.trimStart(),
                                  );
                                }}
                                pattern="[0-9]*"
                              />
                              {formik.touched.phone && formik.errors.phone && (
                                <div className="text-danger">
                                  {formik.errors.phone}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label
                                htmlFor="account_type"
                                className="form-label">
                                Account Type
                              </label>
                              <div className="input-group">
                                <select
                                  name="account_type"
                                  className={`form-select ${
                                    formik.touched.account_type &&
                                    formik.errors.account_type
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="account_type"
                                  {...formik.getFieldProps('account_type')}
                                  aria-label="Select type">
                                  <option value="Retailer">Retailer</option>
                                  <option value="Manufacturer">
                                    Manufacturer
                                  </option>
                                </select>
                              </div>
                              {formik.touched.account_type &&
                                formik.errors.account_type && (
                                  <div className="text-danger">
                                    {formik.errors.account_type}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-6">
                              <label
                                htmlFor="isverified"
                                className="form-label">
                                Status
                              </label>
                              <div className="input-group">
                                <select
                                  name="isverified"
                                  className={`form-select ${
                                    formik.touched.isverified &&
                                    formik.errors.isverified
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id="isverified"
                                  {...formik.getFieldProps('isverified')}
                                  aria-label="Select type">
                                  <option value="true">Verify</option>
                                  <option value="false">Unverify</option>
                                </select>
                              </div>
                              {formik.touched.isverified &&
                                formik.errors.isverified && (
                                  <div className="text-danger">
                                    {formik.errors.isverified}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="from" className="form-label">
                                Mark Up%
                              </label>

                              <input
                                name="color"
                                type="text"
                                className="form-control"
                                id="color"
                                value={formik.values.mark_up}
                                onChange={(e) => {
                                  const cleanedValue = e.target.value.trim(); // Trim whitespace
                                  const numericValue = cleanedValue.replace(
                                    /[^0-9+-]/g,
                                    '',
                                  ); // Remove non-numeric characters

                                  formik.setFieldValue('mark_up', numericValue); // Set cleaned numeric value to 'mark_up' field
                                }}
                              />
                              {formik.touched.mark_up &&
                                formik.errors.mark_up && (
                                  <div className="text-danger">
                                    {formik.errors.mark_up}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="from" className="form-label">
                                Additional Mark Up $
                              </label>

                              <input
                                name="color"
                                type="text"
                                className="form-control"
                                id="color"
                                value={formik.values.additional_mark_up}
                                onChange={(e) => {
                                  const cleanedValue = e.target.value.trim(); // Trim whitespace
                                  const numericValue = cleanedValue.replace(
                                    /[^0-9+-]/g,
                                    '',
                                  ); // Remove non-numeric characters

                                  formik.setFieldValue(
                                    'additional_mark_up',
                                    numericValue,
                                  ); // Set cleaned numeric value to 'mark_up' field
                                }}
                              />
                              {formik.touched.additional_mark_up &&
                                formik.errors.additional_mark_up && (
                                  <div className="text-danger">
                                    {formik.errors.additional_mark_up}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="sales" className="form-label">
                                Assign Sales Executive
                              </label>

                              <select
                                // className="form-select"

                                className={`form-select ${
                                  formik.touched.salesId &&
                                  formik.errors.salesId
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                {...formik.getFieldProps('salesId')}>
                                <option value="">Sales Executive</option>
                                {sales.map((sales) => (
                                  <option key={sales._id} value={sales._id}>
                                    {'Name : - ' +
                                      sales?.first_name +
                                      '' +
                                      sales?.last_name +
                                      ' | ' +
                                      'Email : - ' +
                                      ' ' +
                                      sales?.email}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.salesId &&
                                formik.errors.salesId && (
                                  <div className="text-danger">
                                    {formik.errors.salesId}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="from" className="form-label">
                                Rapnet Id
                              </label>

                              <input
                                name="color"
                                type="text"
                                disabled
                                className="form-control"
                                id="color"
                                value={
                                  formik.values.rap_id
                                    ? formik.values.rap_id
                                    : '-'
                                }
                                onChange={(e) => {
                                  const cleanedValue = e.target.value.trim(); // Trim whitespace
                                  const numericValue = cleanedValue.replace(
                                    /\D/g,
                                    '',
                                  ); // Remove non-numeric characters

                                  formik.setFieldValue('rap_id', numericValue); // Set cleaned numeric value to 'mark_up' field
                                }}
                              />
                              {formik.touched.rap_id &&
                                formik.errors.rap_id && (
                                  <div className="text-danger">
                                    {formik.errors.rap_id}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="text-center m-3">
                            <CommonButton
                              loading={dataContext.loading}
                              onClick={formik.handleSubmit}
                              label="Edit User"
                              loadingLabel="Please wait..."
                            />
                          </div>

                          <div className="d-flex gap-2">
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">
                                    Apply Coupon Code
                                  </h5>
                                  <div>
                                    <form>
                                      <div className="mb-3">
                                        <label
                                          htmlFor="coupon_id"
                                          className="form-label">
                                          Select Coupon
                                        </label>
                                        <select
                                          className={`form-select ${
                                            formik_coupon.touched.coupon_id &&
                                            formik_coupon.errors.coupon_id
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          {...formik_coupon.getFieldProps(
                                            'coupon_id',
                                          )}>
                                          <option value="">Coupon Code</option>
                                          {coupon?.map((coupon) => (
                                            <option
                                              key={coupon._id}
                                              value={coupon._id}>
                                              {coupon.coupon}
                                            </option>
                                          ))}
                                        </select>
                                        {formik_coupon.touched.coupon_id &&
                                          formik_coupon.errors.coupon_id && (
                                            <div className="text-danger">
                                              {formik_coupon.errors.coupon_id}
                                            </div>
                                          )}
                                      </div>
                                      <div className="d-flex gap-3">
                                        <button
                                          onClick={formik_coupon.handleSubmit}
                                          type="submit"
                                          className="btn-custom w-100">
                                          Apply Coupon
                                        </button>

                                        <button
                                          onClick={(e) => removeCoupon(e)}
                                          type="submit"
                                          className="btn-custom w-100">
                                          Remove Coupon
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* {selectedCoupon && ( */}
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">Plan Dates</h5>
                                  <div>
                                    <p>
                                      <strong>Start Date:</strong>{' '}
                                      <strong>
                                        {data?.planPurchase?.length > 0
                                          ? moment(
                                              data?.planPurchase?.[0]
                                                ?.createdAt,
                                            ).format('DD-MM-YYYY')
                                          : '-'}
                                      </strong>
                                    </p>
                                    <p>
                                      <strong>End Date:</strong>{' '}
                                      <strong>
                                        {data?.planPurchase?.length > 0 &&
                                        data?.planPurchase?.[0]?.expired_date
                                          ? moment(
                                              data.planPurchase[0].expired_date,
                                            ).format('DD-MM-YYYY')
                                          : '-'}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* )} */}
                          </div>

                          <div className="d-flex gap-2">
                            <div className="col-md-12">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">Apply Plans</h5>
                                  <div>
                                    <form>
                                      <div className="mb-3">
                                        <label
                                          htmlFor="coupon_id"
                                          className="form-label">
                                          Select Plans
                                        </label>
                                        <select
                                          className={`form-select ${
                                            formik_plan.touched.plan_id &&
                                            formik_plan.errors.plan_id
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          {...formik_plan.getFieldProps(
                                            'plan_id',
                                          )}>
                                          <option value="">Plans...</option>
                                          {plans?.map((item) => (
                                            <option
                                              key={item?._id}
                                              value={item?._id}>
                                              {item?.title} | {item?.price}
                                            </option>
                                          ))}
                                        </select>
                                        {formik_plan.touched.plan_id &&
                                          formik_plan.errors.plan_id && (
                                            <div className="text-danger">
                                              {formik_plan.errors.plan_id}
                                            </div>
                                          )}
                                      </div>
                                      <div className="d-flex gap-3">
                                        <button
                                          onClick={formik_plan.handleSubmit}
                                          type="submit"
                                          className="btn-custom w-25 d-flex justify-content-center mx-auto">
                                          Apply Plans
                                        </button>
                                        {data?.memberPlan !== null && (
                                          <button
                                            onClick={(e) => removePlans(e)}
                                            type="submit"
                                            className="btn-custom w-25 d-flex justify-content-center mx-auto">
                                            Remove Plans
                                          </button>
                                        )}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* {selectedCoupon && ( */}
                            {/* <div className="col-md-6">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">Plan Dates</h5>
                                  <div>
                                    <p>
                                      <strong>Start Date:</strong>{' '}
                                      <strong>
                                        {data?.planPurchase?.length > 0
                                          ? moment(
                                              data?.planPurchase?.[0]
                                                ?.createdAt,
                                            ).format('DD-MM-YYYY')
                                          : '-'}
                                      </strong>
                                    </p>
                                    <p>
                                      <strong>End Date:</strong>{' '}
                                      <strong>
                                        {data?.planPurchase?.length > 0 &&
                                        data?.planPurchase?.[0]?.expired_date
                                          ? moment(
                                              data.planPurchase[0].expired_date,
                                            ).format('DD-MM-YYYY')
                                          : '-'}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {data?.memberPlan && (
                      <div className="row mb-3">
                        <div className="card mt-4">
                          <div className="card-header">
                            <div className="divider-container">
                              <span className="divider-text">MEMBER PLAN</span>
                            </div>
                          </div>

                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <label
                                  htmlFor="first_name"
                                  className="form-label">
                                  Amount
                                </label>

                                <input
                                  name="memberPlan"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="memberPlan"
                                  value={
                                    data.memberPlan?.amount
                                      ? `$${data.memberPlan?.amount}`
                                      : '-'
                                  }
                                  // onChange={(e) => {
                                  //   const value = e.target.value;
                                  //   if (/^[A-Za-z\s]*$/.test(value)) {
                                  //     formik.setFieldValue(
                                  //       'memberPlan',
                                  //       value.trimStart(),
                                  //     );
                                  //   }
                                  // }}
                                />
                                {formik.touched.memberPlan &&
                                  formik.errors.memberPlan && (
                                    <div className="text-danger">
                                      {formik.errors.memberPlan}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-6">
                                <label
                                  htmlFor="discount"
                                  className="form-label">
                                  Discount
                                </label>

                                <input
                                  name="discount"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="discount"
                                  value={
                                    data?.memberPlan?.plan_id?.discount ?? '-'
                                  }
                                  // onChange={(e) => {
                                  //   const value = e.target.value;
                                  //   if (/^[A-Za-z\s]*$/.test(value)) {
                                  //     formik.setFieldValue(
                                  //       'discount',
                                  //       value.trimStart(),
                                  //     );
                                  //   }
                                  // }}
                                />
                                {formik.touched.discount &&
                                  formik.errors.discount && (
                                    <div className="text-danger">
                                      {formik.errors.discount}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label
                                  htmlFor="planType"
                                  className="form-label">
                                  Plan Type
                                </label>

                                <input
                                  name="planType"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="planType"
                                  value={
                                    data?.memberPlan?.plan_id?.plan_type ?? '-'
                                  }
                                  // onChange={(e) => {
                                  //   formik.setFieldValue(
                                  //     'planType',
                                  //     e.target.value?.trimStart(),
                                  //   );
                                  // }}
                                />
                                {formik.touched.planType &&
                                  formik.errors.planType && (
                                    <div className="text-danger">
                                      {formik.errors.planType}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="price" className="form-label">
                                  Price
                                </label>

                                <input
                                  name="price"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="price"
                                  value={
                                    data?.memberPlan?.plan_id?.price ?? '-'
                                  }
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'price',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.price &&
                                  formik.errors.price && (
                                    <div className="text-danger">
                                      {formik.errors.price}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-4">
                                <label
                                  htmlFor="sale_price"
                                  className="form-label">
                                  Sale Price
                                </label>

                                <input
                                  name="sale_price"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="sale_price"
                                  value={
                                    data?.memberPlan?.plan_id?.sale_price ?? '-'
                                  }
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'sale_price',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.sale_price &&
                                  formik.errors.sale_price && (
                                    <div className="text-danger">
                                      {formik.errors.sale_price}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-12">
                                <label htmlFor="type" className="form-label">
                                  Type
                                </label>

                                <input
                                  name="type"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="type"
                                  value={
                                    data?.memberPlan?.plan_id?.title ?? '-'
                                  }
                                  // onChange={(e) => {
                                  //   formik.setFieldValue(
                                  //     'type',
                                  //     e.target.value?.trimStart(),
                                  //   );
                                  // }}
                                />
                                {formik.touched.type && formik.errors.type && (
                                  <div className="text-danger">
                                    {formik.errors.type}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-12">
                                <label htmlFor="type" className="form-label">
                                  Description
                                </label>

                                <div className="form-control">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        data?.memberPlan?.plan_id
                                          ?.description ?? '-',
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {data?.company && (
                      <div className="row mb-3">
                        <div className="card mt-4">
                          <div className="card-header">
                            <div className="divider-container">
                              <span className="divider-text">COMPANY</span>
                            </div>
                          </div>

                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <label
                                  htmlFor="first_name"
                                  className="form-label">
                                  Company Name
                                </label>

                                <input
                                  name="companyName"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyName"
                                  value={formik.values.companyName}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                      formik.setFieldValue(
                                        'companyName',
                                        value.trimStart(),
                                      );
                                    }
                                  }}
                                />
                                {formik.touched.companyName &&
                                  formik.errors.companyName && (
                                    <div className="text-danger">
                                      {formik.errors.companyName}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-6">
                                <label
                                  htmlFor="companyEmail"
                                  className="form-label">
                                  Company Email
                                </label>

                                <input
                                  name="companyEmail"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyEmail"
                                  value={formik.values.companyEmail}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                      formik.setFieldValue(
                                        'companyEmail',
                                        value.trimStart(),
                                      );
                                    }
                                  }}
                                />
                                {formik.touched.companyEmail &&
                                  formik.errors.companyEmail && (
                                    <div className="text-danger">
                                      {formik.errors.companyEmail}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label
                                  htmlFor="companyCountry"
                                  className="form-label">
                                  Company Country
                                </label>

                                <input
                                  name="companyCountry"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyCountry"
                                  value={formik.values.companyCountry}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'companyCountry',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.companyCountry &&
                                  formik.errors.companyCountry && (
                                    <div className="text-danger">
                                      {formik.errors.companyCountry}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-4">
                                <label
                                  htmlFor="companyState"
                                  className="form-label">
                                  Company State
                                </label>

                                <input
                                  name="companyState"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyState"
                                  value={formik.values.companyState}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'companyState',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.companyState &&
                                  formik.errors.companyState && (
                                    <div className="text-danger">
                                      {formik.errors.companyState}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-4">
                                <label
                                  htmlFor="companyCity"
                                  className="form-label">
                                  Company City
                                </label>

                                <input
                                  name="companyCity"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyCity"
                                  value={formik.values.companyCity}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'companyCity',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.companyCity &&
                                  formik.errors.companyCity && (
                                    <div className="text-danger">
                                      {formik.errors.companyCity}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label
                                  htmlFor="companyPhone"
                                  className="form-label">
                                  Company Phone
                                </label>

                                <input
                                  name="companyPhone"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyPhone"
                                  value={formik.values.companyPhone}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'companyPhone',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.companyPhone &&
                                  formik.errors.companyPhone && (
                                    <div className="text-danger">
                                      {formik.errors.companyPhone}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-4">
                                <label
                                  htmlFor="companyZip"
                                  className="form-label">
                                  Company Zip
                                </label>

                                <input
                                  name="companyZip"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyZip"
                                  value={formik.values.companyZip}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'companyZip',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.companyZip &&
                                  formik.errors.companyZip && (
                                    <div className="text-danger">
                                      {formik.errors.companyZip}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-4">
                                <label
                                  htmlFor="companyWebsite"
                                  className="form-label">
                                  Company Website
                                </label>

                                <input
                                  name="companyWebsite"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyWebsite"
                                  value={formik.values.companyWebsite}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'companyWebsite',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.companyWebsite &&
                                  formik.errors.companyWebsite && (
                                    <div className="text-danger">
                                      {formik.errors.companyWebsite}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-6">
                                <label
                                  htmlFor="companyType"
                                  className="form-label">
                                  Company Type
                                </label>

                                <input
                                  name="companyType"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyType"
                                  value={formik.values.companyType}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'companyType',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.companyType &&
                                  formik.errors.companyType && (
                                    <div className="text-danger">
                                      {formik.errors.companyType}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-6">
                                <label
                                  htmlFor="companyAddress"
                                  className="form-label">
                                  Company Address
                                </label>

                                <input
                                  name="companyAddress"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="companyAddress"
                                  value={formik.values.companyAddress}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'companyAddress',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.companyAddress &&
                                  formik.errors.companyAddress && (
                                    <div className="text-danger">
                                      {formik.errors.companyAddress}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label htmlFor="gst_num" className="form-label">
                                  Gst Number
                                </label>

                                <input
                                  name="gst_num"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="gst_num"
                                  value={
                                    formik.values.gst_num
                                      ? formik.values.gst_num
                                      : '-'
                                  }
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'gst_num',
                                      e.target.value?.trimStart(),
                                    );
                                  }}
                                />
                                {formik.touched.gst_num &&
                                  formik.errors.gst_num && (
                                    <div className="text-danger">
                                      {formik.errors.gst_num}
                                    </div>
                                  )}
                              </div>

                              {formik.values.gst_certificate && (
                                <div className="col-md-4">
                                  <label
                                    htmlFor="gst_certificate"
                                    className="form-label">
                                    Gst Certificate
                                  </label>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      onClick={() => {
                                        const urlOpen =
                                          formik.values.gst_certificate; // Replace with the URL you want to open
                                        window.open(urlOpen, '_blank');
                                      }}>
                                      <i class="bi bi-eye-fill"></i>
                                    </button>
                                  </div>
                                </div>
                              )}

                              {formik.values.incorporation_certificate && (
                                <div className="col-md-4">
                                  <label
                                    htmlFor="incorporation_certificate"
                                    className="form-label">
                                    Incorporation Certificate
                                  </label>

                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      onClick={() => {
                                        const urlOpen =
                                          formik.values
                                            .incorporation_certificate; // Replace with the URL you want to open
                                        window.open(urlOpen, '_blank');
                                      }}>
                                      <i class="bi bi-eye-fill"></i>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="row mb-3">
                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">COMPANY</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label
                                htmlFor="first_name"
                                className="form-label">
                                Company Name
                              </label>

                              <input
                                name="companyName"
                                type="text"
                                disabled
                                className="form-control"
                                id="companyName"
                                value={formik.values.companyName}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[A-Za-z\s]*$/.test(value)) {
                                    formik.setFieldValue(
                                      'companyName',
                                      value.trimStart(),
                                    );
                                  }
                                }}
                              />
                              {formik.touched.companyName &&
                                formik.errors.companyName && (
                                  <div className="text-danger">
                                    {formik.errors.companyName}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-6">
                              <label
                                htmlFor="companyEmail"
                                className="form-label">
                                Company Email
                              </label>

                              <input
                                name="companyEmail"
                                type="text"
                                disabled
                                className="form-control"
                                id="companyEmail"
                                value={formik.values.companyEmail}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[A-Za-z\s]*$/.test(value)) {
                                    formik.setFieldValue(
                                      'companyEmail',
                                      value.trimStart(),
                                    );
                                  }
                                }}
                              />
                              {formik.touched.companyEmail &&
                                formik.errors.companyEmail && (
                                  <div className="text-danger">
                                    {formik.errors.companyEmail}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label
                                htmlFor="companyCountry"
                                className="form-label">
                                Company Country
                              </label>

                              <input
                                name="companyCountry"
                                type="text"
                                disabled
                                className="form-control"
                                id="companyCountry"
                                value={formik.values.companyCountry}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'companyCountry',
                                    e.target.value?.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.companyCountry &&
                                formik.errors.companyCountry && (
                                  <div className="text-danger">
                                    {formik.errors.companyCountry}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-4">
                              <label
                                htmlFor="companyState"
                                className="form-label">
                                Company State
                              </label>

                              <input
                                name="companyState"
                                type="text"
                                disabled
                                className="form-control"
                                id="companyState"
                                value={formik.values.companyState}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'companyState',
                                    e.target.value?.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.companyState &&
                                formik.errors.companyState && (
                                  <div className="text-danger">
                                    {formik.errors.companyState}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-4">
                              <label
                                htmlFor="companyCity"
                                className="form-label">
                                Company City
                              </label>

                              <input
                                name="companyCity"
                                type="text"
                                disabled
                                className="form-control"
                                id="companyCity"
                                value={formik.values.companyCity}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'companyCity',
                                    e.target.value?.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.companyCity &&
                                formik.errors.companyCity && (
                                  <div className="text-danger">
                                    {formik.errors.companyCity}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {data?.address && (
                      <div className="row mb-3">
                        <div className="card mt-4">
                          <div className="card-header">
                            <div className="divider-container">
                              <span className="divider-text">
                                COMPANY ADDRESS
                              </span>
                            </div>
                          </div>

                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <label
                                  htmlFor="first_name"
                                  className="form-label">
                                  Address Country
                                </label>

                                <input
                                  name="addressCountry"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="addressCountry"
                                  value={formik.values.addressCountry}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                      formik.setFieldValue(
                                        'addressCountry',
                                        value.trimStart(),
                                      );
                                    }
                                  }}
                                />
                                {formik.touched.addressCountry &&
                                  formik.errors.addressCountry && (
                                    <div className="text-danger">
                                      {formik.errors.addressCountry}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-6">
                                <label
                                  htmlFor="addressState"
                                  className="form-label">
                                  Address State
                                </label>

                                <input
                                  name="addressState"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="addressState"
                                  value={formik.values.addressState}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                      formik.setFieldValue(
                                        'addressState',
                                        value.trimStart(),
                                      );
                                    }
                                  }}
                                />
                                {formik.touched.addressState &&
                                  formik.errors.addressState && (
                                    <div className="text-danger">
                                      {formik.errors.addressState}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-md-6">
                                <label
                                  htmlFor="first_name"
                                  className="form-label">
                                  Address City
                                </label>

                                <input
                                  name="addressCity"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="addressCity"
                                  value={formik.values.addressCity}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                      formik.setFieldValue(
                                        'addressCity',
                                        value.trimStart(),
                                      );
                                    }
                                  }}
                                />
                                {formik.touched.addressCity &&
                                  formik.errors.addressCity && (
                                    <div className="text-danger">
                                      {formik.errors.addressCity}
                                    </div>
                                  )}
                              </div>

                              <div className="col-md-6">
                                <label
                                  htmlFor="addressZipCode"
                                  className="form-label">
                                  Address Zip Code
                                </label>

                                <input
                                  name="addressZipCode"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="addressZipCode"
                                  value={formik.values.addressZipCode}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                      formik.setFieldValue(
                                        'addressZipCode',
                                        value.trimStart(),
                                      );
                                    }
                                  }}
                                />
                                {formik.touched.addressZipCode &&
                                  formik.errors.addressZipCode && (
                                    <div className="text-danger">
                                      {formik.errors.addressZipCode}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div>
                              <div className="col-md-12">
                                <label
                                  htmlFor="addressZipCode"
                                  className="form-label">
                                  Address
                                </label>

                                <textarea
                                  name="address"
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="address"
                                  value={formik.values.address}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[A-Za-z\s]*$/.test(value)) {
                                      formik.setFieldValue(
                                        'address',
                                        value.trimStart(),
                                      );
                                    }
                                  }}
                                />
                                {formik.touched.address &&
                                  formik.errors.address && (
                                    <div className="text-danger">
                                      {formik.errors.address}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>

              {/* {data?.company && ( */}
              <div className="row mb-3">
                <div className="card mt-4">
                  <div className="card-header">
                    <div className="divider-container">
                      <span className="divider-text">UPLOADED DIAMONDS</span>
                      {/* <span className="fs-4"> {diamond?.length}</span> */}
                    </div>
                  </div>

                  <div className="card-body">
                    {diamond?.length === 0 ? (
                      <p className="text-center">No data available</p>
                    ) : (
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">Certificate Id</th>
                            <th scope="col">Shape</th>
                            <th scope="col">Carat</th>
                            <th scope="col">Price</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Amount</th>
                            {/* <th scope="col">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {diamond?.map((item, index) => (
                            <tr className="align-middle" key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item?.cert_num ? item?.cert_num : '-'}</td>
                              <td>
                                {item?.shape ? item?.shape.toUpperCase() : '-'}
                              </td>
                              <td>{item?.carat ? item?.carat : '-'}</td>

                              <td>
                                {item?.price?.price
                                  ? `$${item.price.price.toFixed(2)}`
                                  : '-'}
                              </td>

                              <td>
                                {item?.price?.discount
                                  ? `%${item?.price?.discount?.toFixed(2)}`
                                  : '-'}
                              </td>
                              <td>
                                {item?.price?.amount
                                  ? ` $${item?.price?.amount?.toFixed(2)}`
                                  : '-'}
                              </td>

                              {/* <td className="">
                                <div className="d-flex">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={() => {
                                      navigate(`/edit-diamond/${item?._id}`);
                                    }}>
                                    <i className="bi bi-pencil-square"></i>
                                  </div>
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    onClick={() => {
                                      // setDeleteId(item?._id);
                                    }}>
                                    <i className="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {totalPages > 1 && (
                      <Pagination className="align-items-center justify-content-center">
                        {currentPage !== 1 && ( // Check if it's not the first page
                          <p
                            className="text-center mb-sm-0 pe-3 cursor-pointer"
                            onClick={handlePrevious}
                            role="button">
                            Previous
                          </p>
                        )}
                        {getPaginationItems()}
                        {currentPage !== totalPages &&
                          diamond?.length > 0 && ( // Check if it's not the last page and there's data
                            <p
                              className="text-center mb-sm-0 ps-3 cursor-pointer"
                              onClick={handleNext}
                              role="button">
                              Next
                            </p>
                          )}
                      </Pagination>
                    )}
                  </div>
                </div>
              </div>
              {/* )} */}
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditUserVerifyPage;
