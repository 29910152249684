import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const ViewClientModel = ({show, onHide, data}) => {
  console.log('data', data?.diamond_ids);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          <span>Transaction</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {data?.diamond_ids?.length > 0 ? (
            data.diamond_ids.map((item, index) => {
              const seller = item?.diamond?.uId; // Access the seller (uId) info here
              if (!seller) {
                return (
                  <div key={index} className="text-center w-100">
                    <span>No seller data available.</span>
                  </div>
                );
              }
              return (
                <div key={index}>
                  <p>
                    <strong>First Name:</strong> {seller?.first_name || '-'}
                  </p>
                  <p>
                    <strong>Last Name:</strong> {seller?.last_name || '-'}
                  </p>
                  <p>
                    <strong>Phone:</strong> {seller?.phone || '-'}
                  </p>
                  <p>
                    <strong>Email:</strong> {seller?.email || '-'}
                  </p>
                  <hr />
                </div>
              );
            })
          ) : (
            <p>No seller data available.</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} className="w-100">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewClientModel;
