import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {fetchDataFromAPI} from '../../../utils/api';
import CommonButton from '../../../components/CommonButton/CommonButton';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const EditAnnouncements = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const [data, setData] = useState([]);

  const state = useParams();
  console.log('state', state?.id);

  useEffect(() => {
    getcolor();
  }, []);

  const getcolor = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`Announcement?id=${state?.id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res -->', res?.data?.announcement);
        let data = res?.data;
        setData(res?.data);
        formik.setFieldValue('announcements', res?.data?.announcement);
        formik.setFieldValue('description', res?.data?.description);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      announcements: '',
      description: '',
    },
    validationSchema: Yup.object({
      announcements: Yup.string().required('Please enter Announcements'),
      description: Yup.string().required('Please enter Announcements'),
    }),
    onSubmit: (values) => {
      const {announcements, description} = values;

      const body = {
        announcement: announcements,
        description: description,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI(
          `Announcement?id=${state?.id}`,
          'put',
          body,
          user?.authToken,
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/announcement');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/announcements">Announcements</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Edit Announcements</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="from" className="form-label">
                            Announcements
                          </label>

                          <input
                            name="color"
                            type="text"
                            min={0}
                            className="form-control"
                            id="color"
                            value={formik.values.announcements}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'announcements',
                                e.target.value?.trimStart(),
                              );
                            }}

                            // onChange={(e) => {
                            //   const value = e.target.value.trimStart(); // Trim leading whitespace

                            //   if (/^[A-Za-z\s-]*$/.test(value)) {
                            //     // Check if value contains only letters, spaces, and hyphens
                            //     const capitalizedValue =
                            //       value.charAt(0).toUpperCase() +
                            //       value.slice(1); // Capitalize first letter

                            //     formik.setFieldValue('announcements', value);
                            //   }
                            // }}
                          />
                          {formik.touched.announcements &&
                            formik.errors.announcements && (
                              <div className="text-danger">
                                {formik.errors.announcements}
                              </div>
                            )}
                        </div>

                        <div className="col-md-12">
                          <label htmlFor="from" className="form-label">
                            Announcements
                          </label>

                          <textarea
                            name="color"
                            type="text"
                            className="form-control"
                            id="color"
                            value={formik.values.description}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'description',
                                e.target.value?.trimStart(),
                              );
                            }}

                            // onChange={(e) => {
                            //   const value = e.target.value.trimStart(); // Trim leading whitespace

                            //   if (/^[A-Za-z\s-]*$/.test(value)) {
                            //     // Check if value contains only letters, spaces, and hyphens
                            //     const capitalizedValue =
                            //       value.charAt(0).toUpperCase() +
                            //       value.slice(1); // Capitalize first letter

                            //     formik.setFieldValue('description', value);
                            //   }
                            // }}
                          />
                          {formik.touched.description &&
                            formik.errors.description && (
                              <div className="text-danger">
                                {formik.errors.description}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Edit Announcement"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditAnnouncements;
