import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {FormGroup} from 'react-bootstrap';

import {Country, State, City} from 'country-state-city';
import {codes} from '../../../CurrencyCodes/CurrencyCodes';
import {getUser} from '../../../utils/constants';
import {countr} from '../../../CurrencyCodes/country';
import {cointrystate} from '../../../CurrencyCodes/state';

const EditDiamond = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const {id} = useParams();

  const [diamond, setDiamond] = useState([]);

  console.log('DIAMOND', diamond);
  const [url, setURL] = useState(null);
  const [urlVideo, setVidoURL] = useState(null);
  const [urlPdf, setPdfURL] = useState(null);
  const [subOptions, setSubOptions] = useState({});
  const [states, setStates] = useState([]);

  const user = JSON.parse(getUser());

  console.log('user', user?.authToken);

  useEffect(() => {
    getDiamondsData();
    getDiamond();
  }, []);

  const getDiamond = () => {
    fetchDataFromAPI(`diamond/getdiamond?id=${id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res -->', res?.data);
        formik.setFieldValue('diamondID', res?.data?.dId);
        formik.setFieldValue('shape', res?.data?.shape);
        formik.setFieldValue('carat', res?.data?.carat);

        formik.setFieldValue('country', res?.data?.country);
        formik.setFieldValue('state', res?.data?.state);
        formik.setFieldValue('city', res?.data?.city);

        formik.setFieldValue('colourType', res?.data?.colorType?.type);
        formik.setFieldValue('colour', res?.data?.colorType?.color);
        formik.setFieldValue('intensity_name', res?.data?.colorType?.intensity);
        formik.setFieldValue('overtone', res?.data?.colorType?.overtone);

        formik.setFieldValue('clarity', res?.data?.clarity);
        formik.setFieldValue('eyeclean', res?.data?.eyeClean);
        formik.setFieldValue('bgm', res?.data?.bgm);
        formik.setFieldValue('finish', res?.data?.finish);
        formik.setFieldValue('cut', res?.data?.cut);
        formik.setFieldValue('polish', res?.data?.polish);
        formik.setFieldValue('symmetry', res?.data?.symmetry);
        formik.setFieldValue('fluorescence', res?.data?.fluorescence);
        formik.setFieldValue('colourName', res?.data?.color);
        formik.setFieldValue('grading', res?.data?.gradingReport);
        formik.setFieldValue('certComment', res?.data?.certComment);
        formik.setFieldValue('reportNumber', res?.data?.reportNumber);
        formik.setFieldValue('location', res?.data?.location);
        formik.setFieldValue('roughSource', res?.data?.roughSource);
        formik.setFieldValue('sourceProvide', res?.data?.sourceProvide);
        formik.setFieldValue('transectraLot', res?.data?.transectraLot);
        formik.setFieldValue('discount', res?.data?.price?.discount);
        formik.setFieldValue('amount', res?.data?.price?.amount);
        formik.setFieldValue('price', res?.data?.price?.price);
        formik.setFieldValue('showonly', res?.data?.showonly);
        formik.setFieldValue('length', res?.data?.measurement?.length);
        formik.setFieldValue('width', res?.data?.measurement?.width);
        formik.setFieldValue('depth', res?.data?.measurement?.depth);
        formik.setFieldValue('depthPer', res?.data?.measurement?.depthPer);
        formik.setFieldValue('tablePer', res?.data?.measurement?.tablePer);
        formik.setFieldValue('girdlePer', res?.data?.measurement?.girdlePer);

        formik.setFieldValue('crAngle', res?.data?.crown?.angle);
        formik.setFieldValue('crHeight', res?.data?.crown?.hight);

        formik.setFieldValue('pvAngle', res?.data?.pavilion?.angle);
        formik.setFieldValue('pvHeight', res?.data?.pavilion?.hight);

        formik.setFieldValue('milky', res?.data?.inclusion?.milky);
        formik.setFieldValue('black', res?.data?.inclusion?.black);
        formik.setFieldValue('white', res?.data?.inclusion?.white);
        formik.setFieldValue('open', res?.data?.inclusion?.open);

        formik.setFieldValue('girdle', res?.data?.girdle);
        formik.setFieldValue('cutlet', res?.data?.cutlet);
        formik.setFieldValue('cutletcondition', res?.data?.cutletcondition);
        formik.setFieldValue('ratio', res?.data?.ratio);
        formik.setFieldValue('treatment', res?.data?.treatment);
        formik.setFieldValue('treatmentType', res?.data?.treatmentType);
        formik.setFieldValue('shade', res?.data?.shade);
        formik.setFieldValue('shadekey', res?.data?.shadekey);
        formik.setFieldValue('key', res?.data?.key);
        formik.setFieldValue('keysymbol', res?.data?.symbol);
        formik.setFieldValue('stock_num', res?.data?.stock_num);
        formik.setFieldValue('cert_num', res?.data?.cert_num);
        formik.setFieldValue('currency_code', res?.data?.currency_code);
        formik.setFieldValue('currency_symbol', res?.data?.currency_symbol);
        formik.setFieldValue('brandName', res?.data?.brandName);
        formik.setFieldValue('image', res?.data?.image);
        formik.setFieldValue('video', res?.data?.video);
        formik.setFieldValue('pdf', res?.data?.pdf);
        formik.setFieldValue('numberdiamons', res?.data?.numberOfDiamonds);

        formik.setFieldValue('matchedpair', res?.data?.matchedPair);
        formik.setFieldValue('on_hold', res?.data?.on_hold);
        formik.setFieldValue('parcel', res?.data?.parcel);
      })
      .catch((error) => {
        console.log('error -->', error);
      });
  };

  const handleFileChangeImg = (e) => {
    formik.setFieldValue('image', e.target.files[0]);
    // const image = e?.target?.files[0];
    const data = new FormData();
    data.append('image', e?.target?.files[0]);
    // Reset value for the image field
    // Clear any validation error for the image field
    dataContext.setLoading(true);
    fetchDataFromAPI('upload', 'post', data, '')
      .then((res) => {
        console.log('res: ', res?.data);

        setURL(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  const handleFileChangeVideo = (e) => {
    formik.setFieldValue('video', e.target.files[0]);
    // const video = e?.target?.files[0];
    const data = new FormData();
    data.append('image', e?.target?.files[0]);
    // Reset value for the video field
    // Clear any validation error for the video field
    dataContext.setLoading(true);
    fetchDataFromAPI('upload', 'post', data, '')
      .then((res) => {
        console.log('res: ', res?.data);

        setVidoURL(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  const handleFileChangePdf = (e) => {
    formik.setFieldValue('pdf', e.target.files[0]);
    // const pdfFile = e?.target?.files[0];
    const data = new FormData();
    data.append('image', e?.target?.files[0]);
    // Reset value for the pdfFile field
    // Clear any validation error for the pdfFile field
    dataContext.setLoading(true);
    fetchDataFromAPI('upload', 'post', data, '')
      .then((res) => {
        console.log('res: ', res?.data);

        setPdfURL(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  const getDiamondsData = () => {
    dataContext.setLoading(true);
    fetchDataFromAPI('diamond/getAllDiamonds', 'get', '', user?.authToken)
      .then((res) => {
        setDiamond(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error --->', error);
        dataContext.setLoading(false);
      });
  };

  // const handleColourTypeChange = (e) => {
  //   setSelectedColorType(e.target.value);
  //   formik.handleChange(e);
  // };
  const mmValidation = Yup.string().matches(
    /^\d+mm$/,
    'Please enter a value in mm (e.g., 10mm)',
  );

  // Custom validation for values ending with '%'
  const percentageValidation = Yup.string().matches(
    /^\d+%$/,
    'Please enter a value in percentage (e.g., 50%)',
  );

  const formik = useFormik({
    initialValues: {
      diamondID: '',
      ratio: '',

      availability: '',
      asking_price: '',
      country: '',
      state: '',
      city: '',

      bgm: false,
      carat: '',
      cut: '',
      polish: '',
      symmetry: '',
      reportNumber: '',
      certComment: '',
      sourceProvide: '',
      transectraLot: '',
      discount: '',
      price: '',
      amount: '',
      length: '',
      depth: '',
      width: '',
      tablePer: '',
      depthPer: '',
      girdlePer: '',
      brandName: '',
      carat: '',
      clarity: '',
      colour: '',
      colourType: '',
      colourName: '',
      cut: '',
      cutlet: '',
      cutletcondition: '',
      eyeclean: '',
      finish: '',
      fluorescence: '',
      girdle: '',
      grading: '',
      intensity_name: '',
      keysymbol: '',
      key: '',
      location: '',
      overtone: '',
      polish: '',
      shade: '',
      shape: '',
      showonly: '',
      shadekey: '',
      price: '',
      symmetry: '',
      treatmentType: '',
      treatment: '',
      stock_num: '',
      cert_num: '',
      currency_code: '',
      currency_symbol: '',
      roughSource: '',
      crHeight: '',
      crAngle: '',
      pvAngle: '',
      pvHeight: '',
      milky: '',
      black: '',
      white: '',
      open: '',
      on_hold: false,
      image: null,
      video: null,
      pdf: null,
    },
    validationSchema: Yup.object({
      diamondID: Yup.string().required('Please enter diamond id'),
      shape: Yup.string().required('Please select shape'),
      carat: Yup.string().required('Please enter carat'),
      // country: Yup.string().required('Please select country'),
      // state: Yup.string().required('Please select state'),
      // city: Yup.string().required('Please select city'),
      // colourType: Yup.string().required('Please select colour-type'),
      // colour: Yup.string().required('Please select colour'),
      // overtone: Yup.string().required('Please select overtone'),
      // intensity_name: Yup.string().required('Please select intensity'),
      // clarity: Yup.string().required('Please select clarity'),
      // eyeclean: Yup.string().required('Please select eye clean'),
      // finish: Yup.string().required('Please select finish'),
      // cut: Yup.string().required('Please enter cut value'),
      // polish: Yup.string().required('Please enter polish value'),
      // symmetry: Yup.string().required('Please enter symmetry  value'),
      // fluorescence: Yup.string().required('Please select fluorescence'),
      // colourName: Yup.string().required('Please select colour'),
      // grading: Yup.string().required('Please select grading'),
      // certComment: Yup.string().required('Please enter cert comment'),
      // reportNumber: Yup.string().required('Please select report number'),
      // location: Yup.string().required('Please select location'),
      // sourceProvide: Yup.string().required('Please select source provide'),
      // roughSource: Yup.string().required('Please select rough source'),
      // transectraLot: Yup.string().required('Please enter transectra lot'),
      // discount: Yup.string().required('Please enter discount'),
      // price: Yup.string().required('Please enter price'),
      // amount: Yup.string().required('Please enter amount'),
      // showonly: Yup.string().required('Please select showonly'),
      // length: Yup.string().required('Please enter length'),
      // width: Yup.string().required('Please enter width'),
      // depth: Yup.string().required('Please enter depth'),
      // depthPer: Yup.string().required('Please enter depth'),
      // tablePer: Yup.string().required('Please enter table'),
      // girdlePer: Yup.string().required('Please enter girdle'),
      // ratio: Yup.string().required('Please enter ratio'),
      // cutlet: Yup.string().required('Please select cutlet'),
      // cutletcondition: Yup.string().required('Please select cutlet condition'),
      // girdle: Yup.string().required('Please select girdle'),
      // treatmentType: Yup.string().required('Please select treatment type'),
      // treatment: Yup.string().required('Please select treatment'),
      // shade: Yup.string().required('Please select shade'),
      // shadekey: Yup.string().required('Please select shade key'),
      // keysymbol: Yup.string().required('Please select keysymbol'),
      // key: Yup.string().required('Please select key'),
      // stock_num: Yup.string().required('Please select stock number'),
      // cert_num: Yup.string().required('Please select cert number'),
      // currency_code: Yup.string().required('Please select currency code'),
      // currency_symbol: Yup.string().required('Please select currency symbol'),
      // brandName: Yup.string().required('Please select brand'),
      // image: Yup.mixed().notRequired(),
      // video: Yup.mixed().notRequired(),
      // pdf: Yup.mixed().notRequired(),
      // cut: Yup.string().required('Please select cut'),
      // polish: Yup.string().required('Please select polish'),
      // price: Yup.string().required('Please select price'),
      // symmetry: Yup.string().required('Please select symmetry'),
      // crAngle: Yup.string().required('Please enter crown angle'),
      // crHeight: Yup.string().required('Please enter crown height'),
      // pvAngle: Yup.string().required('Please enter pavilion angle'),
      // pvHeight: Yup.string().required('Please enter pavilion height'),
      // milky: Yup.string().required('Please enter milky value'),
      // black: Yup.string().required('Please enter black value'),
      // white: Yup.string().required('Please enter white value'),
      // open: Yup.string().required('Please enter open value'),
    }),
    onSubmit: (values) => {
      const {
        diamondID,
        discount,
        availability,
        asking_price,
        country,
        state,
        city,
        amount,
        length,
        width,
        depth,
        depthPer,
        tablePer,
        girdlePer,
        showonly,
        brandName,
        carat,
        clarity,
        bgm,
        colour,
        colourType,
        colourName,
        cut,
        cutlet,
        cutletcondition,
        eyeclean,
        finish,
        fluorescence,
        girdle,
        grading,
        intensity_name,
        keysymbol,
        key,
        location,
        overtone,
        polish,
        reportNumber,
        roughSource,
        shade,
        shadekey,
        shape,
        sourceProvide,
        transectraLot,
        certComment,
        price,
        symmetry,
        treatmentType,
        treatment,
        ratio,
        stock_num,
        cert_num,
        currency_symbol,
        currency_code,
        crAngle,
        crHeight,
        pvAngle,
        pvHeight,
        milky,
        black,
        white,
        open,
        numberdiamons,
        matchedpair,
        on_hold,
        parcel,
        image,
        video,
        pdf,
      } = values;

      console.log('values', values);

      try {
        dataContext.setLoading(true);
        const selectedCountry = countr.find((c) => c.id === country);
        const selectedState = cointrystate.find((s) => s.id === state);
        const body = {
          dId: diamondID,
          shape: shape,
          country: selectedCountry?.name,
          state: selectedState?.name,
          carat: carat,
          colorType: {
            type: colourType,
            color: colour,
            intensity: intensity_name,
            overtone: overtone,
          },
          clarity: clarity,
          eyeClean: eyeclean,
          bgm: bgm,
          finish: finish,
          cut: cut,
          polish: polish,
          symmetry: symmetry,
          fluorescence: fluorescence,
          gradingReport: grading,
          certComment: certComment,
          reportNumber: reportNumber,
          location: location,
          city: city,

          roughSource: roughSource,
          sourceProvide: sourceProvide,
          transectraLot: transectraLot,
          price: {
            discount: discount,
            price: price,
            amount: amount,
          },
          showonly: showonly,
          measurement: {
            length: length,
            width: width,
            depth: depth,
            depthPer: depthPer,
            tablePer: tablePer,
            girdlePer: girdlePer,
          },
          crown: {
            angle: crAngle,
            hight: crHeight,
          },
          pavilion: {
            angle: pvAngle,
            hight: pvHeight,
          },
          inclusion: {
            milky: milky,
            black: black,
            white: white,
            open: open,
          },
          color: colourName,
          girdle: girdle,
          cutlet: cutlet,
          cutletcondition: cutletcondition,
          treatment: treatment,
          treatmentType: treatmentType,
          shade: shade,
          shadekey: shadekey,
          brandName: brandName,
          symbol: keysymbol,
          key: key,
          ratio: ratio,
          stock_num: stock_num,
          cert_num: cert_num,
          currency_symbol: currency_symbol,
          currency_code: currency_code,
          image: url ? url : image,
          video: urlVideo ? urlVideo : video,
          pdf: urlPdf ? urlPdf : pdf,
          matchedPair: matchedpair,
          parcel: parcel,
          on_hold: on_hold,
          numberOfDiamonds: numberdiamons,
        };
        console.log('body', body);

        fetchDataFromAPI(
          `diamond/updateDiamond?id=${id}`,
          'put',
          body,
          user?.authToken,
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/get-diamond');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (formik.values.colourType) {
      // Fetch the sub-options based on selected colourType
      const selectedColourType = diamond?.ColorType?.find(
        (item) => item.name === formik.values.colourType,
      );

      console.log('selectedColourType', selectedColourType);

      if (selectedColourType) {
        setSubOptions(selectedColourType.values);
      } else {
        setSubOptions({});
      }
    } else {
      setSubOptions({});
    }
  }, [formik.values.colourType, diamond.ColorType]);

  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    formik.setFieldValue('country', countryId);
    const filteredStates = cointrystate.filter(
      (state) => state.country_id === countryId,
    );
    setStates(filteredStates);
    formik.setFieldValue('state', '');
  };

  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/get-diamond">Diamond</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Edit Diamond</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">
                              PRODUCT INFORMATION
                            </span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="stock_num" className="form-label">
                                Stock Number
                              </label>
                              <input
                                name="stock_num"
                                type="text"
                                placeholder="Number..."
                                className="form-control"
                                id="stock_num"
                                value={formik.values.stock_num}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'stock_num',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.stock_num &&
                                formik.errors.stock_num && (
                                  <div className="text-danger">
                                    {formik.errors.stock_num}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="Availability"
                                className="form-label">
                                Availability
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('availability')}>
                                <option value="">Choose Availability</option>

                                {/* <option key={item._id} value={item.name}>
                                  {item?.name}
                                </option> */}
                                <option value="Guaranteed_Availability">
                                  Guaranteed Availability
                                </option>
                                <option value="Out on memo">Out on memo</option>
                                <option value="Subject to Prior Sale">
                                  Subject to Prior Sale
                                </option>
                                <option value="Not Available">
                                  Not Available
                                </option>
                              </select>
                              {formik.touched.availability &&
                                formik.errors.availability && (
                                  <div className="text-danger">
                                    {formik.errors.availability}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">
                              PRODUCT & LOCATION
                            </span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="asking_price"
                                className="form-label">
                                Asking Price
                              </label>
                              <input
                                name="asking_price"
                                type="text"
                                placeholder="Asking Price..."
                                className="form-control"
                                id="asking_price"
                                value={formik.values.asking_price}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'asking_price',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.asking_price &&
                                formik.errors.asking_price && (
                                  <div className="text-danger">
                                    {formik.errors.asking_price}
                                  </div>
                                )}
                            </div>
                            {/* <div className="col-md-6">
                              <label
                                htmlFor="Availability"
                                className="form-label">
                                Availability
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('availability')}>
                                <option value="">Choose Availability</option>

                      
                                <option value="Guaranteed_Availability">
                                  Guaranteed Availability
                                </option>
                                <option value="Out on memo">Out on memo</option>
                                <option value="Subject to Prior Sale">
                                  Subject to Prior Sale
                                </option>
                                <option value="Not Available">
                                  Not Available
                                </option>
                              </select>
                              {formik.touched.availability &&
                                formik.errors.availability && (
                                  <div className="text-danger">
                                    {formik.errors.availability}
                                  </div>
                                )}
                            </div> */}
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label
                                htmlFor="asking_price"
                                className="form-label">
                                Country
                              </label>
                              <select
                                className={`form-select`}
                                value={formik.values.country}
                                onChange={handleCountryChange}
                                // {...formik.getFieldProps('country')}
                              >
                                <option value="">Country</option>
                                {countr.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.country &&
                                formik.errors.country && (
                                  <div style={{color: 'red'}}>
                                    {formik.errors.country}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-4">
                              <label
                                htmlFor="asking_price"
                                className="form-label">
                                State
                              </label>
                              <select
                                className={`form-select`}
                                value={formik.values.state}
                                onChange={(e) =>
                                  formik.setFieldValue('state', e.target.value)
                                }>
                                <option value="">State</option>
                                {states.map((state) => (
                                  <option key={state.id} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.state && formik.errors.state && (
                                <div style={{color: 'red'}}>
                                  {formik.errors.state}
                                </div>
                              )}
                            </div>

                            <div className="col-md-4">
                              <label
                                htmlFor="asking_price"
                                className="form-label">
                                City
                              </label>
                              <input
                                name="reportNumber"
                                type="text"
                                className="form-control"
                                id="reportNumber"
                                placeholder="city..."
                                value={formik.values.city}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'city',
                                    e.target.value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.city && formik.errors.city && (
                                <div style={{color: 'red'}}>
                                  {formik.errors.city}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">DIAMOND</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label htmlFor="diamondID" className="form-label">
                                Diamond ID
                              </label>
                              <input
                                name="diamondID"
                                type="text"
                                placeholder="Id..."
                                className="form-control"
                                id="diamondID"
                                value={formik.values.diamondID}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Allow only numbers
                                  formik.setFieldValue(
                                    'diamondID',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.diamondID &&
                                formik.errors.diamondID && (
                                  <div className="text-danger">
                                    {formik.errors.diamondID}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="shape" className="form-label">
                                Shape
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('shape')}>
                                <option value="">Choose Shape</option>
                                {diamond.Shape?.map((item) => (
                                  <option key={item._id} value={item.name}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.shape && formik.errors.shape && (
                                <div className="text-danger">
                                  {formik.errors.shape}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="carat" className="form-label">
                                Carat
                              </label>
                              <input
                                name="carat"
                                type="text"
                                placeholder="Carat..."
                                className="form-control"
                                id="carat"
                                value={formik.values.carat}
                                onChange={(e) => {
                                  const value = e.target.value
                                    .replace(/[^0-9.]/g, '') // Allow numbers and periods
                                    .replace(/^(\d*\.\d{0,2}|\d+).*$/, '$1'); // Restrict to two decimal places if needed
                                  formik.setFieldValue(
                                    'carat',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.carat && formik.errors.carat && (
                                <div className="text-danger">
                                  {formik.errors.carat}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">COLOUR TYPE</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-3">
                              <label
                                htmlFor="colourType"
                                className="form-label">
                                Colour-Type
                              </label>
                              <select
                                className="form-select"
                                {...formik.getFieldProps('colourType')}>
                                <option value="">Choose Colour-Type</option>
                                {diamond.ColorType?.map((item) => (
                                  <option key={item._id} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.colourType &&
                                formik.errors.colourType && (
                                  <div className="text-danger">
                                    {formik.errors.colourType}
                                  </div>
                                )}
                            </div>

                            {subOptions.color && (
                              <div className="col-md-3">
                                <label htmlFor="colour" className="form-label">
                                  Colour
                                </label>
                                <select
                                  className="form-select"
                                  {...formik.getFieldProps('colour')}>
                                  <option value="">Choose Colour</option>
                                  {subOptions.color.map((color, index) => (
                                    <option key={index} value={color}>
                                      {color}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.colour &&
                                  formik.errors.colour && (
                                    <div className="text-danger">
                                      {formik.errors.colour}
                                    </div>
                                  )}
                              </div>
                            )}

                            {subOptions.intensity && (
                              <div className="col-md-3">
                                <label
                                  htmlFor="intensity_name"
                                  className="form-label">
                                  Intensity
                                </label>
                                <select
                                  className="form-select"
                                  {...formik.getFieldProps('intensity_name')}>
                                  <option value="">Choose Intensity</option>
                                  {subOptions.intensity.map(
                                    (intensity, index) => (
                                      <option key={index} value={intensity}>
                                        {intensity}
                                      </option>
                                    ),
                                  )}
                                </select>
                                {formik.touched.intensity_name &&
                                  formik.errors.intensity_name && (
                                    <div className="text-danger">
                                      {formik.errors.intensity_name}
                                    </div>
                                  )}
                              </div>
                            )}

                            {subOptions.overtone && (
                              <div className="col-md-3">
                                <label
                                  htmlFor="overtone"
                                  className="form-label">
                                  Overtone
                                </label>
                                <select
                                  className="form-select"
                                  {...formik.getFieldProps('overtone')}>
                                  <option value="">Choose Overtone</option>
                                  {subOptions.overtone.map(
                                    (overtone, index) => (
                                      <option key={index} value={overtone}>
                                        {overtone}
                                      </option>
                                    ),
                                  )}
                                </select>
                                {formik.touched.overtone &&
                                  formik.errors.overtone && (
                                    <div className="text-danger">
                                      {formik.errors.overtone}
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">CLARITY</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label htmlFor="clarity" className="form-label">
                                Clarity
                              </label>
                              <select
                                className="form-select"
                                {...formik.getFieldProps('clarity')}>
                                <option value="">Choose Clarity</option>
                                {diamond.Clarity?.map((item) => (
                                  <option key={item._id} value={item.Clarity}>
                                    {item.Clarity}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.clarity &&
                                formik.errors.clarity && (
                                  <div className="text-danger">
                                    {formik.errors.clarity}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="eyeclean" className="form-label">
                                Eye Clean
                              </label>
                              <select
                                className="form-select"
                                {...formik.getFieldProps('eyeclean')}>
                                <option value="">Choose Eye Clean</option>
                                {diamond.EyeClean?.map((item) => (
                                  <option key={item._id} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.eyeclean &&
                                formik.errors.eyeclean && (
                                  <div className="text-danger">
                                    {formik.errors.eyeclean}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                              <label htmlFor="bgm" className="me-2">
                                No BGM
                              </label>
                              <input
                                name="bgm"
                                type="checkbox"
                                className="form-check-input"
                                id="bgm"
                                checked={formik.values.bgm}
                                onChange={(e) => {
                                  formik.setFieldValue('bgm', e.target.checked);
                                }}
                              />
                              {formik.touched.bgm && formik.errors.bgm && (
                                <div className="text-danger">
                                  {formik.errors.bgm}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">FINISH</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="finish" className="form-label">
                                Finish
                              </label>
                              <select
                                className="form-select"
                                {...formik.getFieldProps('finish')}>
                                <option value="">Choose Finish</option>
                                {diamond.Finish?.map((item) => (
                                  <option key={item._id} value={item.finish}>
                                    {item.finish}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.finish &&
                                formik.errors.finish && (
                                  <div className="text-danger">
                                    {formik.errors.finish}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6 mt-6">
                              <label htmlFor="cut" className="form-label">
                                Cut
                              </label>
                              <input
                                name="cut"
                                type="text"
                                className="form-control"
                                placeholder="Cut..."
                                id="cut"
                                value={formik.values.cut}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'cut',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.cut && formik.errors.cut && (
                                <div className="text-danger">
                                  {formik.errors.cut}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mt-3">
                              <label htmlFor="polish" className="form-label">
                                Polish
                              </label>
                              <input
                                name="polish"
                                type="text"
                                placeholder="Polish..."
                                className="form-control"
                                id="polish"
                                value={formik.values.polish}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'polish',
                                    e.target.value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.polish &&
                                formik.errors.polish && (
                                  <div className="text-danger">
                                    {formik.errors.polish}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6 mt-3">
                              <label htmlFor="symmetry" className="form-label">
                                Symmetry
                              </label>
                              <input
                                name="symmetry"
                                type="text"
                                className="form-control"
                                id="symmetry"
                                placeholder="Symmetry..."
                                value={formik.values.symmetry}
                                onChange={(e) => {
                                  // const value = e.target.value.replace(
                                  //   /[^0-9]/g,
                                  //   '',
                                  // ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'symmetry',
                                    e.target.value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.symmetry &&
                                formik.errors.symmetry && (
                                  <div className="text-danger">
                                    {formik.errors.symmetry}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">FLUORESCENCE</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label
                                htmlFor="fluorescence"
                                className="form-label">
                                Fluorescence
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('fluorescence')}>
                                <option value="">Choose Fluore Scence</option>
                                {diamond.Fluorescence?.map((item) => (
                                  <option key={item._id} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.fluorescence &&
                                formik.errors.fluorescence && (
                                  <div className="text-danger">
                                    {formik.errors.fluorescence}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="colourName"
                                className="form-label">
                                Colour
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('colourName')}>
                                <option value="">Choose Colour</option>
                                {diamond.color?.map((item) => (
                                  <option key={item._id} value={item.color}>
                                    {item.color}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.colourName &&
                                formik.errors.colourName && (
                                  <div className="text-danger">
                                    {formik.errors.colourName}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">GRADING</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="grading" className="form-label">
                                Grading Report
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('grading')}>
                                <option value="">Choose Grading</option>
                                {diamond.Grading?.map((item) => (
                                  <option key={item._id} value={item.grading}>
                                    {item.grading}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.grading &&
                                formik.errors.grading && (
                                  <div className="text-danger">
                                    {formik.errors.grading}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-6 ">
                              <label
                                htmlFor="reportNumber"
                                className="form-label">
                                Report Number
                              </label>
                              <input
                                name="reportNumber"
                                type="text"
                                className="form-control"
                                id="reportNumber"
                                placeholder="number..."
                                value={formik.values.reportNumber}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'reportNumber',
                                    e.target.value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.reportNumber &&
                                formik.errors.reportNumber && (
                                  <div className="text-danger">
                                    {formik.errors.reportNumber}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <label
                                htmlFor="certComment"
                                className="form-label">
                                Cert Comment
                              </label>
                              <input
                                name="certComment"
                                type="text"
                                placeholder="comment..."
                                className="form-control"
                                id="certComment"
                                value={formik.values.certComment}
                                onChange={(e) => {
                                  // Remove leading whitespace
                                  const value = e.target.value.trimStart();
                                  // Remove characters that are not digits or letters
                                  const cleanedValue = value.replace(
                                    /[^0-9a-zA-Z]/g,
                                    '',
                                  );
                                  formik.setFieldValue(
                                    'certComment',
                                    cleanedValue,
                                  );
                                }}
                              />
                              {formik.touched.certComment &&
                                formik.errors.certComment && (
                                  <div className="text-danger">
                                    {formik.errors.certComment}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">LOCATION</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label htmlFor="location" className="form-label">
                                Location
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('location')}>
                                <option value="">Choose Location</option>
                                {diamond.Location?.map((item) => (
                                  <option key={item._id} value={item?.location}>
                                    {item.location}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.location &&
                                formik.errors.location && (
                                  <div className="text-danger">
                                    {formik.errors.location}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-4">
                              <label
                                htmlFor="roughSource"
                                className="form-label">
                                Rough Source
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('roughSource')}>
                                <option value="">Choose Rough Source</option>
                                {diamond.RoughSource?.map((item) => (
                                  <option key={item._id} value={item?.source}>
                                    {item?.source}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.roughSource &&
                                formik.errors.roughSource && (
                                  <div className="text-danger">
                                    {formik.errors.roughSource}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-4">
                              <label
                                htmlFor="sourceProvide"
                                className="form-label">
                                Source Provider
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('sourceProvide')}>
                                <option value="">Choose Source Provider</option>
                                {diamond.SourceProvider?.map((item) => (
                                  <option key={item._id} value={item.name}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.sourceProvide &&
                                formik.errors.sourceProvide && (
                                  <div className="text-danger">
                                    {formik.errors.sourceProvide}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="transectraLot"
                                className="form-label">
                                Transectra Lot
                              </label>
                              <input
                                name="transectraLot"
                                type="text"
                                placeholder="lot..."
                                className="form-control"
                                id="transectraLot"
                                value={formik.values.transectraLot}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'transectraLot',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.transectraLot &&
                                formik.errors.transectraLot && (
                                  <div className="text-danger">
                                    {formik.errors.transectraLot}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">PRICE</span>
                            {/* <hr className="divider-line" /> */}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="discount" className="form-label">
                                Discount
                              </label>
                              <input
                                name="discount"
                                type="text"
                                placeholder="Discount..."
                                className="form-control"
                                id="discount"
                                value={formik.values.discount}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9.-]/g,
                                    '',
                                  ); // Allow numbers, hyphens, and dots
                                  formik.setFieldValue(
                                    'discount',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.discount &&
                                formik.errors.discount && (
                                  <div className="text-danger">
                                    {formik.errors.discount}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="price" className="form-label">
                                Price
                              </label>
                              <input
                                name="price"
                                type="text"
                                placeholder="Price..."
                                className="form-control"
                                id="price"
                                value={formik.values.price}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9.]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'price',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.price && formik.errors.price && (
                                <div className="text-danger">
                                  {formik.errors.price}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="amount" className="form-label">
                                Amount
                              </label>
                              <input
                                name="amount"
                                type="text"
                                placeholder="Amount..."
                                className="form-control"
                                id="amount"
                                value={formik.values.amount}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9.]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'amount',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.amount &&
                                formik.errors.amount && (
                                  <div className="text-danger">
                                    {formik.errors.amount}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Show only pending baki */}
                      <div className="card">
                        {/* <div className="divider-container">
                          <span className="divider-text">SHOW ONLY</span>
                          <hr className="divider-line" />
                        </div> */}
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">SHOW ONLY</span>
                            {/* <hr className="divider-line" /> */}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="md-3 mt-3">
                              <div className="col-md-12">
                                <label
                                  htmlFor="showonly"
                                  className="form-label">
                                  Show Only
                                </label>
                                <select
                                  // className="form-select"

                                  className={`form-select`}
                                  {...formik.getFieldProps('showonly')}>
                                  <option value="">Choose Show Only</option>
                                  {diamond.ShowOnly?.map((item) => (
                                    <option key={item._id} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.showonly &&
                                  formik.errors.showonly && (
                                    <div className="text-danger">
                                      {formik.errors.showonly}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">MEASUREMENT</span>
                            {/* <hr className="divider-line" /> */}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="length" className="form-label">
                                Length
                              </label>
                              <input
                                name="length"
                                type="text"
                                placeholder="Length..."
                                className="form-control"
                                id="length"
                                value={formik.values.length}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'length',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.length &&
                                formik.errors.length && (
                                  <div className="text-danger">
                                    {formik.errors.length}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="width" className="form-label">
                                Width
                              </label>
                              <input
                                name="width"
                                type="text"
                                placeholder="Width..."
                                className="form-control"
                                id="width"
                                value={formik.values.width}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'width',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.width && formik.errors.width && (
                                <div className="text-danger">
                                  {formik.errors.width}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="depth" className="form-label">
                                Depth
                              </label>
                              <input
                                name="depth"
                                type="text"
                                placeholder="Depth..."
                                className="form-control"
                                id="depth"
                                value={formik.values.depth}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'depth',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.depth && formik.errors.depth && (
                                <div className="text-danger">
                                  {formik.errors.depth}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="depthPer" className="form-label">
                                Depth %
                              </label>
                              <input
                                name="depthPer"
                                type="text"
                                placeholder="Depth %..."
                                className="form-control"
                                id="depthPer"
                                value={formik.values.depthPer}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'depthPer',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.depthPer &&
                                formik.errors.depthPer && (
                                  <div className="text-danger">
                                    {formik.errors.depthPer}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="tablePer" className="form-label">
                                Table %
                              </label>
                              <input
                                name="tablePer"
                                type="text"
                                placeholder="Table %..."
                                className="form-control"
                                id="tablePer"
                                value={formik.values.tablePer}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'tablePer',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.tablePer &&
                                formik.errors.tablePer && (
                                  <div className="text-danger">
                                    {formik.errors.tablePer}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="girdlePer" className="form-label">
                                Girdle %
                              </label>
                              <input
                                name="girdlePer"
                                placeholder="Girdle %..."
                                type="text"
                                className="form-control"
                                id="girdlePer"
                                value={formik.values.girdlePer}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'girdlePer',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.girdlePer &&
                                formik.errors.girdlePer && (
                                  <div className="text-danger">
                                    {formik.errors.girdlePer}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="crAngle" className="form-label">
                                Crown Angle
                              </label>
                              <input
                                name="crAngle"
                                type="text"
                                placeholder="Angle..."
                                className="form-control"
                                id="crAngle"
                                value={formik.values.crAngle}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'crAngle',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.crAngle &&
                                formik.errors.crAngle && (
                                  <div className="text-danger">
                                    {formik.errors.crAngle}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="crHeight" className="form-label">
                                Crown Height
                              </label>
                              <input
                                name="crHeight"
                                placeholder="Height..."
                                type="text"
                                className="form-control"
                                id="crHeight"
                                value={formik.values.crHeight}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'crHeight',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.crHeight &&
                                formik.errors.crHeight && (
                                  <div className="text-danger">
                                    {formik.errors.crHeight}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="pvAngle" className="form-label">
                                Pavilion Angle
                              </label>
                              <input
                                name="pvAngle"
                                type="text"
                                placeholder="Angle..."
                                className="form-control"
                                id="pvAngle"
                                value={formik.values.pvAngle}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'pvAngle',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.pvAngle &&
                                formik.errors.pvAngle && (
                                  <div className="text-danger">
                                    {formik.errors.pvAngle}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="pvHeight" className="form-label">
                                Pavilion Height
                              </label>
                              <input
                                name="pvHeight"
                                placeholder="Height..."
                                type="text"
                                className="form-control"
                                id="pvHeight"
                                value={formik.values.pvHeight}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'pvHeight',
                                    e.target.value.trimStart(),
                                  )
                                }
                              />
                              {formik.touched.pvHeight &&
                                formik.errors.pvHeight && (
                                  <div className="text-danger">
                                    {formik.errors.pvHeight}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-3">
                              <label htmlFor="girdle" className="form-label">
                                Girdle
                              </label>
                              <select
                                className="form-select"
                                {...formik.getFieldProps('girdle')}>
                                <option value="">Choose Girdle</option>
                                {diamond.Girdle?.map((item) => (
                                  <option key={item._id} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.girdle &&
                                formik.errors.girdle && (
                                  <div className="text-danger">
                                    {formik.errors.girdle}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="cutlet" className="form-label">
                                Cutlet
                              </label>
                              <select
                                className="form-select"
                                {...formik.getFieldProps('cutlet')}>
                                <option value="">Choose Cutles</option>
                                {diamond.Cutlet?.map((item) => (
                                  <option key={item._id} value={item.value}>
                                    {item.value}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.cutlet &&
                                formik.errors.cutlet && (
                                  <div className="text-danger">
                                    {formik.errors.cutlet}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-3">
                              <label
                                htmlFor="cutletcondition"
                                className="form-label">
                                Cutlet Condition
                              </label>
                              <select
                                className="form-select"
                                {...formik.getFieldProps('cutletcondition')}>
                                <option value="">Choose Condition</option>
                                {diamond.CutletCondition?.map((item) => (
                                  <option key={item._id} value={item.value}>
                                    {item.value}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.cutletcondition &&
                                formik.errors.cutletcondition && (
                                  <div className="text-danger">
                                    {formik.errors.cutletcondition}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-3">
                              <label htmlFor="ratio" className="form-label">
                                Ratio
                              </label>
                              <input
                                name="ratio"
                                placeholder="Ratio..."
                                type="text"
                                className="form-control"
                                id="ratio"
                                value={formik.values.ratio}
                                onChange={(e) => {
                                  const {value} = e.target;
                                  const regex = /^[0-9:.]*$/;
                                  if (regex.test(value)) {
                                    formik.setFieldValue(
                                      'ratio',
                                      value.trimStart(),
                                    );
                                  }
                                }}
                              />
                              {formik.touched.ratio && formik.errors.ratio && (
                                <div className="text-danger">
                                  {formik.errors.ratio}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">TREATMENT</span>
                            {/* <hr className="divider-line" /> */}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="treatment" className="form-label">
                                Treatment
                              </label>
                              <div className="input-group">
                                <select
                                  name="role"
                                  className="form-select"
                                  id="treatment"
                                  {...formik.getFieldProps('treatment')}
                                  aria-label="Select treatment">
                                  {diamond.Treatment?.map((item) => (
                                    <option key={item._id} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {formik.touched.treatment &&
                                formik.errors.treatment && (
                                  <div className="text-danger">
                                    {formik.errors.treatment}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="inclusion" className="form-label">
                                Treatment Type
                              </label>
                              <select
                                className="form-select"
                                {...formik.getFieldProps('treatmentType')}>
                                <option value="">Choose Treatment Type</option>
                                {diamond.Treatment_type?.map((item) => (
                                  <option key={item._id} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.treatmentType &&
                                formik.errors.treatmentType && (
                                  <div className="text-danger">
                                    {formik.errors.treatmentType}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">ADVANCE</span>
                            {/* <hr className="divider-line" /> */}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label htmlFor="shade" className="form-label">
                                Shade
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('shade')}>
                                <option value="">Choose Shade</option>
                                {diamond.Shade?.map((item) => (
                                  <option key={item._id} value={item.name}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.shade && formik.errors.shade && (
                                <div className="text-danger">
                                  {formik.errors.shade}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="shadekey" className="form-label">
                                Shade Key
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('shadekey')}>
                                <option value="">Choose Shade Key</option>
                                {diamond.Shade_Type?.map((item) => (
                                  <option key={item._id} value={item.key}>
                                    {item?.key}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.shadekey &&
                                formik.errors.shadekey && (
                                  <div className="text-danger">
                                    {formik.errors.shadekey}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6 mt-3">
                              <label htmlFor="key" className="form-label">
                                Key
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('key')}>
                                <option value="">Choose Key</option>
                                {diamond.Key_Type?.map((item) => (
                                  <option key={item._id} value={item.item}>
                                    {item?.key}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.key && formik.errors.key && (
                                <div className="text-danger">
                                  {formik.errors.key}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mt-3">
                              <label htmlFor="keysymbol" className="form-label">
                                Symbol
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('keysymbol')}>
                                <option value="">Choose Symbol</option>
                                {diamond.KeySymbol?.map((item) => (
                                  <option key={item._id} value={item.item}>
                                    {item?.symbol}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.keysymbol &&
                                formik.errors.keysymbol && (
                                  <div className="text-danger">
                                    {formik.errors.keysymbol}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            {/* <div className="col-md-6">
                              <label htmlFor="stock_num" className="form-label">
                                Stock Number
                              </label>
                              <input
                                name="stock_num"
                                type="text"
                                placeholder="Number..."
                                className="form-control"
                                id="stock_num"
                                value={formik.values.stock_num}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'stock_num',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.stock_num &&
                                formik.errors.stock_num && (
                                  <div className="text-danger">
                                    {formik.errors.stock_num}
                                  </div>
                                )}
                            </div> */}
                            <div className="col-md-12">
                              <label htmlFor="cert_num" className="form-label">
                                Certificate Number
                              </label>
                              <input
                                name="cert_num"
                                type="text"
                                placeholder="Number..."
                                className="form-control"
                                id="cert_num"
                                value={formik.values.cert_num}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'cert_num',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.cert_num &&
                                formik.errors.cert_num && (
                                  <div className="text-danger">
                                    {formik.errors.cert_num}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6 mt-3">
                              <label
                                htmlFor="currency_code"
                                className="form-label">
                                Currency Code
                              </label>
                              <select
                                name="currency_code"
                                className={`form-select`}
                                id="currency_code"
                                value={formik.values.currency_code}
                                onChange={formik.handleChange}>
                                <option value="" label="Select code" />
                                {codes?.map((currency) => (
                                  <option
                                    key={currency.code}
                                    value={currency.code}>
                                    {currency.code}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.currency_code &&
                                formik.errors.currency_code && (
                                  <div className="text-danger">
                                    {formik.errors.currency_code}
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6 mt-3">
                              <label
                                htmlFor="currency_symbol"
                                className="form-label">
                                Currency Symbol
                              </label>
                              <select
                                name="currency_symbol"
                                className={`form-select`}
                                id="currency_symbol"
                                value={formik.values.currency_symbol}
                                onChange={formik.handleChange}>
                                <option value="" label="Select symbol" />
                                {codes.map((currency) => (
                                  <option
                                    key={currency.symbol}
                                    value={currency.symbol}>
                                    {currency.symbol} ({currency.name})
                                  </option>
                                ))}
                              </select>
                              {formik.touched.currency_symbol &&
                                formik.errors.currency_symbol && (
                                  <div className="text-danger">
                                    {formik.errors.currency_symbol}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-12 mt-3">
                              <label htmlFor="brandName" className="form-label">
                                Brand Name
                              </label>
                              <select
                                className={`form-select`}
                                {...formik.getFieldProps('brandName')}>
                                <option value="">Choose Brand</option>
                                {diamond.Brand?.map((item) => (
                                  <option key={item._id} value={item.value}>
                                    {item.value}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.brandName &&
                                formik.errors.brandName && (
                                  <div className="text-danger">
                                    {formik.errors.brandName}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">INCLUSIONS</span>
                            {/* <hr className="divider-line" /> */}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-3">
                              <label htmlFor="milky" className="form-label">
                                Milky
                              </label>
                              <input
                                name="milky"
                                type="text"
                                placeholder="Number..."
                                className="form-control"
                                id="milky"
                                value={formik.values.milky}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'milky',
                                    e.target.value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.milky && formik.errors.milky && (
                                <div className="text-danger">
                                  {formik.errors.milky}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="black" className="form-label">
                                Black
                              </label>
                              <input
                                name="black"
                                type="text"
                                placeholder="Black..."
                                className="form-control"
                                id="black"
                                value={formik.values.black}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'black',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.black && formik.errors.black && (
                                <div className="text-danger">
                                  {formik.errors.black}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="white" className="form-label">
                                White
                              </label>
                              <input
                                name="white"
                                type="text"
                                placeholder="White..."
                                className="form-control"
                                id="white"
                                value={formik.values.white}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'white',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.white && formik.errors.white && (
                                <div className="text-danger">
                                  {formik.errors.white}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="open" className="form-label">
                                Open
                              </label>
                              <input
                                name="open"
                                type="text"
                                placeholder="Open..."
                                className="form-control"
                                id="open"
                                value={formik.values.open}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'open',
                                    value.trimStart(),
                                  );
                                }}
                              />
                              {formik.touched.open && formik.errors.open && (
                                <div className="text-danger">
                                  {formik.errors.open}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">MEDIA</span>
                            {/* <hr className="divider-line" /> */}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label htmlFor="image" className="form-label">
                                Select Image
                              </label>
                              <FormGroup>
                                <input
                                  className="form-control form-control-file"
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  id="image"
                                  onChange={handleFileChangeImg}
                                />

                                {formik.touched.image &&
                                  formik.errors.image &&
                                  !url && (
                                    <div className="text-danger">
                                      {formik.errors.image}
                                    </div>
                                  )}
                              </FormGroup>
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="video" className="form-label">
                                Select Video
                              </label>
                              <FormGroup>
                                <input
                                  className="form-control form-control-file"
                                  type="file"
                                  name="video"
                                  accept="video/*"
                                  id="video"
                                  onChange={handleFileChangeVideo}
                                />
                                {formik.touched.video &&
                                  formik.errors.video &&
                                  !urlVideo && (
                                    <div className="text-danger">
                                      {formik.errors.video}
                                    </div>
                                  )}
                              </FormGroup>
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="pdfFile" className="form-label">
                                Select PDF
                              </label>
                              <FormGroup>
                                <input
                                  className="form-control form-control-file"
                                  type="file"
                                  name="pdfFile"
                                  accept=".pdf"
                                  id="pdfFile"
                                  onChange={handleFileChangePdf}
                                />
                                {formik.touched.pdf &&
                                  formik.errors.pdf &&
                                  !url && (
                                    <div className="text-danger">
                                      {formik.errors.pdf}
                                    </div>
                                  )}
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-4">
                        <div className="card-header">
                          <div className="divider-container">
                            <span className="divider-text">
                              OTHER INFORMATION
                            </span>
                            {/* <hr className="divider-line" /> */}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-3">
                              <label htmlFor="image" className="form-label">
                                Matched Pair
                              </label>

                              <input
                                type="checkbox"
                                className="form-check"
                                checked={formik.values.matchedPair}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'matchedPair',
                                    e.target.checked,
                                  )
                                }
                              />
                            </div>

                            <div className="col-md-3">
                              <label htmlFor="video" className="form-label">
                                Parcel
                              </label>
                              <input
                                type="checkbox"
                                //   defaultChecked={true}
                                checked={formik.values.parcel}
                                //   onChange={(e) => setParcel(e.target.checked)}
                                //   checked={formik.values.is_active}
                                className="form-check"
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'parcel',
                                    e.target.checked,
                                  )
                                }
                              />
                            </div>

                            <div className="col-md-3">
                              <label htmlFor="on_hold" className="form-label">
                                On Hold
                              </label>
                              <input
                                type="checkbox"
                                //   defaultChecked={true}
                                checked={formik.values.on_hold}
                                //   onChange={(e) => setParcel(e.target.checked)}
                                //   checked={formik.values.is_active}
                                disabled
                                className="form-check"
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'on_hold',
                                    e.target.checked,
                                  )
                                }
                              />
                            </div>

                            <div className="col-md-3">
                              <label htmlFor="pdfFile" className="form-label">
                                Number of diamonds
                              </label>
                              <input
                                type="text"
                                placeholder="Type......."
                                className="form-control"
                                value={formik.values.numberdiamons}
                                onChange={(e) => {
                                  const value = e.target.value.trimStart();
                                  const numericValue = value.replace(
                                    /[^0-9]/g,
                                    '',
                                  ); // Remove non-numeric characters
                                  formik.setFieldValue(
                                    'numberdiamons',
                                    numericValue,
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="row mb-3">
                        <div className="col-md-3">
                          <label htmlFor="brandName" className="form-label">
                            Brand Name
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('brandName')}>
                            <option value="">Choose Brand</option>
                            {diamond.Brand?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.value}
                              </option>
                            ))}
                          </select>
                          {formik.touched.brandName &&
                            formik.errors.brandName && (
                              <div className="text-danger">
                                {formik.errors.brandName}
                              </div>
                            )}
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="carat" className="form-label">
                            Carat
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('carat')}>
                            <option value="">Choose Carat</option>
                            {diamond.Carat?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.from + '-' + item.to}
                              </option>
                            ))}
                          </select>
                          {formik.touched.carat && formik.errors.carat && (
                            <div className="text-danger">
                              {formik.errors.carat}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="clarity" className="form-label">
                            Clarity
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('clarity')}>
                            <option value="">Choose Clarity</option>
                            {diamond.Clarity?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.Clarity}
                              </option>
                            ))}
                          </select>
                          {formik.touched.clarity && formik.errors.clarity && (
                            <div className="text-danger">
                              {formik.errors.clarity}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="colour" className="form-label">
                            Colour
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('colour')}>
                            <option value="">Choose Colour</option>
                            {diamond.Color?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.color}
                              </option>
                            ))}
                          </select>
                          {formik.touched.colour && formik.errors.colour && (
                            <div className="text-danger">
                              {formik.errors.colour}
                            </div>
                          )}
                        </div>
                      </div> */}
                      {/* 
                      <div className="row md-3">
                        <div className="col-md-3">
                          <label htmlFor="colourType" className="form-label">
                            Colour-Type
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('colourType')}>
                            <option value="">Choose Colour-Type</option>
                            {diamond.ColorType?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.colourType &&
                            formik.errors.colourType && (
                              <div className="text-danger">
                                {formik.errors.colourType}
                              </div>
                            )}
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="cut" className="form-label">
                            Cut
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('cut')}>
                            <option value="">Choose Cut</option>
                            {diamond.Cut?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.from + '-' + item.to}
                              </option>
                            ))}
                          </select>
                          {formik.touched.cut && formik.errors.cut && (
                            <div className="text-danger">
                              {formik.errors.cut}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="cutlet" className="form-label">
                            Cutlet
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('cutlet')}>
                            <option value="">Choose Cutles</option>
                            {diamond.Cutlet?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.value}
                              </option>
                            ))}
                          </select>
                          {formik.touched.cutlet && formik.errors.cutlet && (
                            <div className="text-danger">
                              {formik.errors.cutlet}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label
                            htmlFor="cutletcondition"
                            className="form-label">
                            Cutlet Condition
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('cutletcondition')}>
                            <option value="">Choose Condition</option>
                            {diamond.CutletCondition?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.value}
                              </option>
                            ))}
                          </select>
                          {formik.touched.cutletcondition &&
                            formik.errors.cutletcondition && (
                              <div className="text-danger">
                                {formik.errors.cutletcondition}
                              </div>
                            )}
                        </div>
                      </div> */}

                      {/* <div className="row md-3 mt-3">
                        <div className="col-md-3">
                          <label htmlFor="treatment" className="form-label">
                            Treatment
                          </label>
                          <div className="input-group">
                            <select
                              name="role"
                              className={`form-select`}
                              id="treatment"
                              {...formik.getFieldProps('treatment')}
                              aria-label="Select treatment">
                              <option value="">Select a treatment...</option>
                              <option value="No Treatment">No Treatment</option>
                              <option value="Show only trested">
                                Show only trested
                              </option>
                              <option value="Inclube treated diamonds in results">
                                Inclube treated diamonds in results
                              </option>
                            </select>
                          </div>
                          {formik.touched.treatment &&
                            formik.errors.treatment && (
                              <div className="text-danger">
                                {formik.errors.treatment}
                              </div>
                            )}
                        </div>
                 

                        <div className="col-md-3">
                          <label htmlFor="finish" className="form-label">
                            Finish
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('finish')}>
                            <option value="">Choose Finish</option>
                            {diamond.Finish?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.finish}
                              </option>
                            ))}
                          </select>
                          {formik.touched.finish && formik.errors.finish && (
                            <div className="text-danger">
                              {formik.errors.finish}
                            </div>
                          )}
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="fluorescence" className="form-label">
                            Fluorescence
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('fluorescence')}>
                            <option value="">Choose Fluore Scence</option>
                            {diamond.Fluorescence?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.fluorescence &&
                            formik.errors.fluorescence && (
                              <div className="text-danger">
                                {formik.errors.fluorescence}
                              </div>
                            )}
                        </div>
                      </div> */}

                      {/* <div className="row md-3 mt-3">
                        <div className="col-md-3">
                          <label htmlFor="girdle" className="form-label">
                            Girdle
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('girdle')}>
                            <option value="">Choose Girdle</option>
                            {diamond.Girdle?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.girdle && formik.errors.girdle && (
                            <div className="text-danger">
                              {formik.errors.girdle}
                            </div>
                          )}
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="grading" className="form-label">
                            Grading
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('grading')}>
                            <option value="">Choose Grading</option>
                            {diamond.Grading?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.grading}
                              </option>
                            ))}
                          </select>
                          {formik.touched.grading && formik.errors.grading && (
                            <div className="text-danger">
                              {formik.errors.grading}
                            </div>
                          )}
                        </div>

                        <div className="col-md-3">
                          <label
                            htmlFor="intensity_name"
                            className="form-label">
                            Intensity
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('intensity_name')}>
                            <option value="">Choose Intensity</option>
                            {diamond.Intensity?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.intensity_name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.intensity_name &&
                            formik.errors.intensity_name && (
                              <div className="text-danger">
                                {formik.errors.intensity_name}
                              </div>
                            )}
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="keysymbol" className="form-label">
                            Key Symbol
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('keysymbol')}>
                            <option value="">Choose Key Symbol</option>
                            {diamond.KeySymbol?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.key + '-' + item?.symbol}
                              </option>
                            ))}
                          </select>
                          {formik.touched.keysymbol &&
                            formik.errors.keysymbol && (
                              <div className="text-danger">
                                {formik.errors.keysymbol}
                              </div>
                            )}
                        </div>
                      </div> */}

                      {/* <div className="row md-3 mt-3">
                        <div className="col-md-3">
                          <label htmlFor="location" className="form-label">
                            Location
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('location')}>
                            <option value="">Choose Location</option>
                            {diamond.Location?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.location}
                              </option>
                            ))}
                          </select>
                          {formik.touched.location &&
                            formik.errors.location && (
                              <div className="text-danger">
                                {formik.errors.location}
                              </div>
                            )}
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="overtone" className="form-label">
                            Overtone
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('overtone')}>
                            <option value="">Choose Overtone</option>
                            {diamond.Overtone?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.overtone &&
                            formik.errors.overtone && (
                              <div className="text-danger">
                                {formik.errors.overtone}
                              </div>
                            )}
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="polish" className="form-label">
                            Polish
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('polish')}>
                            <option value="">Choose Polish</option>
                            {diamond.Polish?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item?.from + '-' + item?.to}
                              </option>
                            ))}
                          </select>
                          {formik.touched.polish && formik.errors.polish && (
                            <div className="text-danger">
                              {formik.errors.polish}
                            </div>
                          )}
                        </div>

                        <div className="col-md-3">
                          <label htmlFor="reportNumber" className="form-label">
                            Report Number
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('reportNumber')}>
                            <option value="">Choose Report Number</option>
                            {diamond.ReportNumber?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item?.number}
                              </option>
                            ))}
                          </select>
                          {formik.touched.reportNumber &&
                            formik.errors.reportNumber && (
                              <div className="text-danger">
                                {formik.errors.reportNumber}
                              </div>
                            )}
                        </div>
                      </div> */}

                      {/* <div className="row md-3 mt-3">
                        <div className="col-md-4">
                          <label htmlFor="transectraLot" className="form-label">
                            Transectra Lot
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('transectraLot')}>
                            <option value="">Choose Transectra Lot</option>
                            {diamond.TransectraLot?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item?.value}
                              </option>
                            ))}
                          </select>
                          {formik.touched.transectraLot &&
                            formik.errors.transectraLot && (
                              <div className="text-danger">
                                {formik.errors.transectraLot}
                              </div>
                            )}
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="certComment" className="form-label">
                            Cert Comment
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('certComment')}>
                            <option value="">Choose Cert Comment </option>
                            {diamond.CertComment?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item?.comment}
                              </option>
                            ))}
                          </select>
                          {formik.touched.certComment &&
                            formik.errors.certComment && (
                              <div className="text-danger">
                                {formik.errors.certComment}
                              </div>
                            )}
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="symmetry" className="form-label">
                            Symmetry
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('symmetry')}>
                            <option value="">Choose Symmetry</option>
                            {diamond.Symmetry?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item?.from + '-' + item?.to}
                              </option>
                            ))}
                          </select>
                          {formik.touched.symmetry &&
                            formik.errors.symmetry && (
                              <div className="text-danger">
                                {formik.errors.symmetry}
                              </div>
                            )}
                        </div>
                      </div> */}

                      {/* <div className="row md-3 mt-3">
                        <div className="col-md-4">
                          <label htmlFor="crown" className="form-label">
                            Crown
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('crown')}>
                            <option value="">Choose Crown </option>
                            {diamond.Crown?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.crown?.cr_angle?.from} -{' '}
                                {item.crown?.cr_angle?.to} |{' '}
                                {item.crown?.cr_height?.from} -{' '}
                                {item.crown?.cr_height?.to}
                              </option>
                            ))}
                          </select>
                          {formik.touched.crown && formik.errors.crown && (
                            <div className="text-danger">
                              {formik.errors.crown}
                            </div>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="price" className="form-label">
                            Price
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('price')}>
                            <option value="">Choose Price </option>
                            {diamond.Price?.flatMap((item) =>
                              item.prices.map((price) => (
                                <option key={price._id} value={price._id}>
                                  Discount: {price.discount} | Price:{' '}
                                  {price.price} | Amount: {price.amount}
                                </option>
                              )),
                            )}
                          </select>
                          {formik.touched.price && formik.errors.price && (
                            <div className="text-danger">
                              {formik.errors.price}
                            </div>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="pavillion" className="form-label">
                            Pavillion
                          </label>
                          <select
                            // className="form-select"

                            className={`form-select`}
                            {...formik.getFieldProps('pavillion')}>
                            <option value="">Choose Price </option>
                            {diamond.Pavillion?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.pavillion?.pv_angle?.from} -{' '}
                                {item.pavillion?.pv_angle?.to} |{' '}
                                {item.pavillion?.pv_height?.from} -{' '}
                                {item.pavillion?.pv_height?.to}
                              </option>
                            ))}
                          </select>
                          {formik.touched.pavillion &&
                            formik.errors.pavillion && (
                              <div className="text-danger">
                                {formik.errors.pavillion}
                              </div>
                            )}
                        </div>
                      </div> */}

                      {/* <div className="row md-3 mt-3">
                        <div className="col-md-6">
                          <label htmlFor="inclusion" className="form-label">
                            Treatment Type
                          </label>
                          <select
                            className={`form-select`}
                            {...formik.getFieldProps('treatmentType')}>
                            <option value="">Choose Treatment Type</option>
                            {diamond.Treatment_type?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.treatmentType &&
                            formik.errors.treatmentType && (
                              <div className="text-danger">
                                {formik.errors.treatmentType}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="treatmentType" className="form-label">
                            Inclusion
                          </label>
                          <select
                            className={`form-select`}
                            {...formik.getFieldProps('inclusion')}>
                            <option value="">Choose Inclusion</option>
                            {diamond.Inclusion?.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.inclusons.black.join(', ')} |{' '}
                                {item.inclusons.white.join(', ')} |{' '}
                                {item.inclusons.open.join(', ')} |{' '}
                                {item.inclusons.milky
                                  .map((m) => `${m.form} to ${m.to}`)
                                  .join(', ')}
                              </option>
                            ))}
                          </select>
                          {formik.touched.inclusion &&
                            formik.errors.inclusion && (
                              <div className="text-danger">
                                {formik.errors.inclusion}
                              </div>
                            )}
                        </div>
                      </div> */}

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Edit Diamond"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditDiamond;
