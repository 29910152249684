import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Country, State} from 'country-state-city';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';
import {countr} from '../../../CurrencyCodes/country';
import {cointrystate} from '../../../CurrencyCodes/state';

const AddRegionalOffices = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const user = JSON.parse(getUser());

  // const getFullCountryName = (countryCode) => {
  //   const country = Country.getCountryByCode(countryCode);
  //   return country ? country.name : '';
  // };

  // const getFullStateName = (countryCode, stateCode) => {
  //   const state = State.getStateByCodeAndCountry(stateCode, countryCode);
  //   return state ? state.name : '';
  // };

  const formik = useFormik({
    initialValues: {
      country: '',
      state: '',
      email: '',
      phone_no: '',
      option1: '',
      option2: '',
    },
    validationSchema: Yup.object({
      country: Yup.string().required('Please select a country'),
      state: Yup.string().required('Please select a state'),
      email: Yup.string()
        .email('Invalid email')
        .required('Please enter your email'),
      phone_no: Yup.string().required('Enter phone number'),
      option1: Yup.string().required('Enter address'),
      option2: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      const {country, state, email, phone_no, option1, option2} = values;

      const selectedCountry = countr.find((c) => c.id === country);
      const selectedState = cointrystate.find((s) => s.id === state);

      const body = {
        country: selectedCountry?.name || '',
        state: selectedState?.name || '',
        email: email,
        phone_number: phone_no,
        address_line1: option1,
        address_line2: option2,
        admin_id: user?._id,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI(`regionaloffice`, 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/regional-offices');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    formik.setFieldValue('country', countryId);
    const filteredStates = cointrystate.filter(
      (state) => state.country_id === countryId,
    );
    setStates(filteredStates);
    formik.setFieldValue('state', '');
  };

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/regional-offices">Regional Offices</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Regional Offices</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="country" className="form-label">
                            Country
                          </label>
                          {/* <select
                            name="country"
                            className="form-control"
                            id="country"
                            value={formik.values.country}
                            onChange={handleCountryChange}>
                            <option value="">Select Country</option>
                            {Country.getAllCountries().map((country) => (
                              <option
                                key={country.isoCode}
                                value={country.isoCode}>
                                {country.name}
                              </option>
                            ))}
                          </select> */}
                          <select
                            name="country"
                            className="form-select"
                            id="country"
                            value={formik.values.country}
                            onChange={handleCountryChange}>
                            <option value="" label="Select country" />
                            {countr.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.country && formik.errors.country && (
                            <div className="text-danger">
                              {formik.errors.country}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="state" className="form-label">
                            State
                          </label>
                          <select
                            name="state"
                            className="form-control"
                            id="state"
                            value={formik.values.state}
                            onChange={(e) =>
                              formik.setFieldValue('state', e.target.value)
                            }>
                            <option value="">Select State</option>
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.state && formik.errors.state && (
                            <div className="text-danger">
                              {formik.errors.state}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="option1" className="form-label">
                            Address 1
                          </label>
                          <input
                            name="option1"
                            type="text"
                            className="form-control"
                            id="option1"
                            value={formik.values.option1}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'option1',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.option1 && formik.errors.option1 && (
                            <div className="text-danger">
                              {formik.errors.option1}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="option2" className="form-label">
                            Address 2
                          </label>
                          <input
                            name="option2"
                            type="text"
                            className="form-control"
                            id="option2"
                            value={formik.values.option2}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'option2',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.option2 && formik.errors.option2 && (
                            <div className="text-danger">
                              {formik.errors.option2}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            email="email"
                            type="text"
                            className="form-control"
                            id="email"
                            value={formik.values.email}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'email',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="phone_no" className="form-label">
                            Phone
                          </label>
                          <input
                            name="phone_no"
                            type="text"
                            className="form-control"
                            id="phone_no"
                            value={formik.values.phone_no}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue(
                                'phone_no',
                                value.trimStart(),
                              );
                            }}
                            maxLength={10}
                          />
                          {formik.touched.phone_no &&
                            formik.errors.phone_no && (
                              <div className="text-danger">
                                {formik.errors.phone_no}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Add Regional Offices"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddRegionalOffices;
