import AddCaratPage from '../pages/Home/CaratPage/AddCaratPage';
import CaratPage from '../pages/Home/CaratPage/CaratPage';
import EditCaratPage from '../pages/Home/CaratPage/EditCaratPage';
import AddColorType from '../pages/Home/ColorTypePage/AddColorType';
import ColorType from '../pages/Home/ColorTypePage/ColorType';
import EditColorType from '../pages/Home/ColorTypePage/EditColorType';
import Dashboardpage from '../pages/Home/Dashboardpage/Dashboardpage';
import AddIntensityPage from '../pages/Home/IntensityPage/AddIntensityPage';
import EditIntensityPage from '../pages/Home/IntensityPage/EditIntensityPage';
import IntensityPage from '../pages/Home/IntensityPage/IntensityPage';
import AddOverTonePage from '../pages/Home/OverTonePage/AddOverTonePage';
import EditOvdrTonePage from '../pages/Home/OverTonePage/EditOvdrTonePage';
import OverTonePage from '../pages/Home/OverTonePage/OverTonePage';
import AddCoreFeature from '../pages/Home/PackagePage/AddCoreFeature';
import AddPackage from '../pages/Home/PackagePage/AddPackage';
import CoreFeaturesPage from '../pages/Home/PackagePage/CoreFeaturesPage';
import EditCoreFeturesPage from '../pages/Home/PackagePage/EditCoreFeturesPage';
import PackageAnnuallyPage from '../pages/Home/PackagePage/PackageAnnuallyPage';
import PackagePage from '../pages/Home/PackagePage/PackagePage';
import AddShapePage from '../pages/Home/Shapepage/AddShapePage';
import EditShapePage from '../pages/Home/Shapepage/EditShapePage';
import Shapepage from '../pages/Home/Shapepage/Shapepage';

import AddColourPage from '../pages/Home/ColorPage/AddColourPage';
import ColorPage from '../pages/Home/ColorPage/ColorPage';
import EditColourPage from '../pages/Home/ColorPage/EditColourPage';

import AddClarityPage from '../pages/Home/ClarityPage/AddClarityPage';
import ClarityPage from '../pages/Home/ClarityPage/ClarityPage';
import EditClarityPage from '../pages/Home/ClarityPage/EditClarityPage';

import AddEyeCleanPage from '../pages/Home/EyeCleanPage/AddEyeCleanPage';
import EditEyeCleanPage from '../pages/Home/EyeCleanPage/EditEyeCleanPage';
import EyeCleanPage from '../pages/Home/EyeCleanPage/EyeCleanPage';

import AddFinishPage from '../pages/Home/FinishPage/AddFinishPage';
import EditFinishPage from '../pages/Home/FinishPage/EditFinishPage';
import FinishPage from '../pages/Home/FinishPage/FinishPage';

import AddFluoreScencePage from '../pages/Home/FluoreScencePage/AddFluoreScencePage';
import EditFluoreScemcePage from '../pages/Home/FluoreScencePage/EditFluoreScemcePage';
import FluoreScencePage from '../pages/Home/FluoreScencePage/FluoreScencePage';
import AddGradingPage from '../pages/Home/GradingPage/AddGradingPage';
import EditGradingPage from '../pages/Home/GradingPage/EditGradingPage';
import GradingPage from '../pages/Home/GradingPage/GradingPage';
import AddReportNumberPage from '../pages/Home/ReportNumberPage/AddReportNumberPage';
import EditReportNumberPage from '../pages/Home/ReportNumberPage/EditReportNumberPage';
import ReportNumber from '../pages/Home/ReportNumberPage/ReportNumber';
import AddCertCommentPage from '../pages/Home/CertCommentPage/AddCertCommentPage';
import CertCommnetPage from '../pages/Home/CertCommentPage/CertCommnetPage';
import EditCertCommentPage from '../pages/Home/CertCommentPage/EditCertCommentPage';
import AddLocationPage from '../pages/Home/LocationPage.js/AddLocationPage';
import EditLocationPage from '../pages/Home/LocationPage.js/EditLocationPage';
import LocationPage from '../pages/Home/LocationPage.js/LocationPage';
import RoughSourcePage from '../pages/Home/RoughSourcePage/RoughSourcePage';

import PrivateRoute from './RouteProtection';
import EditPackageAnnually from '../pages/Home/PackagePage/EditPackageAnnually';
import AddAnnuallyPage from '../pages/Home/PackagePage/AddAnnuallyPage';
import EditMonthlyPackage from '../pages/Home/PackagePage/EditMonthlyPackage';

import AddRoughSourcePage from '../pages/Home/RoughSourcePage/AddRoughSourcePage';
import EditRoughSourcePage from '../pages/Home/RoughSourcePage/EditRoughSourcePage';
import SourceProviderPage from '../pages/Home/SourceProviderPage/SourceProviderPage';
import AddSourceProviderPage from '../pages/Home/SourceProviderPage/AddSourceProviderPage';
import EditSourceProviderPage from '../pages/Home/SourceProviderPage/EditSourceProviderPage';
import CrownPage from '../pages/Home/CrownPage/CrownPage';
import AddCrownPage from '../pages/Home/CrownPage/AddCrownPage';
import EditCrownPage from '../pages/Home/CrownPage/EditCrownPage';
import PavillionPage from '../pages/Home/PavillionPage/PavillionPage';
import AddPavillionPage from '../pages/Home/PavillionPage/AddPavillionPage';
import EditPavillionPage from '../pages/Home/PavillionPage/EditPavillionPage';
import GridlePage from '../pages/Home/GridlePage/GridlePage';
import AddGridlePage from '../pages/Home/GridlePage/AddGridlePage';
import EditGridlePage from '../pages/Home/GridlePage/EditGridlePage';
import CuletPage from '../pages/Home/CuletPage/CuletPage';
import AddCuletPage from '../pages/Home/CuletPage/AddCuletPage';
import EditCuletPage from '../pages/Home/CuletPage/EditCuletPage';
import EditKeySymbolPage from '../pages/Home/KeySymbolPage/EditKeySymbolPage';
import AddKeySymbolPage from '../pages/Home/KeySymbolPage/AddKeySymbolPage';
import KeySymbolPage from '../pages/Home/KeySymbolPage/KeySymbolPage';
import ShadePage from '../pages/Home/ShadePage/ShadePage';
import AddShadePage from '../pages/Home/ShadePage/AddShadePage';
import EditShadePage from '../pages/Home/ShadePage/EditShadePage';
import AddBrandPage from '../pages/Home/BrandPage/AddBrandPage';
import EditBrandPage from '../pages/Home/BrandPage/EditBrandPage';
import BrandPage from '../pages/Home/BrandPage/BrandPage';
import TranscetraLotPage from '../pages/Home/TranscetraLotPage/TranscetraLotPage';
import AddTranscetraLotPage from '../pages/Home/TranscetraLotPage/AddTranscetraLotPage';
import EditTranscetraLotPage from '../pages/Home/TranscetraLotPage/EditTranscetraLotPage';
import MeasurementPage from '../pages/Home/MeasurementPage/MeasurementPage';
import AddMeasurementPage from '../pages/Home/MeasurementPage/AddMeasurementPage';
import EditMeasurementPage from '../pages/Home/MeasurementPage/EditMeasurementPage';
import CuletConditionPage from '../pages/Home/CuletConditionPage/CuletConditionPage';
import AddCuletConditionPage from '../pages/Home/CuletConditionPage/AddCuletConditionPage';
import EditCuletConditionPage from '../pages/Home/CuletConditionPage/EditCuletConditionPage';
import AddDiamond from '../pages/Home/AddDiamondPage/AddDiamond';
import GetDiamond from '../pages/Home/AddDiamondPage/GetDiamond';
import PricePage from '../pages/Home/PricePage/PricePage';
import AddPricePage from '../pages/Home/PricePage/AddPricePage';
import EditPricePage from '../pages/Home/PricePage/EditPricePage';
import TreamentPage from '../pages/Home/TreamentPage/TreamentPage';
import AddTreamentPage from '../pages/Home/TreamentPage/AddTreamentPage';
import EditTreamentPage from '../pages/Home/TreamentPage/EditTreamentPage';
import TreatmentType from '../pages/Home/TreatmentType/TreatmentType';
import AddTreatmentType from '../pages/Home/TreatmentType/AddTreatmentType';
import EditTreatmentType from '../pages/Home/TreatmentType/EditTreatmentType';
import ShowonlyPage from '../pages/Home/ShowonlyPage/ShowonlyPage';
import AddShowonlyPage from '../pages/Home/ShowonlyPage/AddShowonlyPage';
import EditShowonlyPage from '../pages/Home/ShowonlyPage/EditShowonlyPage';
import EditDiamond from '../pages/Home/AddDiamondPage/EditDiamond';
import KeyPage from '../pages/Home/KeyPage/KeyPage';
import AddKeyPage from '../pages/Home/KeyPage/AddKeyPage';
import EditKeyPage from '../pages/Home/KeyPage/EditKeyPage';
import ShadeTypePage from '../pages/Home/ShadeTypePage/ShadeTypePage';
import AddShadeTypePage from '../pages/Home/ShadeTypePage/AddShadeTypePage';
import EditShadeTypePage from '../pages/Home/ShadeTypePage/EditShadeTypePage';
import UserVerifyPage from '../pages/Home/UserVerifyPage/UserVerifyPage';
import SalesExecutive from '../pages/Home/SalesExecutive/SalesExecutive';
import EditSalesExecutive from '../pages/Home/SalesExecutive/EditSalesExecutive';
import AddSalesExecutive from '../pages/Home/SalesExecutive/AddSalesExecutive';
import EditUserVerifyPage from '../pages/Home/UserVerifyPage/EditUserVerifyPage';
import EditAdminProfile from '../pages/Home/EditAdminProfilePage/EditAdminProfile';
import BuyerRequestPage from '../pages/Home/BuyerRequestPage/BuyerRequestPage';
import ViewRequestPage from '../pages/Home/BuyerRequestPage/ViewRequestPage';
import RapNetPriceListPage from '../pages/Home/RapNetPricePage/RapNetPriceListPage';
import RapaportPriceList from '../pages/Home/RapaportPriceListPage/RapaportPriceList';
import UploadDiamond from '../pages/Home/AddDiamondPage/UploadDiamond';
import RegionalOffices from '../pages/Home/RegionalOfficesPage/RegionalOffices';
import AddRegionalOffices from '../pages/Home/RegionalOfficesPage/AddRegionalOffices';
import EditRegionalOffices from '../pages/Home/RegionalOfficesPage/EditRegionalOffices';
import CompanySize from '../pages/Home/CompanySizePage/CompanySize';
import AddCompany from '../pages/Home/CompanySizePage/AddCompany';
import EditCompany from '../pages/Home/CompanySizePage/EditCompany';
import CompanyType from '../pages/Home/CompanyTypePage/CompanyType';
import AddCompanyType from '../pages/Home/CompanyTypePage/AddCompanyType';
import EditCompanyType from '../pages/Home/CompanyTypePage/EditCompanyType';
import CountryPrice from '../pages/Home/CountryPricePage/CountryPrice';
import AddCountryPrice from '../pages/Home/CountryPricePage/AddCountryPrice';
import EditCountryPrice from '../pages/Home/CountryPricePage/EditCountryPrice';
import AggrementPage from '../pages/Home/ConditionPage/AggrementPage';
import ConductPage from '../pages/Home/ConditionPage/ConductPage';
import PolicyPage from '../pages/Home/ConditionPage/PolicyPage';
import TermPage from '../pages/Home/ConditionPage/TermPage';
import MarkupPage from '../pages/Home/MarkupPage/MarkupPage';
import Announcements from '../pages/Home/AnnouncementsPage/Announcements';
import AddAnnouncements from '../pages/Home/AnnouncementsPage/AddAnnouncements';
import EditAnnouncements from '../pages/Home/AnnouncementsPage/EditAnnouncements';
import CutPage from '../pages/Home/CutPage/CutPage';
import AddCutPage from '../pages/Home/CutPage/AddCutPage';
import EditCutPage from '../pages/Home/CutPage/EditCutPage';
import Polishing from '../pages/Home/PolishingPage/Polishing';
import EditPolishing from '../pages/Home/PolishingPage/EditPolishing';
import AddPolishing from '../pages/Home/PolishingPage/AddPolishing';
import Symmetry from '../pages/Home/SymmetryPage/Symmetry';
import AddSymmetry from '../pages/Home/SymmetryPage/AddSymmetry';
import EditSymmetry from '../pages/Home/SymmetryPage/EditSymmetry';
import PriceMaster from '../pages/Home/PriceMasterPage/PriceMaster';
import UserAskPage from '../pages/Home/UserAskPage/UserAskPage';
import ViewAsking from '../pages/Home/UserAskPage/ViewAsking';
import ContactForm from '../pages/Home/ContactForm/ContactForm';
import ViewContactUs from '../pages/Home/ContactForm/ViewContactUs';
import BuyListPage from '../pages/Home/BuyListPage/BuyListPage';
import OrderList from '../pages/Home/OrderListPage/OrderList';
import HoldListPage from '../pages/Home/HoldListPage/HoldListPage';
import TotalPurchase from '../pages/Home/TotalPurchase/TotalPurchase';
import ChatUserPage from '../pages/Home/ChatUserPage/ChatUserPage';
import ChatPage from '../pages/Home/ChatPage/ChatPage';
import SuperAdmin from '../components/SuperAdmin/SuperAdmin';
import EditSuperAdmin from '../components/SuperAdmin/EditSuperAdmin';
import AddAdmin from '../components/SuperAdmin/AddAdmin';

const routes = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    element: (
      <PrivateRoute>
        <Dashboardpage />
      </PrivateRoute>
    ),
  },
  {
    title: 'Shape',
    path: '/shape',
    element: (
      <PrivateRoute>
        <Shapepage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Shape',
    path: '/edit-shape/:id',
    element: (
      <PrivateRoute>
        <EditShapePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Shape',
    path: '/add-shape',
    element: (
      <PrivateRoute>
        <AddShapePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Color Type',
    path: '/color-type',
    element: (
      <PrivateRoute>
        <ColorType />
      </PrivateRoute>
    ),
  },
  {
    title: 'Add Color Type',
    path: '/add-color-type',
    element: (
      <PrivateRoute>
        <AddColorType />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Shape',
    path: '/edit-color-type/:id',
    element: (
      <PrivateRoute>
        <EditColorType />
      </PrivateRoute>
    ),
  },

  {
    title: 'Intensity',
    path: '/intensity',
    element: (
      <PrivateRoute>
        <IntensityPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Intensity',
    path: '/add-intensity',
    element: (
      <PrivateRoute>
        <AddIntensityPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Intensity',
    path: '/edit-intensity/:id',
    element: (
      <PrivateRoute>
        <EditIntensityPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Over Tone',
    path: '/over-tone',
    element: (
      <PrivateRoute>
        <OverTonePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Over Tone',
    path: '/add-over-tone',
    element: (
      <PrivateRoute>
        <AddOverTonePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Over Tone',
    path: '/edit-over-tone/:id',
    element: (
      <PrivateRoute>
        <EditOvdrTonePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Carat',
    path: '/carat',
    element: (
      <PrivateRoute>
        <CaratPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Carat',
    path: '/add-carat',
    element: (
      <PrivateRoute>
        <AddCaratPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Carat',
    path: '/edit-carat/:id',
    element: (
      <PrivateRoute>
        <EditCaratPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'RoughSource',
    path: '/roughsource',
    element: (
      <PrivateRoute>
        <RoughSourcePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Color',
    path: '/color',
    element: (
      <PrivateRoute>
        <ColorPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Colour',
    path: '/add-colour',
    element: (
      <PrivateRoute>
        <AddColourPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Colour',
    path: '/edit-colour/:id',
    element: (
      <PrivateRoute>
        <EditColourPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Clarity',
    path: '/clarity',
    element: (
      <PrivateRoute>
        <ClarityPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Clarity',
    path: '/add-clarity',
    element: (
      <PrivateRoute>
        <AddClarityPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Clarity',
    path: '/edit-clarity/:id',
    element: (
      <PrivateRoute>
        <EditClarityPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'EyeClean',
    path: '/eyeclean',
    element: (
      <PrivateRoute>
        <EyeCleanPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add EyeClean',
    path: '/add-eyeclean',
    element: (
      <PrivateRoute>
        <AddEyeCleanPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit EyeClean',
    path: '/edit-eyeclean/:id',
    element: (
      <PrivateRoute>
        <EditEyeCleanPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Finish',
    path: '/finish',
    element: (
      <PrivateRoute>
        <FinishPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Finish',
    path: '/add-finish',
    element: (
      <PrivateRoute>
        <AddFinishPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Finish',
    path: '/edit-Finish/:id',
    element: (
      <PrivateRoute>
        <EditFinishPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'FluoreScence',
    path: '/fluorescence',
    element: (
      <PrivateRoute>
        <FluoreScencePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add FluoreScence',
    path: '/add-fluorescence',
    element: (
      <PrivateRoute>
        <AddFluoreScencePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit FluoreScence',
    path: '/edit-fluorescence/:id',
    element: (
      <PrivateRoute>
        <EditFluoreScemcePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Grading',
    path: '/grading',
    element: (
      <PrivateRoute>
        <GradingPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Grading',
    path: '/add-grading',
    element: (
      <PrivateRoute>
        <AddGradingPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Grading',
    path: '/edit-grading/:id',
    element: (
      <PrivateRoute>
        <EditGradingPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'ReportNumber',
    path: '/reportnumber',
    element: (
      <PrivateRoute>
        <ReportNumber />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add ReportNumber',
    path: '/add-reportnumber',
    element: (
      <PrivateRoute>
        <AddReportNumberPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit ReportNumber',
    path: '/edit-reportnumber/:id',
    element: (
      <PrivateRoute>
        <EditReportNumberPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'CertComment',
    path: '/certcomment',
    element: (
      <PrivateRoute>
        <CertCommnetPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add CertComment',
    path: '/add-certcomment',
    element: (
      <PrivateRoute>
        <AddCertCommentPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit CertComment ',
    path: '/edit-certcomment/:id',
    element: (
      <PrivateRoute>
        <EditCertCommentPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Location',
    path: '/location',
    element: (
      <PrivateRoute>
        <LocationPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Location',
    path: '/add-location',
    element: (
      <PrivateRoute>
        <AddLocationPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Location ',
    path: '/edit-location/:id',
    element: (
      <PrivateRoute>
        <EditLocationPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'RoughSource',
    path: '/roughsource',
    element: (
      <PrivateRoute>
        <RoughSourcePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add RoughSource',
    path: '/add-roughsource',
    element: (
      <PrivateRoute>
        <AddRoughSourcePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit RoughSource ',
    path: '/edit-roughsource/:id',
    element: (
      <PrivateRoute>
        <EditRoughSourcePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Monthly',
    path: '/monthly-package',
    element: (
      <PrivateRoute>
        <PackagePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Annually',
    path: '/annually-package',
    element: (
      <PrivateRoute>
        <PackageAnnuallyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Package',
    path: '/add-package',
    element: (
      <PrivateRoute>
        <AddPackage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Core Feature',
    path: '/core-feature',
    element: (
      <PrivateRoute>
        <CoreFeaturesPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Core Feature',
    path: '/edit-core-feature/:id',
    element: (
      <PrivateRoute>
        <EditCoreFeturesPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Package Annually',
    path: '/edit-annually-package/:id',
    element: (
      <PrivateRoute>
        <EditPackageAnnually />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Monthly Package ',
    path: '/edit-monthly-package/:id',
    element: (
      <PrivateRoute>
        <EditMonthlyPackage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Annually Package',
    path: '/add-annually-package',
    element: (
      <PrivateRoute>
        <AddAnnuallyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Core Feature',
    path: '/add-core-feature',
    element: (
      <PrivateRoute>
        <AddCoreFeature />
      </PrivateRoute>
    ),
  },

  {
    title: 'SourceProvider',
    path: '/sourceProvider',
    element: (
      <PrivateRoute>
        <SourceProviderPage />
      </PrivateRoute>
    ),
  },
  {
    title: 'Add SourceProvider',
    path: '/add SourceProvider',
    element: (
      <PrivateRoute>
        <AddSourceProviderPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit SourceProvider',
    path: '/edit SourceProvider/:id',
    element: (
      <PrivateRoute>
        <EditSourceProviderPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Crown',
    path: '/crown',
    element: (
      <PrivateRoute>
        <CrownPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Crown',
    path: '/add crown',
    element: (
      <PrivateRoute>
        <AddCrownPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Crown',
    path: '/edit Crown/:id',
    element: (
      <PrivateRoute>
        <EditCrownPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Pavillion',
    path: '/pavillion',
    element: (
      <PrivateRoute>
        <PavillionPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Pavillion',
    path: '/add pavillion',
    element: (
      <PrivateRoute>
        <AddPavillionPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Pavillion',
    path: '/edit pavillion/:id',
    element: (
      <PrivateRoute>
        <EditPavillionPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Gridle',
    path: '/gridle',
    element: (
      <PrivateRoute>
        <GridlePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Gridle',
    path: '/add-gridle',
    element: (
      <PrivateRoute>
        <AddGridlePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Gridle',
    path: '/edit-gridle/:id',
    element: (
      <PrivateRoute>
        <EditGridlePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Culet',
    path: '/culet',
    element: (
      <PrivateRoute>
        <CuletPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Culet',
    path: '/add-culet',
    element: (
      <PrivateRoute>
        <AddCuletPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Culet',
    path: '/edit-culet/:id',
    element: (
      <PrivateRoute>
        <EditCuletPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Symbol',
    path: '/key-symbol',
    element: (
      <PrivateRoute>
        <KeySymbolPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Key Symbol',
    path: '/add-key-symbol',
    element: (
      <PrivateRoute>
        <AddKeySymbolPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Key Symbol',
    path: '/edit-key-symbol/:id',
    element: (
      <PrivateRoute>
        <EditKeySymbolPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Key Title',
    path: '/key',
    element: (
      <PrivateRoute>
        <KeyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Key',
    path: '/add-key',
    element: (
      <PrivateRoute>
        <AddKeyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Key',
    path: '/edit-key/:id',
    element: (
      <PrivateRoute>
        <EditKeyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Shade',
    path: '/shade',
    element: (
      <PrivateRoute>
        <ShadePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Shade',
    path: '/add-shade',
    element: (
      <PrivateRoute>
        <AddShadePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Shade',
    path: '/edit-shade/:id',
    element: (
      <PrivateRoute>
        <EditShadePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Shade Type',
    path: '/shade-type',
    element: (
      <PrivateRoute>
        <ShadeTypePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Shade Type',
    path: '/add-shade-type',
    element: (
      <PrivateRoute>
        <AddShadeTypePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Shade Type',
    path: '/edit-shade-type/:id',
    element: (
      <PrivateRoute>
        <EditShadeTypePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Brand',
    path: '/brand',
    element: (
      <PrivateRoute>
        <BrandPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Brand',
    path: '/add-brand',
    element: (
      <PrivateRoute>
        <AddBrandPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Brand',
    path: '/edit-brand/:id',
    element: (
      <PrivateRoute>
        <EditBrandPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Transcetra Lot',
    path: '/transcetra-lot',
    element: (
      <PrivateRoute>
        <TranscetraLotPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Transcetra Lot',
    path: '/add-transcetra-lot',
    element: (
      <PrivateRoute>
        <AddTranscetraLotPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Transcetra Lot',
    path: '/edit-transcetra-lot/:id',
    element: (
      <PrivateRoute>
        <EditTranscetraLotPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Measurement',
    path: '/measurement',
    element: (
      <PrivateRoute>
        <MeasurementPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Measurement',
    path: '/add-measurement',
    element: (
      <PrivateRoute>
        <AddMeasurementPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Measurement',
    path: '/edit-measurement/:id',
    element: (
      <PrivateRoute>
        <EditMeasurementPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'CuletCondition',
    path: '/culetcondition',
    element: (
      <PrivateRoute>
        <CuletConditionPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add CuletCondition',
    path: '/add-culetcondition',
    element: (
      <PrivateRoute>
        <AddCuletConditionPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit CuletCondition',
    path: '/edit-culetcondition/:id',
    element: (
      <PrivateRoute>
        <EditCuletConditionPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Get Diamond',
    path: '/get-diamond',
    element: (
      <PrivateRoute>
        <GetDiamond />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Diamond',
    path: '/add-diamond',
    element: (
      <PrivateRoute>
        <AddDiamond />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Diamond',
    path: '/edit-diamond/:id',
    element: (
      <PrivateRoute>
        <EditDiamond />
      </PrivateRoute>
    ),
  },

  {
    title: 'Price',
    path: '/price',
    element: (
      <PrivateRoute>
        <PricePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Price',
    path: '/add-price',
    element: (
      <PrivateRoute>
        <AddPricePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Price',
    path: '/edit-price/:id',
    element: (
      <PrivateRoute>
        <EditPricePage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Treatment',
    path: '/treatment',
    element: (
      <PrivateRoute>
        <TreamentPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Treatment',
    path: '/add-treatment',
    element: (
      <PrivateRoute>
        <AddTreamentPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Treatment',
    path: '/edit-treatment/:id',
    element: (
      <PrivateRoute>
        <EditTreamentPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Treatment Type',
    path: '/treatment-type',
    element: (
      <PrivateRoute>
        <TreatmentType />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Treatment',
    path: '/add-treatment-type',
    element: (
      <PrivateRoute>
        <AddTreatmentType />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Treatment',
    path: '/edit-treatment-type/:id',
    element: (
      <PrivateRoute>
        <EditTreatmentType />
      </PrivateRoute>
    ),
  },

  {
    title: 'Showonly',
    path: '/showonly',
    element: (
      <PrivateRoute>
        <ShowonlyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Showonly',
    path: '/add-showonly',
    element: (
      <PrivateRoute>
        <AddShowonlyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Showonly',
    path: '/edit-showonly/:id',
    element: (
      <PrivateRoute>
        <EditShowonlyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'User Verify',
    path: '/user-verify',
    element: (
      <PrivateRoute>
        <UserVerifyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit User Verify',
    path: '/edit-user-verify/:id',
    element: (
      <PrivateRoute>
        <EditUserVerifyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Sales Executive',
    path: '/sales-executive',
    element: (
      <PrivateRoute>
        <SalesExecutive />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Sales Executive',
    path: '/add-sales-executive',
    element: (
      <PrivateRoute>
        <AddSalesExecutive />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Sales Executive',
    path: '/edit-sales-executive/:id',
    element: (
      <PrivateRoute>
        <EditSalesExecutive />
      </PrivateRoute>
    ),
  },

  {
    title: 'Admin Edit',
    path: '/admin-edit',
    element: (
      <PrivateRoute>
        <EditAdminProfile />
      </PrivateRoute>
    ),
  },

  {
    title: 'Buyer Request',
    path: '/buyer-request',
    element: (
      <PrivateRoute>
        <BuyerRequestPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'View Buyer Request',
    path: '/view-buyer-request/:id',
    element: (
      <PrivateRoute>
        <ViewRequestPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Rapaport Price List',
    path: '/rapaport-price-list',
    element: (
      <PrivateRoute>
        <RapaportPriceList />
      </PrivateRoute>
    ),
  },

  {
    title: 'RapNet Price List',
    path: '/repnet-price-list',
    element: (
      <PrivateRoute>
        <RapNetPriceListPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Upload Diamond',
    path: '/upload-diamond',
    element: (
      <PrivateRoute>
        <UploadDiamond />
      </PrivateRoute>
    ),
  },

  {
    title: 'Regional Offices',
    path: '/regional-offices',
    element: (
      <PrivateRoute>
        <RegionalOffices />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Regional Offices',
    path: '/add-regional-offices',
    element: (
      <PrivateRoute>
        <AddRegionalOffices />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Regional Offices',
    path: '/edit-regional-offices/:id',
    element: (
      <PrivateRoute>
        <EditRegionalOffices />
      </PrivateRoute>
    ),
  },

  {
    title: 'Company Size',
    path: '/company-size',
    element: (
      <PrivateRoute>
        <CompanySize />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Company Size',
    path: '/add-company-size',
    element: (
      <PrivateRoute>
        <AddCompany />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Company Size',
    path: '/edit-company-size/:id',
    element: (
      <PrivateRoute>
        <EditCompany />
      </PrivateRoute>
    ),
  },

  {
    title: 'Company Type',
    path: '/company-type',
    element: (
      <PrivateRoute>
        <CompanyType />
      </PrivateRoute>
    ),
  },
  {
    title: 'Add Company Type',
    path: '/add-company-type',
    element: (
      <PrivateRoute>
        <AddCompanyType />
      </PrivateRoute>
    ),
  },
  {
    title: 'Edit Company Type',
    path: '/edit-company-type/:id',
    element: (
      <PrivateRoute>
        <EditCompanyType />
      </PrivateRoute>
    ),
  },

  {
    title: 'Country Price',
    path: '/country-price',
    element: (
      <PrivateRoute>
        <CountryPrice />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Country Price',
    path: '/add-country-price',
    element: (
      <PrivateRoute>
        <AddCountryPrice />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Country Price',
    path: '/edit-country-price/:id',
    element: (
      <PrivateRoute>
        <EditCountryPrice />
      </PrivateRoute>
    ),
  },

  {
    title: 'Liecence Aggrement',
    path: '/liecence-aggrement',
    element: (
      <PrivateRoute>
        <AggrementPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Code of Conduct',
    path: '/code-of-conduct',
    element: (
      <PrivateRoute>
        <ConductPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
    element: (
      <PrivateRoute>
        <PolicyPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Term and Condition',
    path: '/term-and-condition',
    element: (
      <PrivateRoute>
        <TermPage />
      </PrivateRoute>
    ),
  },
  {
    title: 'Markup Price',
    path: '/markup-price',
    element: (
      <PrivateRoute>
        <MarkupPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Announcement',
    path: '/announcement',
    element: (
      <PrivateRoute>
        <Announcements />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Addannouncement',
    path: '/add-announcement',
    element: (
      <PrivateRoute>
        <AddAnnouncements />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Addannouncement',
    path: '/edit-announcement/:id',
    element: (
      <PrivateRoute>
        <EditAnnouncements />
      </PrivateRoute>
    ),
  },

  {
    title: 'Cutting',
    path: '/cutting',
    element: (
      <PrivateRoute>
        <CutPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add Cutting',
    path: '/add-cutting',
    element: (
      <PrivateRoute>
        <AddCutPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Cutting',
    path: '/edit-cutting/:id',
    element: (
      <PrivateRoute>
        <EditCutPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'Polishing',
    path: '/polishing',
    element: (
      <PrivateRoute>
        <Polishing />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add-Polishing',
    path: '/add-polishing',
    element: (
      <PrivateRoute>
        <AddPolishing />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit-Polishing',
    path: '/edit-polishing/:id',
    element: (
      <PrivateRoute>
        <EditPolishing />
      </PrivateRoute>
    ),
  },

  {
    title: 'Symmetry',
    path: '/symmetry',
    element: (
      <PrivateRoute>
        <Symmetry />
      </PrivateRoute>
    ),
  },

  {
    title: 'Add-Symmetry',
    path: '/add-symmetry',
    element: (
      <PrivateRoute>
        <AddSymmetry />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit-Symmetry',
    path: '/edit-symmetry/:id',
    element: (
      <PrivateRoute>
        <EditSymmetry />
      </PrivateRoute>
    ),
  },

  {
    title: 'Price Master',
    path: '/price-master',
    element: (
      <PrivateRoute>
        <PriceMaster />
      </PrivateRoute>
    ),
  },

  {
    title: 'User Asking',
    path: '/user-asking',
    element: (
      <PrivateRoute>
        <UserAskPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'View Asking',
    path: '/view-diamond/:id',
    element: (
      <PrivateRoute>
        <ViewAsking />
      </PrivateRoute>
    ),
  },

  {
    title: 'Contact From',
    path: '/contact-form',
    element: (
      <PrivateRoute>
        <ContactForm />
      </PrivateRoute>
    ),
  },

  {
    title: 'View ContactUs',
    path: '/view-contactus/:id',
    element: (
      <PrivateRoute>
        <ViewContactUs />
      </PrivateRoute>
    ),
  },

  {
    title: 'orderlist',
    path: '/order-list',
    element: (
      <PrivateRoute>
        <OrderList />
      </PrivateRoute>
    ),
  },

  {
    title: 'buylist',
    path: '/buy-list',
    element: (
      <PrivateRoute>
        <BuyListPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'holdlist',
    path: '/hold-list',
    element: (
      <PrivateRoute>
        <HoldListPage />
      </PrivateRoute>
    ),
  },

  {
    title: 'totalpurchase',
    path: '/total-purchase',
    element: (
      <PrivateRoute>
        <TotalPurchase />
      </PrivateRoute>
    ),
  },
  {
    title: 'super-admin',
    path: '/super-admin',
    element: (
      <PrivateRoute>
        <SuperAdmin />
      </PrivateRoute>
    ),
  },

  {
    title: 'Edit Super Admin',
    path: '/edit-super-admin/:id',
    element: (
      <PrivateRoute>
        <EditSuperAdmin />
      </PrivateRoute>
    ),
  },
  {
    title: 'Add Admin',
    path: '/add-admin',
    element: (
      <PrivateRoute>
        <AddAdmin />
      </PrivateRoute>
    ),
  },
  {
    title: 'Chat-User',
    path: '/chat-user',
    element: (
      <PrivateRoute>
        <ChatUserPage />
      </PrivateRoute>
    ),
  },
  {
    title: 'Chat-Page',
    path: '/user-chat',
    element: (
      <PrivateRoute>
        <ChatPage />
      </PrivateRoute>
    ),
  },
];

export default routes;
