import React, {useContext, useEffect, useState} from 'react';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import Processing from '../../../components/Processing/Processing';
import {DataContext} from '../../../context/dataContext';
import {getUser} from '../../../utils/constants';
import {Link, useNavigate} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import {Pagination} from 'react-bootstrap';

const BuyerRequestPage = () => {
  const navigate = useNavigate();
  const [buyerRequest, setBuyRequest] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);

  console.log('log', deleteId);
  const user = JSON.parse(getUser());

  console.log('user', user);
  const [selectedTab, setSelectedTab] = useState(false);
  const [updatingId, setUpdatingId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getVerify(currentPage);
  }, [selectedTab, currentPage]);

  // this use Effect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getVerify = (page) => {
    console.log('page', page);
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `buyerRequest?page=${page}&status=${selectedTab}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res buyerRequest', res);
        setBuyRequest(res?.data?.results);
        setTotalPages(res?.pages?.totalPages);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `buyerRequest?buyerRequestID=${deleteId}`,
      'delete',
      '',
      user?.authToken,
    )
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getVerify();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  const handleAll = () => {
    setSelectedTab('Pending');
    setCurrentPage(1);
  };

  const handleAccepted = () => {
    setSelectedTab('Completed');
    setCurrentPage(1);
  };

  const updateStatus = (id, status) => {
    console.log('status', status);
    console.log('id', id);
    setUpdatingId(id);

    const body = {
      status: status,
    };

    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `buyerRequest?buyerRequestID=${id}`,
      'put',
      body,
      user?.authToken,
    )
      .then((res) => {
        console.log('res ---->', res);
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getVerify();
        setUpdatingId(null);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Status update error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        setUpdatingId(null);
        dataContext?.setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getVerify(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getVerify(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            className={'custom-pagination'}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
            linkClassName={i === currentPage ? 'link-pagination' : ''}>
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Buyer Requests</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <ul className="nav">
                    <li className="nav-item">
                      <button
                        className={`nav-link-tab ${
                          selectedTab === false ? 'active' : ''
                        }`}
                        onClick={handleAll}
                        data-bs-toggle="tab"
                        data-bs-target="#driver-app">
                        Pending
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link-tab ${
                          selectedTab === true ? 'active' : ''
                        }`}
                        onClick={handleAccepted}
                        data-bs-toggle="tab"
                        data-bs-target="#driver-icabbi">
                        Completed
                      </button>
                    </li>
                  </ul>
                  <hr />

                  <h5 className="card-title">Buyer Request Data</h5>
                  {buyerRequest?.length === 0 ? (
                    <p className="text-center">No data available</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table text-nowrap table-hover table-striped">
                        <thead>
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">Basic Info</th>
                            <th scope="col">Extra Info</th>
                            <th scope="col">Created</th>
                            <th scope="col">Expires</th>
                            <th scope="col">Buy Request #</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buyerRequest?.map((item, index) => (
                            <tr className="align-middle" key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {item?.diamondData?.Shape
                                  ? item?.diamondData?.Shape.join(',')
                                  : ' -'}
                                ,
                                {item?.diamondData?.Carat
                                  ? item?.diamondData?.Carat?.map((item) => {
                                      return (
                                        <>
                                          <span>{item?.from}</span>-
                                          <span>{item?.to}</span>
                                        </>
                                      );
                                    })
                                  : '-'}
                                {'ct'},
                                {item?.diamondData?.brandName
                                  ? item?.diamondData?.brandName
                                  : '-'}
                              </td>
                              <td>
                                {item?.diamondData?.Color
                                  ? item?.diamondData?.Color
                                  : '-'}
                                ,{' '}
                                {item?.diamondData?.Clarity
                                  ? item?.diamondData?.Clarity
                                  : '-'}{' '}
                                , {'Finish'} :{' '}
                                {item?.diamondData?.Finish
                                  ? item?.diamondData?.Finish
                                  : '-'}
                                <div>
                                  {item?.diamondData?.price?.amount}
                                  {'$/ct'}
                                </div>
                              </td>
                              <td>
                                {moment(item?.createdAt).format('MM/DD/YYYY')}
                              </td>
                              <td>
                                {moment(item?.expiredDate).format('MM/DD/YYYY')}
                                {/* {item?.expiredDate ? item?.expiredDate : '-'} */}
                              </td>

                              <td>{item?.buyRequestID}</td>

                              <td>
                                <select
                                  style={{width: 100}}
                                  className="form-select form-select-sm"
                                  value={item?.status}
                                  onChange={(e) =>
                                    updateStatus(item?._id, e.target.value)
                                  }
                                  disabled={updatingId === item?._id}>
                                  <option value="Pending">Pending</option>
                                  <option value="Completed">Completed</option>
                                </select>
                              </td>

                              <td className="">
                                <div className="d-flex">
                                  <div
                                    className="edit-icon btn btn-sm btn-primary me-2"
                                    role="button"
                                    onClick={() => {
                                      navigate(
                                        `/view-buyer-request/${item?._id}`,
                                      );
                                    }}>
                                    <i class="bi bi-eye"></i>
                                  </div>
                                  <div
                                    role="button"
                                    className="delete-icon btn btn-sm btn-danger"
                                    onClick={() => {
                                      setDeleteId(item?._id);
                                    }}>
                                    <i className="bi bi-trash"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {totalPages > 1 && (
            <Pagination className="align-items-center justify-content-center">
              {currentPage !== 1 && ( // Check if it's not the first page
                <p
                  className="text-center mb-sm-0 pe-3 cursor-pointer"
                  onClick={handlePrevious}
                  role="button">
                  Previous
                </p>
              )}
              {getPaginationItems()}
              {currentPage !== totalPages &&
                buyerRequest.length > 0 && ( // Check if it's not the last page and there's data
                  <p
                    className="text-center mb-sm-0 ps-3 cursor-pointer"
                    onClick={handleNext}
                    role="button">
                    Next
                  </p>
                )}
            </Pagination>
          )}
        </section>
        <DeleteModal
          show={dataContext.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Request"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default BuyerRequestPage;
