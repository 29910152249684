import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const EditKeySymbolPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const user = JSON.parse(getUser());
  const state = useParams();

  useEffect(() => {
    getGridle();
  }, []);

  const getGridle = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`key?id=${state?.id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res culet', res?.data);

        formik.setFieldValue('symbol', res?.data?.symbol);

        setData(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      symbol: '',
    },
    validationSchema: Yup.object({
      symbol: Yup.string().required('Please enter symbol'),
    }),
    onSubmit: (values) => {
      const {key, symbol} = values;

      const body = {
        symbol: symbol,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI(`key?id=${state?.id}`, 'put', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/key-symbol');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/key-symbol">Symbol</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Edit Symbol</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="symbol" className="form-label">
                            Symbol
                          </label>
                          <input
                            name="symbol"
                            type="text"
                            className="form-control"
                            id="symbol"
                            value={formik.values.symbol}
                            onChange={(e) => {
                              let value = e.target.value.trimStart();
                              // Ensure first letter is capitalized
                              value =
                                value.charAt(0).toUpperCase() + value.slice(1);
                              if (/^[A-Za-z\s]*$/.test(value)) {
                                formik.setFieldValue('symbol', value);
                              }
                            }}
                          />
                          {formik.touched.symbol && formik.errors.symbol && (
                            <div className="text-danger">
                              {formik.errors.symbol}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Edit Key Symbol"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditKeySymbolPage;
