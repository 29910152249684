import React, {useContext} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';

const AddPricePage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();

  const state = useParams();

  // Custom validation for values ending with '%'
  const percentageValidation = Yup.string().matches(
    /^\d+%$/,
    'Please enter a value in percentage (e.g., 50%)',
  );

  const formik = useFormik({
    initialValues: {
      discountTo: '',
      discountFrom: '',
      priceFrom: '',
      priceTo: '',
      amountTo: '',
      amountFrom: '',
    },
    validationSchema: Yup.object({
      discountFrom: percentageValidation.required('Please enter discount from'),
      discountTo: percentageValidation.required('Please enter discount To'),

      priceTo: Yup.string().required('Please enter price to'),
      priceFrom: Yup.string().required('Please enter price from'),

      amountTo: Yup.string().required('Please enter amount to'),
      amountFrom: Yup.string().required('Please enter amount from'),
    }),
    onSubmit: (values) => {
      const {
        discountTo,
        discountFrom,
        priceFrom,
        priceTo,
        amountTo,
        amountFrom,
      } = values;

      const body = {
        discount: {
          from: discountFrom,
          to: discountTo,
        },
        price_ct: {
          from: priceFrom,
          to: priceTo,
        },
        amount: {
          from: amountFrom,
          to: amountTo,
        },
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI(`price?id=${state?.id}`, 'post', body, '')
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/price');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/price">Price</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Price</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="discountFrom" className="form-label">
                            Discount From
                          </label>
                          <input
                            name="discountFrom"
                            type="text"
                            className="form-control"
                            id="discountFrom"
                            value={formik.values.discountFrom}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'discountFrom',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.discountFrom &&
                            formik.errors.discountFrom && (
                              <div className="text-danger">
                                {formik.errors.discountFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="discountTo" className="form-label">
                            Discount To
                          </label>
                          <input
                            name="discountTo"
                            type="text"
                            className="form-control"
                            id="discountTo"
                            value={formik.values.discountTo}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'discountTo',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.discountTo &&
                            formik.errors.discountTo && (
                              <div className="text-danger">
                                {formik.errors.discountTo}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="amountFrom" className="form-label">
                            Amount From
                          </label>
                          <input
                            name="amountFrom"
                            type="text"
                            className="form-control"
                            id="amountFrom"
                            value={formik.values.amountFrom}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue(
                                'amountFrom',
                                value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.amountFrom &&
                            formik.errors.amountFrom && (
                              <div className="text-danger">
                                {formik.errors.amountFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="amountTo" className="form-label">
                            Amount To
                          </label>
                          <input
                            name="amountTo"
                            type="text"
                            className="form-control"
                            id="amountTo"
                            value={formik.values.amountTo}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue(
                                'amountTo',
                                value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.amountTo &&
                            formik.errors.amountTo && (
                              <div className="text-danger">
                                {formik.errors.amountTo}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="priceFrom" className="form-label">
                            Price From
                          </label>
                          <input
                            name="priceFrom"
                            type="text"
                            className="form-control"
                            id="priceFrom"
                            value={formik.values.priceFrom}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue(
                                'priceFrom',
                                value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.priceFrom &&
                            formik.errors.priceFrom && (
                              <div className="text-danger">
                                {formik.errors.priceFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="priceTo" className="form-label">
                            Price To
                          </label>
                          <input
                            name="priceTo"
                            type="text"
                            className="form-control"
                            id="priceTo"
                            value={formik.values.priceTo}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue(
                                'priceTo',
                                value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.priceTo && formik.errors.priceTo && (
                            <div className="text-danger">
                              {formik.errors.priceTo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Add Price"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddPricePage;
