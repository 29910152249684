import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {fetchDataFromAPI} from '../../../utils/api';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const EditRoughSourcePage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const [data, setData] = useState([]);

  const state = useParams();

  const cleanInput = (value) => {
    // Remove any character that is not a letter, number, or whitespace
    return value.replace(/[^a-zA-Z0-9 ]/g, '');
  };

  useEffect(() => {
    getRoughSource();
  }, []);

  const getRoughSource = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`rough?id=${state?.id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res -->', res?.data);
        let data = res?.data;
        setData(res?.data);
        formik.setFieldValue('source', data?.source);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      source: '',
    },
    validationSchema: Yup.object({
      source: Yup.string().required('Please enter RoughSourcePage'),
    }),
    onSubmit: (values) => {
      const {source} = values;

      const body = {
        source: source,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI(`rough?id=${state?.id}`, 'put', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/roughsource');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/roughsource">Rough Source</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Edit Rough Source </span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="from" className="form-label">
                            Rough Source
                          </label>

                          <input
                            name="RoughSourcePage"
                            type="text"
                            min={0}
                            className="form-control"
                            id="RoughSourcePage"
                            value={formik.values.source}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              ); // Trim leading whitespace and clean input

                              // Capitalize the first letter
                              const capitalizedValue =
                                cleanedValue.charAt(0).toUpperCase() +
                                cleanedValue.slice(1);

                              formik.setFieldValue('source', capitalizedValue); // Set capitalized value to 'source' field
                            }}
                          />
                          {formik.touched.source && formik.errors.source && (
                            <div className="text-danger">
                              {formik.errors.source}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Edit Rough Source"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditRoughSourcePage;
