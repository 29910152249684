import React, {useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {fetchDataFromAPI} from '../../../utils/api';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const AddColorType = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [selectedColorType, setSelectedColorType] = useState('');
  const user = JSON.parse(getUser());

  const formik = useFormik({
    initialValues: {
      name: '',
      overtone: [],
      color: [],
      intensity: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please select type'),
      overtone: Yup.array().required('Please enter overtone'),
      color: Yup.array().required('Please enter color '),
      intensity: Yup.array().required('Please select intensity'),
    }),
    onSubmit: (values) => {
      const {name, overtone, color, intensity} = values;

      const body = {
        name: name,
        values: {
          overtone: overtone,
          color: color,
          intensity: intensity,
        },
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('colortype/addcolor', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/color-type');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/color-type">Color-Type</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Add Color-Type</span>
            </li>
          </ol>
        </nav>
      </div>
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="name" className="form-label">
                          Color Type
                        </label>
                        <div className="input-group">
                          <select
                            name="name"
                            className={`form-select`}
                            id="name"
                            {...formik.getFieldProps('name')}
                            onChange={(e) => {
                              formik.setFieldValue('name', e.target.value);
                              setSelectedColorType(e.target.value);
                            }}
                            aria-label="Select name">
                            <option value="">Select a Colour Type...</option>
                            <option value="Fancy">Fancy</option>
                            <option value="Argle">Argle</option>
                            <option value="White">White</option>
                          </select>
                        </div>
                        {formik.touched.name && formik.errors.name && (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mt-3">
                        <label htmlFor="intensity" className="form-label">
                          Intensity
                        </label>
                        <input
                          name="intensity"
                          className="form-control"
                          id="intensity"
                          value={formik.values.intensity.join(', ')} // Convert array to comma-separated string
                          onChange={(e) => {
                            const value = e.target.value;
                            // Parse comma-separated string to array, capitalize first letter of each word, and trim spaces
                            formik.setFieldValue(
                              'intensity',
                              value.split(',').map((item) => {
                                const trimmedItem = item.trim();
                                return (
                                  trimmedItem.charAt(0).toUpperCase() +
                                  trimmedItem.slice(1)
                                );
                              }),
                            );
                          }}
                        />
                        {formik.touched.intensity &&
                          formik.errors.intensity && (
                            <div className="text-danger">
                              {formik.errors.intensity}
                            </div>
                          )}
                      </div>

                      <div className="col-md-4 mt-3">
                        <label htmlFor="color" className="form-label">
                          Colour
                        </label>
                        <input
                          name="color"
                          type="text"
                          className="form-control"
                          id="color"
                          value={formik.values.color.join(', ')}
                          // value={formik.values.color}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Check if the value contains only letters and spaces
                            if (/^[A-Za-z\s,]*$/.test(value)) {
                              // Parse comma-separated string to array, capitalize first letter of each word, and trim spaces
                              formik.setFieldValue(
                                'color',
                                value.split(',').map((item) => {
                                  const trimmedItem = item.trim();
                                  return (
                                    trimmedItem.charAt(0).toUpperCase() +
                                    trimmedItem.slice(1)
                                  );
                                }),
                              );
                            }
                          }}
                        />
                        {formik.touched.color && formik.errors.color && (
                          <div className="text-danger">
                            {formik.errors.color}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mt-3">
                        <label htmlFor="overtone" className="form-label">
                          Overtone
                        </label>
                        <input
                          name="overtone"
                          type="text"
                          className="form-control"
                          id="overtone"
                          // value={formik.values.overtone}
                          value={formik.values.overtone.join(', ')}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Check if the value contains only letters, spaces, and commas
                            if (/^[A-Za-z\s,]*$/.test(value)) {
                              // Parse comma-separated string to array, capitalize first letter of each word, and trim spaces
                              formik.setFieldValue(
                                'overtone',
                                value.split(',').map((item) => {
                                  const trimmedItem = item.trim();
                                  return (
                                    trimmedItem.charAt(0).toUpperCase() +
                                    trimmedItem.slice(1)
                                  );
                                }),
                              );
                            }
                          }}
                        />
                        {formik.touched.overtone && formik.errors.overtone && (
                          <div className="text-danger">
                            {formik.errors.overtone}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Color Type"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddColorType;
