import React, {useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import constants, {saveUserLocally} from '../../../utils/constants';
import {fetchDataFromAPI} from '../../../utils/api';
import {DataContext} from '../../../context/dataContext';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {toast} from 'react-toastify';

const Registerscreen = () => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const dataContext = useContext(DataContext);
  const {loading, setLoading} = dataContext;
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('Please enter first name'),
      lastname: Yup.string().required('Please enter last name'),
      phone: Yup.string()
        .matches(/^[0-9]+$/, 'Phone number must contain only digits')
        .min(6, 'Phone number must be at least 6 digits')
        .max(10, 'Phone number must be at most 10 digits')
        .required('Please enter phone number'),
      email: Yup.string()
        .email('Invalid email')
        .required('Please enter your email'),
      password: Yup.string()
        .matches(/^\S*$/, 'Space not valid in password.')
        .required('Please enter your password.'),
    }),

    onSubmit: (values) => {
      const {lastname, firstname, phone, email, password} = values;

      dataContext?.setLoading(true);

      const body = {
        first_name: firstname,
        last_name: lastname,
        phone: phone,
        email: email,
        password: password,
      };
      console.log('body', body);

      fetchDataFromAPI('admin/register', 'post', body, '')
        .then((res) => {
          console.log('res', res);
          dataContext?.setLoading(false);

          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          navigate('/login');
        })
        .catch((error) => {
          dataContext?.setLoading(false);
          toast.error(error?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('error', error);
        });
      // functionlogin(values);
    },
  });

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a
                    href="index.html"
                    className="logo d-flex align-items-center w-auto">
                    {/* <img src="assets/img/logo.png" alt="" /> */}
                    <span className="d-none d-lg-block">
                      Transectra - Admin
                    </span>
                  </a>
                </div>
                {/* End Logo */}
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Register to Your Account
                      </h5>
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}>
                      {/* First Name  */}
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          First Name
                        </label>
                        <input
                          placeholder="First Name"
                          type="text"
                          name="firstname"
                          className={`form-control ${
                            formik.touched.firstname && formik.errors.firstname
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="firstname"
                          value={formik.values.firstname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'firstname',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.firstname &&
                          formik.errors.firstname && (
                            <div className="invalid-feedback">
                              {formik.errors.firstname}
                            </div>
                          )}
                      </div>

                      {/* Last Name  */}
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Last Name
                        </label>
                        <input
                          placeholder="Last Name"
                          type="text"
                          name="lastname"
                          className={`form-control ${
                            formik.touched.lastname && formik.errors.lastname
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="lastname"
                          value={formik.values.lastname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'lastname',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.lastname && formik.errors.lastname && (
                          <div className="invalid-feedback">
                            {formik.errors.lastname}
                          </div>
                        )}
                      </div>

                      {/* Phone */}
                      <div className="col-12">
                        <label htmlFor="phone" className="form-label">
                          Phone Number
                        </label>
                        <input
                          placeholder="Phone Number"
                          type="text"
                          name="phone"
                          className={`form-control ${
                            formik.touched.phone && formik.errors.phone
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="phone"
                          value={formik.values.phone}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue('phone', value.trimStart());
                          }}
                          pattern="[0-9]*"
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <div className="invalid-feedback">
                            {formik.errors.phone}
                          </div>
                        )}
                      </div>

                      {/* Email  */}
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Email
                        </label>
                        <input
                          placeholder="Please enter your email"
                          type="text"
                          name="email"
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>

                      {/* Password  */}
                      <div className="col-12 ">
                        <label htmlFor="yourPassword" className="form-label">
                          Password
                        </label>

                        {/* <div className="d-flex"> */}
                        <input
                          placeholder="Password"
                          type={showPass ? 'text' : 'password'}
                          name="password"
                          className={`form-control ${
                            formik.touched.password && formik.errors.password
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="yourPassword"
                          value={formik.values.password}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'password',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          // style={{
                          //   borderTopRightRadius: '0',
                          //   borderBottomRightRadius: '0',
                          // }}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="invalid-feedback">
                            {formik.errors.password}
                          </div>
                        )}
                        {/* </div> */}

                        {/* <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowPass(!showPass)}>
                            {showPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button> */}
                      </div>
                      <div className="text-center mt-3">
                        <CommonButton
                          loading={loading}
                          onClick={formik.handleSubmit}
                          label="Register"
                          loadingLabel="Please wait..."
                        />
                      </div>
                      {/* <div className="col-12">
                      <p className="small mb-0">
                        Don't have account?{" "}
                        <a href="pages-register.html">Create an account</a>
                      </p>
                    </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Registerscreen;
