import React, {useContext, useEffect, useState} from 'react';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const CountryPrice = () => {
  const navigate = useNavigate();
  const [countryPrice, setCountryPrice] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const user = JSON.parse(getUser());

  useEffect(() => {
    getCountryPrice();
  }, []);

  // this use Dffect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  // get Color type  api call
  const getCountryPrice = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('countryNew', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res', res);
        setCountryPrice(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete Color Type
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`countryNew?id=${deleteId}`, 'delete', '', user?.authToken)
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getCountryPrice();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>countryPrice</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>

            <li className="breadcrumb-item active">Country Price</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-lg-between btn-View">
                  <div>
                    {/* <h3 className=" font-weight-bold">Users List</h3> */}
                  </div>

                  <div className=" text-center ">
                    <button
                      type="submit"
                      className="btn-custom"
                      onClick={() => navigate('/add-country-price')}>
                      Add Country Price
                    </button>
                  </div>
                </div>
                <h5 className="card-title">Country Price</h5>
                {/* Default Table */}
                {countryPrice?.length === 0 ? (
                  <p className="text-center">No data available</p>
                ) : (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        {/* <th scope="col">Image</th> */}
                        <th scope="col">Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Code</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Flag</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {countryPrice.map((item, index) => {
                        return (
                          <tr className="align-middle">
                            <th scope="row">{index + 1}</th>

                            <td>{item?.name}</td>
                            <td>{item?.amount}</td>
                            <td>{item?.code}</td>
                            <td>{item?.currency_symbol}</td>
                            <td>
                              <img
                                src={
                                  item?.flagImage
                                    ? item?.flagImage
                                    : require('../../../assets/images/account.png')
                                }
                                alt="Profile"
                                style={{height: 50, width: 50}}
                                // className="rounded-circle"
                              />
                            </td>
                            <td>
                              {moment(item?.updatedAt).format('MMMM Do YYYY')}
                            </td>
                            <td>
                              {moment(item?.createdAt).format('MMMM Do YYYY')}
                            </td>

                            <td className="">
                              <div className="d-flex">
                                <div
                                  className="edit-icon btn btn-sm btn-primary me-2"
                                  role="button"
                                  onClick={(e) => {
                                    navigate(
                                      `/edit-country-price/${item?._id}`,
                                    );
                                  }}>
                                  <i class="bi bi-pencil-square"></i>
                                </div>
                                <div
                                  role="button"
                                  className="delete-icon btn btn-sm btn-danger"
                                  // onClick={() => deleteContent(item?._id)}
                                  onClick={(e) => {
                                    setDeleteId(item?._id);
                                  }}>
                                  <i class="bi bi-trash"></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {/* End Default Table Example */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteModal
        show={dataContext.showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Country Price"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default CountryPrice;
