import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const EyeCleanPage = () => {
  const navigate = useNavigate();
  const [eyeclean, setEyeClean] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const user = JSON.parse(getUser());

  useEffect(() => {
    getEyeClean();
  }, []);

  useEffect(() => {
    if (deleteId) {
      dataContext?.setShowDeleteModal(true);
    } else {
      dataContext?.setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getEyeClean = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('eyeclean', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res eyeclean', res);
        setEyeClean(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`eyeclean?id=${deleteId}`, 'delete', '', user?.authToken)
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getEyeClean();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  return (
    <div>
      <main id="main" className="main">
        {dataContext?.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Eye Clean</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-lg-between btn-View">
                    <div></div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn-custom"
                        onClick={() => navigate('/add-eyeclean')}>
                        Add Eye Clean
                      </button>
                    </div>
                  </div>
                  <h5 className="card-title">Eye Clean Data</h5>
                  {eyeclean?.length === 0 ? (
                    <p className="text-center">No data available</p>
                  ) : (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Eye Clean</th>
                          <th scope="col">Updated At</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eyeclean.map((item, index) => (
                          <tr key={item?._id} className="align-middle">
                            <th scope="row">{index + 1}</th>
                            <td>{item?.name}</td>
                            <td>
                              {moment(item?.updatedAt).format('MMMM Do YYYY')}
                            </td>
                            <td>
                              {moment(item?.createdAt).format('MMMM Do YYYY')}
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="edit-icon btn btn-sm btn-primary me-2"
                                  role="button"
                                  onClick={() =>
                                    navigate(`/edit-eyeclean/${item?._id}`, {
                                      state: {user: item},
                                    })
                                  }>
                                  <i className="bi bi-pencil-square"></i>
                                </div>
                                <div
                                  role="button"
                                  className="delete-icon btn btn-sm btn-danger"
                                  onClick={() => setDeleteId(item?._id)}>
                                  <i className="bi bi-trash"></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <DeleteModal
          show={dataContext?.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete EyeClean"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default EyeCleanPage;
