import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const EditClarityPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());

  const cleanInput = (value) => {
    // Remove any character that is not a letter, number, or whitespace
    return value.replace(/[^a-zA-Z0-9 ]/g, '');
  };

  const [data, setData] = useState([]);

  const state = useParams();
  console.log('state', state?.id);

  useEffect(() => {
    getClarity();
  }, []);

  const getClarity = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `clarity/getClarity?id=${state?.id}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res -->', res?.data);
        let data = res?.data;
        setData(res?.data);

        formik.setFieldValue('Clarity', data?.Clarity);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      Clarity: '',
    },
    validationSchema: Yup.object({
      Clarity: Yup.string().required('Please enter from'),
    }),
    onSubmit: (values) => {
      const {Clarity} = values;

      const body = {
        Clarity: Clarity,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI(
          `clarity/updateClarity?id=${state?.id}`,
          'put',
          body,
          '',
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/clarity');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/clarity">Clarity</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Edit Clarity</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="from" className="form-label">
                            Clarity
                          </label>

                          <input
                            name="Clarity"
                            type="text"
                            min={0}
                            className="form-control"
                            id="Clarity"
                            value={formik.values.Clarity}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              ); // Trim leading whitespace and clean input

                              // Capitalize the first letter
                              const capitalizedValue =
                                cleanedValue.charAt(0).toUpperCase() +
                                cleanedValue.slice(1);

                              formik.setFieldValue('Clarity', capitalizedValue); // Set capitalized value to 'Clarity' field
                            }}
                          />
                          {formik.touched.Clarity && formik.errors.Clarity && (
                            <div className="text-danger">
                              {formik.errors.Clarity}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Edit Clarity"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditClarityPage;
