import React, {useContext} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {fetchDataFromAPI} from '../../../utils/api';
import {getUser} from '../../../utils/constants';

const AddReportNumberPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const formik = useFormik({
    initialValues: {
      number: '',
    },
    validationSchema: Yup.object({
      number: Yup.string().required('Please enter colour'),
    }),
    onSubmit: (values) => {
      const {number} = values;

      const body = {
        number: number,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('reportnumber/add', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/reportnumber');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/reportnumber">Report Number</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add ReportNumber</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="from" className="form-label">
                            Report Number Data
                          </label>

                          <input
                            name="color"
                            type="text"
                            className="form-control"
                            id="color"
                            value={formik.values.number}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue('number', value.trimStart());
                            }}
                          />
                          {formik.touched.number && formik.errors.number && (
                            <div className="text-danger">
                              {formik.errors.number}
                            </div>
                          )}
                        </div>

                        {/* <div className="col-md-6">
                    <label htmlFor="to" className="form-label">
                      To
                    </label>

                    <input
                      name="to"
                      type="number"
                      className="form-control"
                      id="to"
                      value={formik.values.to}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "to",
                          e.target.value?.trimStart()
                        );
                      }}
                    />
                    {formik.touched.to && formik.errors.to && (
                      <div className="text-danger">
                        {formik.errors.to}
                      </div>
                    )}
                  </div> */}
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Add Report Number"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddReportNumberPage;
