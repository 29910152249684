import React, {useContext, useEffect, useState} from 'react';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import Processing from '../../../components/Processing/Processing';

const OverTonePage = () => {
  const navigate = useNavigate();
  const [overTone, setOvertone] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getOverTone();
  }, []);

  // this use Dffect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  // get Color type  api call
  const getOverTone = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('overtone/getovertone', 'get', '', '')
      .then((res) => {
        console.log('res overTone', res);
        setOvertone(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete Color Type
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`overtone/deleteovertone?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getOverTone();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>intensity</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>

            <li className="breadcrumb-item active">Over Tone</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-lg-between btn-View">
                  <div>
                    {/* <h3 className=" font-weight-bold">Users List</h3> */}
                  </div>

                  <div className=" text-center ">
                    <button
                      type="submit"
                      className="btn-custom"
                      onClick={() => navigate('/add-over-tone')}>
                      Add Over Tone
                    </button>
                  </div>
                </div>
                <h5 className="card-title">Over Tone Data</h5>
                {/* Default Table */}
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      {/* <th scope="col">Image</th> */}
                      <th scope="col">Name</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {overTone.map((item, index) => {
                      return (
                        <tr className="align-middle">
                          <th scope="row">{index + 1}</th>
                          {/* <td>
                            <img
                              src={
                                item?.image
                                  ? item?.image
                                  : require('../../../assets/images/account.png')
                              }
                              alt="Profile"
                              style={{height: 50, width: 50}}
                              className="rounded-circle"
                            />
                          </td> */}
                          <td>{item?.name}</td>
                          <td>
                            {moment(item?.updatedAt).format('MMMM Do YYYY')}
                          </td>
                          <td>
                            {moment(item?.createdAt).format('MMMM Do YYYY')}
                          </td>

                          <td className="">
                            <div className="d-flex">
                              <div
                                className="edit-icon btn btn-sm btn-primary me-2"
                                role="button"
                                onClick={(e) => {
                                  navigate(`/edit-over-tone/${item?._id}`, {
                                    state: {user: item},
                                  });
                                }}>
                                <i class="bi bi-pencil-square"></i>
                              </div>
                              <div
                                role="button"
                                className="delete-icon btn btn-sm btn-danger"
                                // onClick={() => deleteContent(item?._id)}
                                onClick={(e) => {
                                  setDeleteId(item?._id);
                                }}>
                                <i class="bi bi-trash"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* End Default Table Example */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteModal
        show={dataContext.showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Over Tone"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default OverTonePage;
