import React, {useContext, useEffect, useState} from 'react';
import Processing from '../../../components/Processing/Processing';
import {DataContext} from '../../../context/dataContext';
import {Link, useParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {getUser} from '../../../utils/constants';

const ViewRequestPage = () => {
  const dataContext = useContext(DataContext);
  const [viewRequest, setViewRequest] = useState([]);
  console.log('viewRequest', viewRequest);
  const user = JSON.parse(getUser());

  const {id} = useParams();

  useEffect(() => {
    getBuyerView();
  }, []);

  const getBuyerView = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`buyerRequest?id=${id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res view', res?.data);
        setViewRequest(res?.data?.results);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  console.log('object', viewRequest[0]?.diamondData);

  return (
    <main id="main" className="main">
      {dataContext?.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/buyer-request">Request</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>View Request</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row justify-content-start">
          <div className="col-xl-12 ">
            <div className="card">
              {/* Show only pending baki */}
              <div className="card-header">
                <div className="divider-container">
                  <span className="divider-text">USER INFO</span>
                  {/* <hr className="divider-line" /> */}
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered  text-center">
                  <tbody>
                    <tr>
                      <td>First Name</td>
                      <td>
                        {viewRequest[0]?.userID?.first_name
                          ? viewRequest[0]?.userID?.first_name
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Name</td>
                      <td>
                        {viewRequest[0]?.userID?.last_name
                          ? viewRequest[0]?.userID?.last_name
                          : '-'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card ">
              <div className="card-header">
                <div className="divider-container">
                  <span className="divider-text">
                    DIAMOND DETAILS : - {viewRequest[0]?.buyRequest}
                  </span>
                  {/* <hr className="divider-line" /> */}
                </div>
              </div>

              <div className="card mt-3 m-3">
                {/* Show only pending baki */}
                <div className="card-header">
                  <div className="divider-container">
                    <span className="divider-text">SHAPE</span>
                    {/* <hr className="divider-line" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered text-center table-striped">
                    <tbody>
                      <tr>
                        <td>Diamond Id</td>
                        <td>{viewRequest[0]?.diamondData?.dId}</td>
                      </tr>
                      <tr>
                        <td>Certificate Number</td>
                        <td>
                          {viewRequest[0]?.diamondData?.cert_num
                            ? viewRequest[0]?.diamondData?.cert_num
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Stock Number</td>
                        <td>
                          {viewRequest[0]?.diamondData?.stock_num
                            ? viewRequest[0]?.diamondData?.stock_num
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card m-3">
                {/* Show only pending baki */}
                <div className="card-header">
                  <div className="divider-container">
                    <span className="divider-text">CROWN</span>
                    {/* <hr className="divider-line" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered text-center table-striped">
                    <tbody>
                      <tr>
                        <td>Angle</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Crown?.angle?.from
                            ? viewRequest[0]?.diamondData?.Crown?.angle?.from
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Height</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Crown?.hight?.to
                            ? viewRequest[0]?.diamondData?.Crown?.hight?.to
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card m-3">
                {/* Show only pending baki */}
                <div className="card-header">
                  <div className="divider-container">
                    <span className="divider-text">PAVILION</span>
                    {/* <hr className="divider-line" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered text-center table-striped">
                    <tbody>
                      <tr>
                        <td>Angle</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Pavilion?.angle.from
                            ? viewRequest[0]?.diamondData?.Pavilion?.angle?.from
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Height</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Pavilion?.hight?.to
                            ? viewRequest[0]?.diamondData?.Pavilion?.hight?.to
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card m-3">
                {/* Show only pending baki */}
                <div className="card-header">
                  <div className="divider-container">
                    <span className="divider-text">COLOR TYPE</span>
                    {/* <hr className="divider-line" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered text-center table-striped">
                    <tbody>
                      <tr>
                        <td>Type</td>
                        <td>
                          {viewRequest[0]?.diamondData?.ColorType?.type
                            ? viewRequest[0]?.diamondData?.ColorType?.type
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td>
                          {viewRequest[0]?.diamondData?.ColorType?.color
                            ? viewRequest[0]?.diamondData?.ColorType?.color
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Intensity</td>
                        <td>
                          {viewRequest[0]?.diamondData?.ColorType?.intensity
                            ? viewRequest[0]?.diamondData?.ColorType?.intensity
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Overtone</td>
                        <td>
                          {viewRequest[0]?.diamondData?.ColorType?.overtone
                            ? viewRequest[0]?.diamondData?.ColorType?.overtone
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card m-3">
                {/* Show only pending baki */}
                <div className="card-header">
                  <div className="divider-container">
                    <span className="divider-text">INCLUSIONS</span>
                    {/* <hr className="divider-line" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered text-center table-striped">
                    <tbody>
                      <tr>
                        <td>Milky</td>
                        <td>
                          {viewRequest[0]?.diamondData?.inclusion?.milky
                            ? viewRequest[0]?.diamondData?.inclusion?.milky
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Black</td>
                        <td>
                          {viewRequest[0]?.diamondData?.inclusion?.black
                            ? viewRequest[0]?.diamondData?.inclusion?.black
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>White</td>
                        <td>
                          {viewRequest[0]?.diamondData?.inclusion?.white
                            ? viewRequest[0]?.diamondData?.inclusion?.white
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Open</td>
                        <td>
                          {viewRequest[0]?.diamondData?.inclusion?.open
                            ? viewRequest[0]?.diamondData?.inclusion?.open
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card m-3">
                {/* Show only pending baki */}
                <div className="card-header">
                  <div className="divider-container">
                    <span className="divider-text">PRICE</span>
                    {/* <hr className="divider-line" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered text-center table-striped">
                    <tbody>
                      <tr>
                        <td>Discount %</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Price?.discount?.from
                            ? viewRequest[0]?.diamondData?.Price?.discount?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Price?.discount?.to
                            ? viewRequest[0]?.diamondData?.Price?.discount?.to
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Price $</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Price?.priceperct?.from
                            ? viewRequest[0]?.diamondData?.Price?.priceperct
                                ?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Price?.priceperct?.to
                            ? viewRequest[0]?.diamondData?.Price?.priceperct?.to
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Amount $</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Price?.amount?.from
                            ? viewRequest[0]?.diamondData?.Price?.amount?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Price?.amount?.to
                            ? viewRequest[0]?.diamondData?.Price?.amount?.to
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card m-3">
                {/* Show only pending baki */}
                <div className="card-header">
                  <div className="divider-container">
                    <span className="divider-text">MEASUREMENT</span>
                    {/* <hr className="divider-line" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered text-center table-striped">
                    <tbody>
                      <tr>
                        <td>Length</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Measurements?.Length
                            ?.from
                            ? viewRequest[0]?.diamondData?.Measurements?.Length
                                ?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Measurements?.Length?.to
                            ? viewRequest[0]?.diamondData?.Measurements?.Length
                                ?.to
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Width</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Measurements?.Width
                            ?.from
                            ? viewRequest[0]?.diamondData?.Measurements?.Width
                                ?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Measurements?.Width?.to
                            ? viewRequest[0]?.diamondData?.Measurements?.Width
                                ?.to
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Depth</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Measurements?.Depth
                            ?.from
                            ? viewRequest[0]?.diamondData?.Measurements?.Depth
                                ?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Measurements?.Depth?.to
                            ? viewRequest[0]?.diamondData?.Measurements?.Depth
                                ?.to
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Depth %</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Measurements?.DepthPer
                            ?.from
                            ? viewRequest[0]?.diamondData?.Measurements
                                ?.DepthPer?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Measurements?.DepthPer
                            ?.to
                            ? viewRequest[0]?.diamondData?.Measurements
                                ?.DepthPer?.to
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Table %</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Measurements?.TablePer
                            ?.from
                            ? viewRequest[0]?.diamondData?.Measurements
                                ?.TablePer?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Measurements?.TablePer
                            ?.to
                            ? viewRequest[0]?.diamondData?.Measurements
                                ?.TablePer?.to
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Girdle %</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Measurements?.GirdlePer
                            ?.from
                            ? viewRequest[0]?.diamondData?.Measurements
                                ?.GirdlePer?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Measurements?.GirdlePer
                            ?.to
                            ? viewRequest[0]?.diamondData?.Measurements
                                ?.GirdlePer?.to
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card m-3">
                {/* Show only pending baki */}
                <div className="card-header">
                  <div className="divider-container">
                    <span className="divider-text">ADVANCE</span>
                    {/* <hr className="divider-line" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered text-center table-striped">
                    <tbody>
                      <tr>
                        <td>Clarity</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Clarity
                            ? viewRequest[0].diamondData.Clarity.join(', ')
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Eye Clean</td>
                        <td>
                          {viewRequest[0]?.diamondData?.EyeClean
                            ? viewRequest[0]?.diamondData?.EyeClean
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>BGM</td>
                        <td>
                          {viewRequest[0]?.diamondData?.bgm === true
                            ? 'True'
                            : 'False'}
                        </td>
                      </tr>
                      <tr>
                        <td>Finish</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Finish
                            ? viewRequest[0]?.diamondData?.Finish.join(', ')
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Cut</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Cut
                            ? viewRequest[0]?.diamondData?.Cut
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Polish</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Polish
                            ? viewRequest[0]?.diamondData?.Polish.join(', ')
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Symmetry</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Symmetry
                            ? viewRequest[0]?.diamondData?.Symmetry.join(',')
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Fluorescence</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Fluorescence
                            ? viewRequest[0]?.diamondData?.Fluorescence
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Grading Report</td>
                        <td>
                          {viewRequest[0]?.diamondData?.gradingReport
                            ? viewRequest[0]?.diamondData?.gradingReport
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Cert Comment</td>
                        <td>
                          {viewRequest[0]?.diamondData?.CertComment
                            ? viewRequest[0]?.diamondData?.CertComment
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Report Number</td>
                        <td>
                          {viewRequest[0]?.diamondData?.ReportNumber
                            ? viewRequest[0]?.diamondData?.ReportNumber
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Location
                            ? viewRequest[0]?.diamondData?.Location
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Ratio</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Ratio?.from
                            ? viewRequest[0]?.diamondData?.Ratio?.from
                            : '-'}{' '}
                          -{' '}
                          {viewRequest[0]?.diamondData?.Ratio?.to
                            ? viewRequest[0]?.diamondData?.Ratio?.to
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Source Provide</td>
                        <td>
                          {viewRequest[0]?.diamondData?.RoughSourceProvider
                            ? viewRequest[0]?.diamondData?.RoughSourceProvider
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Transectra Lot</td>
                        <td>
                          {viewRequest[0]?.diamondData?.TransectraLot
                            ? viewRequest[0]?.diamondData?.TransectraLot
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Showonly</td>
                        <td>
                          {viewRequest[0]?.diamondData?.ShowOnly
                            ? viewRequest[0]?.diamondData?.ShowOnly?.join(',')
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Treatment</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Treatment
                            ? viewRequest[0]?.diamondData?.Treatment
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Treatment Type</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Treatment_type
                            ? viewRequest[0]?.diamondData?.Treatment_type
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Shade</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Shade?.Key
                            ? viewRequest[0]?.diamondData?.Shade?.Key
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Shade Key</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Shade?.Key
                            ? viewRequest[0]?.diamondData?.Shade?.Key
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Brand Name</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Brand
                            ? viewRequest[0]?.diamondData?.Brand.join(', ')
                            : '-'}{' '}
                        </td>
                      </tr>
                      <tr>
                        <td>Symbol</td>
                        <td>
                          {viewRequest[0]?.diamondData?.KeySymbol?.Symbol
                            ? viewRequest[0]?.diamondData?.KeySymbol?.Symbol
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Key</td>
                        <td>
                          {viewRequest[0]?.diamondData?.KeySymbol?.Key
                            ? viewRequest[0]?.diamondData?.KeySymbol?.Key
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Girdle</td>
                        <td>
                          {viewRequest[0]?.diamondData?.Girdle
                            ? viewRequest[0]?.diamondData?.Girdle?.join(', ')
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Culet</td>
                        <td>
                          {viewRequest[0]?.diamondData?.CuletSize
                            ? viewRequest[0]?.diamondData?.CuletSize.join(',')
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>Cutlet Condition</td>
                        <td>
                          {viewRequest[0]?.diamondData?.CuletCondition
                            ? viewRequest[0]?.diamondData?.CuletCondition?.join(
                                ',',
                              )
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="row text-center">
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="btn-custom"
                        onClick={() =>
                          window.open(viewRequest?.diamondData?.image, '_blank')
                        }>
                        Image <i class="bi bi-card-image"></i>
                      </button>
                    </div>
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="btn-custom"
                        onClick={() =>
                          window.open(viewRequest?.diamondData?.video, '_blank')
                        }>
                        Video <i class="bi bi-camera-reels-fill"></i>
                      </button>
                    </div>
                    <div
                      className="col-md-4"
                      onClick={() =>
                        window.open(viewRequest?.diamondData?.pdf, '_blank')
                      }>
                      <button type="button" className="btn-custom">
                        PDF <i class="bi bi-file-earmark-pdf-fill"></i>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ViewRequestPage;
