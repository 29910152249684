import React, {useContext} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const AddSymmetry = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const formik = useFormik({
    initialValues: {
      name: '',
      code: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter name'),
      code: Yup.string().required('Please enter code'),
    }),
    onSubmit: (values) => {
      const {name, code} = values;

      const body = {
        name: name,
        code: code,
      };
      console.log('body', body);
      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('Symmetry', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/symmetry');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/symmetry">Symmetry</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Symmetry</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="culetName" className="form-label">
                              Name
                            </label>
                            <input
                              name="culetName"
                              type="text"
                              className="form-control"
                              id="culetName"
                              value={formik.values.name}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                // Ensure first letter is capitalized
                                // value =
                                //   value.charAt(0).toUpperCase() + value.slice(1);
                                if (/^[A-Za-z\s]*$/.test(value)) {
                                  formik.setFieldValue('name', value);
                                }
                              }}
                            />
                            {formik.touched.name && formik.errors.name && (
                              <div className="text-danger">
                                {formik.errors.name}
                              </div>
                            )}
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="code" className="form-label">
                              Code
                            </label>
                            <input
                              name="code"
                              type="text"
                              className="form-control"
                              id="code"
                              value={formik.values.code}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                // Ensure first letter is capitalized
                                // value =
                                //   value.charAt(0).toUpperCase() + value.slice(1);
                                if (/^[A-Za-z\s]*$/.test(value)) {
                                  formik.setFieldValue('code', value);
                                }
                              }}
                            />
                            {formik.touched.code && formik.errors.code && (
                              <div className="text-danger">
                                {formik.errors.code}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Add Symmetry"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddSymmetry;
