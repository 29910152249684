import React, {useContext, useEffect, useState} from 'react';
import {getUser} from '../../../utils/constants';
import {DataContext} from '../../../context/dataContext';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {fetchDataFromAPI} from '../../../utils/api';
import Processing from '../../../components/Processing/Processing';
import {toast} from 'react-toastify';

const Dashboardpage = () => {
  const user = JSON.parse(getUser());
  const dataContext = useContext(DataContext);

  const syncRepapod = () => {};
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState(null); // Update initial state to null
  const [Data, setDasData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      fetchDataFromAPI(`admin/dash-board`, 'get', '', user?.authToken)
        .then((res) => {
          console.log('res diamond', res?.data);
          setDasData(res.data);
          dataContext?.setLoading(false);
        })
        .catch((error) => {
          console.log('error overtone', error);
          dataContext?.setLoading(false);
        });
    };
    fetchData();
  }, []);

  const capitalizeFirstLetter = (str) => {
    return str
      .replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
      .replace(/^./, (firstChar) => firstChar.toUpperCase()); // Capitalize first letter
  };

  useEffect(() => {
    if (!searchQuery.trim()) {
      // If the search query is empty, clear the data and stop further execution
      setData(null);
      return;
    }
  }, [searchQuery]);

  const handleSearch = (e) => {
    dataContext?.setLoading(true);

    fetchDataFromAPI(
      `search/report?report_id=${searchQuery}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        dataContext?.setLoading(false);
        setData(res?.data?.uId);
      })
      .catch((error) => {
        dataContext?.setLoading(false);
        console.log('error', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
      });
  };

  return (
    <>
      <main id="main" className="main">
        {dataContext?.loading && <Processing />}

        <div className="pagetitle">
          <h1>Dashboard</h1>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Dashboard</h5>

                  <div className="table-responsive">
                    <div className="col-md-6">
                      <div className="input-group  rounded-1 overflow-hidden">
                        <input
                          type="text"
                          placeholder="Report Number"
                          value={searchQuery}
                          onChange={(event) =>
                            setSearchQuery(event.target.value)
                          }
                          style={{
                            flex: 1,
                            padding: '8px',
                            border: '1px solid #ccc',
                          }}
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleSearch();
                          }}
                          style={{
                            backgroundColor: '#BC923F',
                            color: 'white',
                            border: 'none',
                            padding: '8px 15px',
                          }}>
                          <i className="bi bi-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Show the data only if it exists */}
                  {data && (
                    <div
                      className="data-view mt-4 p-4 rounded"
                      style={{
                        backgroundColor: '#f8f9fa', // light background
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                      }}>
                      <p>
                        <strong>First Name:</strong> {data.first_name || '-'}
                      </p>
                      <p>
                        <strong>Last Name:</strong> {data.last_name || '-'}
                      </p>
                      <p>
                        <strong>Phone:</strong> {data.phone || '-'}
                      </p>
                      <p>
                        <strong>Email:</strong> {data.email || '-'}
                      </p>
                      <hr />
                    </div>
                  )}

                  <div className="col-lg-12">
                    <div className="row">
                      {Object.entries(Data).map(([key, value], index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-6 col-sm-12">
                          <div className="card info-card">
                            <div className="card-body">
                              <h5 className="card-title">
                                {capitalizeFirstLetter(key)}
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="ps-3 ps-xl-2">
                                  <h6>{value}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section dashboard">
          <div className="row">
          
          </div>
        </section> */}
      </main>
    </>
  );
};

export default Dashboardpage;
