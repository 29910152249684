import React, {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import {DataContext} from '../../../context/dataContext';
import {getUser, saveUserLocally} from '../../../utils/constants';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {fetchDataFromAPI} from '../../../utils/api';

const EditAdminProfile = () => {
  const user = JSON.parse(getUser());
  console.log('user', user);

  const dataContext = useContext(DataContext);

  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    dataContext?.setLoading(true);
    formik.setFieldValue('firstname', user?.first_name);
    formik.setFieldValue('lastname', user?.last_name);
    formik.setFieldValue('email', user?.email);
    formik.setFieldValue('phone', user?.phone);
    dataContext?.setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      newPass: '',
      conNewPass: '',
    },

    validationSchema: Yup.object({
      firstname: Yup.string().required('First Name is required'),
      lastname: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      role: Yup.string().notRequired(),
      newPass: Yup.string()
        // Scenario 1: No validation restrictions
        // .notRequired()
        // Scenario 2: Relaxed validation criteria
        .min(8, 'Password must be at least 8 characters long.')
        .notRequired(),

      conNewPass: Yup.string()
        .oneOf([Yup.ref('newPass'), null], 'Passwords must match.')
        .notRequired(),
    }),

    onSubmit: (values) => {
      const {firstname, lastname, email, conNewPass, phone, newPass} = values;

      try {
        if (newPass && !conNewPass) {
          formik.setFieldError(
            'conNewPass',
            'Please enter repeat new password',
          );
          return;
        }
        // dataContext?.setLoading(true);
        // const body = {
        //   firstName: firstName,
        //   lastName: lastName,
        //   email: email,
        // };

        const data = {
          first_name: firstname,
          last_name: lastname,
          email: email,
          phone: phone,
          password: conNewPass ? conNewPass : user?.password,
        };

        console.log('data', data);

        fetchDataFromAPI(
          `admin/update?id=${user?._id}`,
          'put',
          data,
          user?.authToken,
        )
          .then((response) => {
            toast.success('Profile updated successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });

            saveUserLocally(JSON.stringify(response?.data));
            dataContext?.setLoading(false);
            navigate('/');
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext?.setLoading(false);

            console.log('catch error:', error);
            console.log('type:', typeof error?.response?.data);
            if (
              error?.response?.data &&
              typeof error?.response?.data === 'string'
            ) {
              dataContext?.setLoading(false);
              alert(error?.response?.data);
              return;
            }

            dataContext?.setLoading(false);
          });
      } catch (error) {
        console.log('catch error: ', error);

        dataContext?.setLoading(false);
      }
    },
  });

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Profile</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form
                    className="needs-validation"
                    onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <label
                        htmlFor="firstName"
                        className="col-md-4 col-lg-3 col-form-label">
                        First Name
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="firstName"
                          type="text"
                          className={`form-control ${
                            formik.touched.firstname && formik.errors.firstname
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="firstName"
                          value={formik.values.firstname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'firstname',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.firstname &&
                          formik.errors.firstname && (
                            <div className="invalid-feedback">
                              {formik.errors.firstname}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="lastName"
                        className="col-md-4 col-lg-3 col-form-label">
                        Last Name
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="lastName"
                          type="text"
                          className={`form-control ${
                            formik.touched.lastname && formik.errors.lastname
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="lastName"
                          value={formik.values.lastname}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'lastname',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.lastname && formik.errors.lastname && (
                          <div className="invalid-feedback">
                            {formik.errors.lastname}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="email"
                        className="col-md-4 col-lg-3 col-form-label">
                        Email
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="email"
                          type="text"
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.email}
                          id="email"
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="phone"
                        className="col-md-4 col-lg-3 col-form-label">
                        Phone
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="phone"
                          type="text"
                          className={`form-control ${
                            formik.touched.phone && formik.errors.phone
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.phone}
                          id="phone"
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue('phone', value.trimStart());
                          }}
                          maxLength={10}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <div className="invalid-feedback">
                            {formik.errors.phone}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          New Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="newPass"
                            type={showNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="newPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'newPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowNewPass(!showNewPass)}>
                            {showNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.newPass && formik.errors.newPass && (
                          <div className="text-danger">
                            {formik.errors.newPass}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Repeat New Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="conNewPass"
                            type={showConNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="conNewPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'conNewPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowConNewPass(!showConNewPass)}>
                            {showConNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.conNewPass &&
                          formik.errors.conNewPass && (
                            <div className="text-danger">
                              {formik.errors.conNewPass}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Edit"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditAdminProfile;
