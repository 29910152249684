import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import moment from 'moment';
import Processing from '../../../components/Processing/Processing';

const MeasurementPage = () => {
  const navigate = useNavigate();
  const [measurement, setMeasurement] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getMeasurement();
  }, []);

  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getMeasurement = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('measurement', 'get', '', '')
      .then((res) => {
        console.log('res measurement', res?.data);
        setMeasurement(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error measurement', error);
        dataContext?.setLoading(false);
      });
  };

  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`measurement?id=${deleteId}`, 'delete', '', '')
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getMeasurement();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Measurement</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-lg-between btn-View">
                    <div></div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn-custom"
                        onClick={() => navigate('/add-measurement')}>
                        Add Measurement
                      </button>
                    </div>
                  </div>
                  <h5 className="card-title">Measurement Data</h5>
                  <div className="table-responsive">
                    <table className="table text-nowrap table-hover">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          {/* <th scope="col">Measurement</th> */}
                          <th scope="col">Depth</th>
                          <th scope="col">Depth %</th>
                          <th scope="col">Girdle %</th>
                          <th scope="col">Length</th>
                          <th scope="col">Ratio</th>
                          <th scope="col">Table %</th>
                          <th scope="col">Width</th>
                          <th scope="col">Updated At</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {measurement.map(
                          (item, index) => (
                            console.log('item', item),
                            (
                              <>
                                <tr className="align-middle" key={item?._id}>
                                  <th scope="row">{index + 1}</th>
                                  {/* <td>{item?.measurement}</td> */}
                                  <td>{`From: ${item?.Measurement?.depth?.from},  To: ${item?.Measurement?.depth?.to}`}</td>
                                  <td>{`From: ${item?.Measurement?.depth_per?.from},  To: ${item?.Measurement?.depth_per?.to}`}</td>
                                  <td>{`From: ${item?.Measurement?.girdle_per?.from}, To: ${item?.Measurement?.girdle_per?.to}`}</td>
                                  <td>{`From: ${item?.Measurement?.length?.from}, To: ${item?.Measurement?.length?.to}`}</td>
                                  <td>{`From: ${item?.Measurement?.ratio?.from}, To: ${item?.Measurement?.ratio?.to}`}</td>
                                  <td>{`From: ${item?.Measurement?.table_per?.from}, To: ${item?.Measurement?.table_per?.to}`}</td>
                                  <td>{`From: ${item?.Measurement?.width?.from}, To: ${item?.Measurement?.width?.to}`}</td>
                                  <td>
                                    {moment(item?.updatedAt).format(
                                      'MMMM Do YYYY',
                                    )}
                                  </td>
                                  <td>
                                    {moment(item?.createdAt).format(
                                      'MMMM Do YYYY',
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <div
                                        className="edit-icon btn btn-sm btn-primary me-2"
                                        role="button"
                                        onClick={() =>
                                          navigate(
                                            `/edit-measurement/${item?._id}`,
                                          )
                                        }>
                                        <i className="bi bi-pencil-square"></i>
                                      </div>
                                      <div
                                        role="button"
                                        className="delete-icon btn btn-sm btn-danger"
                                        onClick={() => setDeleteId(item?._id)}>
                                        <i className="bi bi-trash"></i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )
                          ),
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <DeleteModal
          show={dataContext.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Measurement"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default MeasurementPage;
