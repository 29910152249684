import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { object } from "yup";

const ViewContactUs = () => {
  const { state } = useLocation();
  const items = state?.item;
  console.log("staeeecontactus", state?.item);
  if (!items) {
    return <div>No contact information available.</div>;
  }
  return (
    <Container fluid className="d-flex vh-100">
      <Row className="m-auto align-self-center justify-content-center w-100">
        <Col md={6}>
          <Card className="shadow-lg mb-4">
            <Card.Header
              as="h4"
              className="text-center text-white"
              style={{ backgroundColor: "#ac833e" }}
              aria-label="Contact Information"
            >
              Contact Information
            </Card.Header>
            <Card.Body>
              <Row className="mb-3" role="grid">
                <Col md={6} role="gridcell">
                  <Card.Text className="font-weight-bold">Email:</Card.Text>
                </Col>
                <Col md={6} role="gridcell">
                  <Card.Text className="text-muted">{items.email}</Card.Text>
                </Col>
              </Row>
              <Row className="mb-3" role="grid">
                <Col md={6} role="gridcell">
                  <Card.Text className="font-weight-bold">
                    First Name:
                  </Card.Text>
                </Col>
                <Col md={6} role="gridcell">
                  <Card.Text className="text-muted">
                    {items.first_name}
                  </Card.Text>
                </Col>
              </Row>
              <Row className="mb-3" role="grid">
                <Col md={6} role="gridcell">
                  <Card.Text className="font-weight-bold">Last Name:</Card.Text>
                </Col>
                <Col md={6} role="gridcell">
                  <Card.Text className="text-muted">
                    {items.last_name}
                  </Card.Text>
                </Col>
              </Row>
              <Row className="mb-3" role="grid">
                <Col md={6} role="gridcell">
                  <Card.Text className="font-weight-bold">Message:</Card.Text>
                </Col>
                <Col md={6} role="gridcell">
                  <Card.Text className="text-muted">{items.message}</Card.Text>
                </Col>
              </Row>
              <Row role="grid">
                <Col md={6} role="gridcell">
                  <Card.Text className="font-weight-bold">Phone:</Card.Text>
                </Col>
                <Col md={6} role="gridcell">
                  <Card.Text className="text-muted">{items.phone}</Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewContactUs;
