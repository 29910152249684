import React, {useContext} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';
import {countries} from 'country-flags-svg';
import Select from 'react-select';
import currencyToSymbolMap from 'currency-symbol-map/map';
import getSymbolFromCurrency from 'currency-symbol-map';
import {codes} from '../../../CurrencyCodes/CurrencyCodes';

const AddCountryPrice = () => {
  console.log('getSymbolFromCurrency', getSymbolFromCurrency('INR'));
  console.log('countries', countries);

  console.log('currencyToSymbolMap', currencyToSymbolMap);
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());

  const formik = useFormik({
    initialValues: {
      flagImage: null,
      name: '',
      code: '',
      amount: '',
      currency_symbol: '',
    },
    validationSchema: Yup.object({
      flagImage: Yup.string().required('Please select flag'),
      name: Yup.string().required('Please enter country name'),
      code: Yup.string().required('Please enter country code'),
      amount: Yup.string().required('Please enter country amount'),
      currency_symbol: Yup.string().required('Please enter currency symbol'),
    }),
    onSubmit: (values) => {
      const {flagImage, name, code, amount, currency_symbol} = values;

      const body = {
        flagImage: flagImage,
        name: name,
        code: code,
        amount: amount,
        currency_symbol: currency_symbol,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('countryNew', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/country-price');
            console.log('res ---->', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  const customOptions = countries.map((country) => ({
    value: country.flag,
    label: (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <img
          src={country.flag}
          alt={country.name}
          style={{width: '20px', height: '15px', marginRight: '10px'}}
        />
        <span>{country.name}</span>
      </div>
    ),
  }));

  const handleCountryChange = (selectedOption) => {
    const selectedCountry = countries.find(
      (country) => country.flag === selectedOption.value,
    );

    if (selectedCountry) {
      const currencySymbol = getSymbolFromCurrency(selectedCountry.currency);

      formik.setValues({
        ...formik.values,
        flagImage: selectedOption.value,
        name: selectedCountry.name,
        code: selectedCountry.iso2,
        currency_symbol: currencySymbol,
      });
    }
  };

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/country-price">Country Price</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Country Price</span>
              </li>
            </ol>
          </nav>
        </div>
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="flagImage" className="form-label">
                            Flag
                          </label>
                          <Select
                            options={customOptions}
                            onChange={handleCountryChange}
                            placeholder="Country Flag"
                            formatOptionLabel={({label}) => label}
                          />

                          {formik.touched.flagImage &&
                            formik.errors.flagImage && (
                              <div className="text-danger">
                                {formik.errors.flagImage}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="name" className="form-label">
                            Country Name
                          </label>
                          <input
                            name="name"
                            type="text"
                            className="form-control"
                            id="name"
                            value={formik.values.name}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[A-Za-z0-9\s<>/]*$/.test(value)) {
                                formik.setFieldValue('name', value.trimStart());
                              }
                            }}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="text-danger">
                              {formik.errors.name}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="code" className="form-label">
                            Country Code
                          </label>
                          <input
                            name="code"
                            type="text"
                            className="form-control"
                            id="code"
                            value={formik.values.code}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[A-Za-z0-9\s<>/]*$/.test(value)) {
                                formik.setFieldValue('code', value.trimStart());
                              }
                            }}
                          />
                          {formik.touched.code && formik.errors.code && (
                            <div className="text-danger">
                              {formik.errors.code}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="amount" className="form-label">
                            Country Amount
                          </label>
                          <input
                            name="amount"
                            type="text"
                            className="form-control"
                            id="amount"
                            value={formik.values.amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[0-9.]*$/.test(value)) {
                                formik.setFieldValue(
                                  'amount',
                                  value.trimStart(),
                                );
                              }
                            }}
                          />
                          {formik.touched.amount && formik.errors.amount && (
                            <div className="text-danger">
                              {formik.errors.amount}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label
                            htmlFor="currency_symbol"
                            className="form-label">
                            Currency Symbol
                          </label>
                          <select
                            className={`form-select`}
                            {...formik.getFieldProps('currency_symbol')}>
                            <option value="">Choose Currency Symbol</option>
                            {codes?.map((item) => (
                              <option key={item._id} value={item.symbol_native}>
                                {item.symbol_native}
                              </option>
                            ))}
                          </select>
                          {formik.touched.currency_symbol &&
                            formik.errors.currency_symbol && (
                              <div className="text-danger">
                                {formik.errors.currency_symbol}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Add Country Price"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddCountryPrice;
