import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Pagination } from "react-bootstrap";
import { DataContext } from "../../context/dataContext";
import { getUser } from "../../utils/constants";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import Processing from "../Processing/Processing";
import DeleteModal from "../DeleteModal/DeleteModal";

const SuperAdmin = () => {
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const user = JSON.parse(getUser());
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    getAdminData(currentPage);
  }, [currentPage]);

  // this use Effect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getAdminData = (page) => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `admin/getAllAdmin`,
      //   `admin/getAllAdmin?page=${page}&adminData_id=${searchQuery}`,
      "get",
      "",
      user?.authToken
    )
      .then((res) => {
        console.log("res adminData", res?.data);
        setAdminData(res?.data);
        setTotalPages(res?.pages?.totalPages);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log("error overtone", error);
        dataContext?.setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getAdminData(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getAdminData(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            className={"custom-pagination"}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
            linkClassName={i === currentPage ? "link-pagination" : ""}
          >
            {i}
          </Pagination.Item>
        );
      } else if (paginationItems[paginationItems.length - 1] !== "...") {
        paginationItems.push("...");
      }
    }

    return paginationItems;
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `admin/deleteAdmin?admin_id=${deleteId}`,
      "delete",
      "",
      user?.authToken
    )
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getAdminData();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log("Content error: ", error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        dataContext?.setLoading(false);
      });
  };

  return (
    <div>
      {" "}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Super Admin</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn-custom"
                      onClick={() => navigate("/add-admin")}
                    >
                      Add Diamond
                    </button>
                  </div>
                  <h5 className="card-title">Admin List</h5>
                  {adminData?.length === 0 ? (
                    <p className="text-center">No data available</p>
                  ) : (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">First name</th>
                          <th scope="col">Last name</th>
                          <th scope="col">Role</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminData?.map((item, index) => (
                          <tr className="align-middle" key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.first_name}</td>
                            <td>{item?.last_name}</td>
                            <td>{item?.role}</td>

                            <td className="">
                              <div className="d-flex">
                                <div
                                  className="edit-icon btn btn-sm btn-primary me-2"
                                  role="button"
                                  onClick={() => {
                                    navigate(`/edit-super-admin/${item?._id}`);
                                    // navigate(`edit-super-admin/:id/${item?._id}`);
                                  }}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </div>
                                <div
                                  role="button"
                                  className="delete-icon btn btn-sm btn-danger"
                                  onClick={() => {
                                    setDeleteId(item?._id);
                                  }}
                                >
                                  <i className="bi bi-trash"></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            {totalPages > 1 && (
              <Pagination className="align-items-center justify-content-center">
                {currentPage !== 1 && ( // Check if it's not the first page
                  <p
                    className="text-center mb-sm-0 pe-3 cursor-pointer"
                    onClick={handlePrevious}
                    role="button"
                  >
                    Previous
                  </p>
                )}
                {getPaginationItems()}
                {currentPage !== totalPages &&
                  adminData?.length > 0 && ( // Check if it's not the last page and there's data
                    <p
                      className="text-center mb-sm-0 ps-3 cursor-pointer"
                      onClick={handleNext}
                      role="button"
                    >
                      Next
                    </p>
                  )}
              </Pagination>
            )}
          </div>
        </section>
        <DeleteModal
          show={dataContext.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Admin"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default SuperAdmin;
