import React, {useContext, useEffect, useState} from 'react';
import CommonButton from '../../../components/CommonButton/CommonButton';
import Processing from '../../../components/Processing/Processing';
import {DataContext} from '../../../context/dataContext';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';

import * as Yup from 'yup';
import ReactQuill from 'react-quill';

import {toast} from 'react-toastify';
import {fetchDataFromAPI} from '../../../utils/api';
import {getUser} from '../../../utils/constants';
import {FormGroup} from 'react-bootstrap';

const RapNetPriceListPage = () => {
  const dataContext = useContext(DataContext);
  const user = JSON.parse(getUser());
  const [url, setURL] = useState(null);

  const [data, setData] = useState();

  console.log('data -->', data);

  useEffect(() => {
    getRepNetPriceList();
  }, []);

  const getRepNetPriceList = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('rapnetpricelist', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res -->', res);
        formik.setFieldValue('data', res?.data?.data);
        formik.setFieldValue('link', res?.data?.link);
        setData(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        dataContext?.setLoading(false);

        console.log('error', error);
      });
  };

  const handleFileChangePdf = (e) => {
    formik.setFieldValue('link', e.target.files[0]);
    // const pdfFile = e?.target?.files[0];
    const data = new FormData();
    data.append('image', e?.target?.files[0]);
    // Reset value for the pdfFile field
    // Clear any validation error for the pdfFile field
    dataContext.setLoading(true);
    fetchDataFromAPI('upload', 'post', data, '')
      .then((res) => {
        console.log('res: ', res?.data);

        setURL(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  const handleEditorChange = (html) => {
    formik.setFieldValue('data', html);
  };

  const validationSchema = Yup.object().shape({
    link: Yup.mixed().required('Please enter link'),
    data: Yup.string().required('Please enter description'),
  });

  const formik = useFormik({
    initialValues: {
      link: '',
      data: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      dataContext?.setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        link: url ? url : values?.link,
        data: values?.data,
      };

      console.log('body', body);

      fetchDataFromAPI(
        `rapnetpricelist?id=${data?._id}`,
        'put',
        body,
        user?.authToken,
      )
        .then((res) => {
          dataContext?.setLoading(false);

          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('content res:', res?.data);
          getRepNetPriceList();
          //   formik.resetForm();
          //   navigate('/blog');
        })
        .catch((error) => {
          dataContext?.setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating member: ', error);
        });
    },
  });

  console.log('data', formik.values.link);

  return (
    <main id="main" className="main">
      {dataContext?.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
              <Link to="/driver">Content Edit</Link>
            </li> */}
            <li className="breadcrumb-item active">
              <span>RapNet Price</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-12 mt-3">
                        <label htmlFor="lastName" className="form-label">
                          Description
                        </label>

                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.data}
                            onChange={handleEditorChange}
                            style={{height: 150}}

                            // style={{height: 200}}
                          />
                        </div>
                        {formik.touched.data && formik.errors.data && (
                          <div className="text-danger">
                            {formik.errors.data}
                          </div>
                        )}
                      </div>
                      <div className="row mb-3 mt-3">
                        <div className="col-md-10">
                          <label htmlFor="pdfFile" className="form-label">
                            Choose PDF
                          </label>
                          <FormGroup>
                            <input
                              className="form-control"
                              type="file"
                              name="pdfFile"
                              accept=".pdf"
                              id="pdfFile"
                              onChange={handleFileChangePdf}
                            />
                            {formik.touched.link &&
                              formik.errors.link &&
                              !url && (
                                <div className="text-danger">
                                  {formik.errors.link}
                                </div>
                              )}
                          </FormGroup>
                        </div>
                        <div className="col-md-2 d-flex align-items-end">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => {
                              const urlOpen = url ? url : formik.values.link; // Replace with the URL you want to open
                              window.open(urlOpen, '_blank');
                            }}>
                            <i class="bi bi-eye-fill"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        type="submit"
                        label="Edit Data"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default RapNetPriceListPage;
