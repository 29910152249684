import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api'; // Ensure you have an updateDataToAPI function in your api utils
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const PriceMaster = () => {
  const navigate = useNavigate();
  const [priceList, setPriceList] = useState();
  const [searchData, setSearchData] = useState();
  const [editedPrices, setEditedPrices] = useState({}); // State to hold edited prices with IDs

  const [priceListPear, setPriceListPear] = useState();
  const [searchDataPear, setSearchDataPear] = useState();
  const [editedPricesPear, setEditedPricesPear] = useState({}); // State to hold edited prices for pear shape with IDs
  const [loading, setLoading] = useState(false);
  const dataContext = useContext(DataContext);
  const user = JSON.parse(getUser());

  useEffect(() => {
    PriceList();
    PriceListPear();
  }, []);

  function searchCaratPrice(high_size, color, clarity) {
    return searchData?.find(
      (item) =>
        item.high_size === high_size &&
        item.color === color &&
        item.clarity === clarity,
    )?.caratprice;
  }

  function searchCaratPricePear(high_size, color, clarity) {
    return searchDataPear?.find(
      (item) =>
        item.high_size === high_size &&
        item.color === color &&
        item.clarity === clarity,
    )?.caratprice;
  }

  const handlePriceChange = (shape, id, value) => {
    const newPrices = {...editedPrices};
    newPrices[id] = value;
    setEditedPrices(newPrices);
  };

  const handlePriceChangePear = (shape, id, value) => {
    const newPrices = {...editedPricesPear};
    newPrices[id] = value;
    setEditedPricesPear(newPrices);
  };

  const savePrices = () => {
    dataContext?.setLoading(true);

    // Prepare data for API request
    const updatedPrices = {
      round: Object.keys(editedPrices).map((id) => ({
        id,
        price: editedPrices[id],
      })),
      pear: Object.keys(editedPricesPear).map((id) => ({
        id,
        price: editedPricesPear[id],
      })),
    };

    console.log('updatedPrices', updatedPrices);

    fetchDataFromAPI('priceList', 'put', updatedPrices, user?.authToken)
      .then((res) => {
        PriceList();
        PriceListPear();
        dataContext?.setLoading(false);
        toast.success('Prices updated successfully');
      })
      .catch((error) => {
        console.log('error key', error);
        toast.error('Error updating prices');
        dataContext?.setLoading(false);
      });
  };

  const PriceList = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('priceList?shape=round', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res', res);
        const uniqueProperties = res?.data?.reduce(
          (acc, item) => {
            acc.high_size.add(item.high_size);
            acc.color.add(item.color);
            acc.clarity.add(item.clarity);
            return acc;
          },
          {
            high_size: new Set(),
            color: new Set(),
            clarity: new Set(),
          },
        );

        const high_size = [...uniqueProperties?.high_size];
        const color = [...uniqueProperties?.color];
        const clarity = [...uniqueProperties?.clarity];

        const data = {
          high_size,
          color,
          clarity,
        };

        setPriceList(data);
        setSearchData(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error key', error);
        dataContext?.setLoading(false);
      });
  };

  const PriceListPear = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('priceList?shape=pear', 'get', '', user?.authToken)
      .then((res) => {
        const uniqueProperties = res?.data?.reduce(
          (acc, item) => {
            acc.high_size.add(item.high_size);
            acc.color.add(item.color);
            acc.clarity.add(item.clarity);
            return acc;
          },
          {
            high_size: new Set(),
            color: new Set(),
            clarity: new Set(),
          },
        );

        const high_size = [...uniqueProperties?.high_size];
        const color = [...uniqueProperties?.color];
        const clarity = [...uniqueProperties?.clarity];

        const data = {
          high_size,
          color,
          clarity,
        };

        setPriceListPear(data);
        setSearchDataPear(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error key', error);
        dataContext?.setLoading(false);
      });
  };

  const fetchRap = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('priceList/refresh', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res rap', res);
        // PriceList();
        // PriceListPear();
        window.location.reload();
        dataContext?.setLoading(false);
        toast.success(res?.message);
      })
      .catch((error) => {
        console.log('error key', error);
        dataContext?.setLoading(false);
      });
  };

  const handleMakeSameAsRap = () => {
    const updatedPrices = {};

    searchData.forEach((item) => {
      if (item._id) {
        updatedPrices[item._id] = 0 || 0; // Ensure correct value assignment
      }
    });

    setEditedPrices(updatedPrices);

    const updatedPricesPear = {};

    searchDataPear.forEach((item) => {
      if (item._id) {
        updatedPricesPear[item._id] = 0 || 0; // Ensure correct value assignment
      }
    });

    setEditedPricesPear(updatedPricesPear);
  };

  const syncDiamond = () => {
    // dataContext?.setLoading(true);

    setLoading(true);
    fetchDataFromAPI('sync/trans', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res', res);
        setLoading(false);

        // dataContext?.setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        console.log('error', error);
        // dataContext?.setLoading(false);
      });
  };

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Price List</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <ul className="nav mt-3 align-self-end">
                  <li className="nav-item">
                    <button
                      className={`nav-link-tab ${loading ? 'loading' : ''}`}
                      onClick={syncDiamond}
                      data-bs-toggle="tab"
                      data-bs-target="#driver-app"
                      disabled={loading}>
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                      ) : (
                        'Sync'
                      )}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link-tab`}
                      onClick={fetchRap}
                      data-bs-toggle="tab"
                      data-bs-target="#driver-app">
                      Fetch Rap
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link-tab`}
                      onClick={handleMakeSameAsRap}
                      data-bs-toggle="tab"
                      data-bs-target="#driver-icabbi">
                      Make Same as Rap
                    </button>
                  </li>

                  <li>
                    <button
                      onClick={savePrices}
                      className={`nav-link-tab`}
                      // onClick={handleAccepted}
                      data-bs-toggle="tab"
                      data-bs-target="#driver-icabbi">
                      Save Prices
                    </button>
                  </li>
                </ul>
                <hr />
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Round Table</h5>
                    {priceList?.high_size?.map((price, index) => (
                      <table
                        className="table table-responsive  text-nowrap table-bordered"
                        key={index}>
                        <thead>
                          <tr>
                            <th scope="col">{price}</th>
                            {priceList?.clarity?.map((clarity, index) => (
                              <th scope="col" key={index}>
                                {clarity?.toUpperCase()}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {priceList.color.map((color, colorIndex) => (
                            <tr key={colorIndex} className="text-center">
                              <th scope="row">{color?.toUpperCase()}</th>
                              {priceList?.clarity?.map((item, clarityIndex) => {
                                const matchingItem = searchData?.find(
                                  (data) =>
                                    data.high_size === price &&
                                    data.color === color &&
                                    data.clarity === item,
                                );
                                return (
                                  <td
                                    key={clarityIndex}
                                    className="text-center">
                                    <div className="price-container">
                                      <span className="static-price">
                                        {matchingItem?.caratprice ?? '-'}
                                      </span>
                                      <div className="d-flex">
                                        <input
                                          type="text"
                                          style={{
                                            width: 60,
                                            textAlign: 'center',
                                            fontSize: 14,
                                          }}
                                          value={
                                            editedPrices[
                                              matchingItem?._id ?? ''
                                            ] ??
                                            matchingItem?.Transcaratprice ??
                                            '0'
                                          }
                                          onChange={(e) => {
                                            // Only allow numeric input
                                            const value = e.target.value;
                                            const numericValue = value.replace(
                                              /[^0-9.]/g,
                                              '',
                                            ); // Remove non-numeric characters except for '.'
                                            handlePriceChange(
                                              'round',
                                              matchingItem?._id ?? '',
                                              numericValue,
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ))}
                  </div>
                </div>

                <div className="card-body">
                  <h5 className="card-title">Pear Table</h5>
                  {priceListPear?.high_size?.map((price, index) => (
                    <table className="table table-bordered" key={index}>
                      <thead>
                        <tr>
                          <th scope="col">{price}</th>
                          {priceListPear?.clarity?.map((clarity, index) => (
                            <th scope="col" key={index}>
                              {clarity?.toUpperCase()}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {priceListPear.color.map((color, colorIndex) => (
                          <tr key={colorIndex}>
                            <th scope="row">{color?.toUpperCase()}</th>
                            {priceListPear?.clarity?.map(
                              (item, clarityIndex) => {
                                const matchingItem = searchDataPear?.find(
                                  (data) =>
                                    data.high_size === price &&
                                    data.color === color &&
                                    data.clarity === item,
                                );
                                return (
                                  <td
                                    key={clarityIndex}
                                    className="text-center">
                                    <div className="price-container">
                                      <span className="static-price">
                                        {matchingItem?.caratprice ?? '-'}
                                      </span>
                                      <input
                                        style={{
                                          width: 60,
                                          textAlign: 'center',
                                          fontSize: 14,
                                        }}
                                        type="text"
                                        value={
                                          editedPricesPear[
                                            matchingItem?._id ?? ''
                                          ] ??
                                          matchingItem?.Transcaratprice ??
                                          '0'
                                        }
                                        onChange={(e) => {
                                          // Allow only numeric input and a decimal point
                                          const value = e.target.value;
                                          const numericValue = value.replace(
                                            /[^0-9.]/g,
                                            '',
                                          ); // Remove non-numeric characters except for '.'
                                          handlePriceChangePear(
                                            'pear',
                                            matchingItem?._id ?? '',
                                            numericValue,
                                          );
                                        }}
                                      />
                                    </div>
                                  </td>
                                );
                              },
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PriceMaster;
