import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';

const EditPricePage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();

  const state = useParams();

  useEffect(() => {
    getGridle();
  }, []);

  const getGridle = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`shade?id=${state?.id}`, 'get', '', '')
      .then((res) => {
        console.log('res culet', res?.data);
        formik.setFieldValue('discount', res?.data?.discount);
        formik.setFieldValue('price', res?.data?.price);
        formik.setFieldValue('amount', res?.data?.amount);

        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  // Custom validation for values ending with '%'
  const percentageValidation = Yup.string().matches(
    /^\d+%$/,
    'Please enter a value in percentage (e.g., 50%)',
  );

  const formik = useFormik({
    initialValues: {
      discount: '',
      price: '',
      amount: '',
    },
    validationSchema: Yup.object({
      discount: percentageValidation.required('Please enter discount'),
      price: Yup.string().required('Please enter price'),
      amount: Yup.string().required('Please enter amount'),
    }),
    onSubmit: (values) => {
      const {value, price, amount} = values;

      const body = {
        discount: value,
        price: price,
        amount: amount,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI(`shade?id=${state?.id}`, 'put', body, '')
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/price');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/price">Price</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Edit Price</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="discount" className="form-label">
                            Discount
                          </label>
                          <input
                            name="discount"
                            type="text"
                            className="form-control"
                            id="discount"
                            value={formik.values.discount}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue(
                                'discount',
                                value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.discount &&
                            formik.errors.discount && (
                              <div className="text-danger">
                                {formik.errors.discount}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="price" className="form-label">
                            price
                          </label>
                          <input
                            name="price"
                            type="text"
                            className="form-control"
                            id="price"
                            value={formik.values.price}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue('price', value.trimStart());
                            }}
                          />
                          {formik.touched.price && formik.errors.price && (
                            <div className="text-danger">
                              {formik.errors.price}
                            </div>
                          )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="amount" className="form-label">
                            Amount
                          </label>
                          <input
                            name="amount"
                            type="text"
                            className="form-control"
                            id="amount"
                            value={formik.values.amount}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue('amount', value.trimStart());
                            }}
                          />
                          {formik.touched.amount && formik.errors.amount && (
                            <div className="text-danger">
                              {formik.errors.amount}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Edit Price"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditPricePage;
