import React, {useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const AddColourPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  const formik = useFormik({
    initialValues: {
      color: '',
    },
    validationSchema: Yup.object({
      color: Yup.string().required('Please enter colour'),
    }),
    onSubmit: (values) => {
      const {color} = values;

      const body = {
        color: color,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('color/addcolors', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/color');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/color">Colour</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Colour</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="from" className="form-label">
                            Colour
                          </label>

                          <input
                            name="color"
                            type="text"
                            className="form-control"
                            id="color"
                            value={formik.values.color}
                            // onChange={(e) => {
                            //   formik.setFieldValue(
                            //     "color",
                            //     e.target.value?.trimStart()
                            //   );
                            // }}

                            onChange={(e) => {
                              const value = e.target.value.trimStart(); // Trim leading whitespace

                              if (/^[A-Za-z\s-]*$/.test(value)) {
                                // Check if value contains only letters, spaces, and hyphens
                                const capitalizedValue =
                                  value.charAt(0).toUpperCase() +
                                  value.slice(1); // Capitalize first letter

                                formik.setFieldValue('color', capitalizedValue);
                              }
                            }}
                          />
                          {formik.touched.color && formik.errors.color && (
                            <div className="text-danger">
                              {formik.errors.color}
                            </div>
                          )}
                        </div>

                        {/* <div className="col-md-6">
                          <label htmlFor="to" className="form-label">
                            To
                          </label>

                          <input
                            name="to"
                            type="number"
                            className="form-control"
                            id="to"
                            value={formik.values.to}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "to",
                                e.target.value?.trimStart()
                              );
                            }}
                          />
                          {formik.touched.to && formik.errors.to && (
                            <div className="text-danger">
                              {formik.errors.to}
                            </div>
                          )}
                        </div> */}
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Add Colour"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddColourPage;
