import React, {useContext} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {getUser} from '../../../utils/constants';

const AddCompany = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const user = JSON.parse(getUser());

  const formik = useFormik({
    initialValues: {
      compaySize: '',
    },
    validationSchema: Yup.object({
      compaySize: Yup.string().required('Please enter company size'),
    }),
    onSubmit: (values) => {
      const {value, compaySize} = values;

      const body = {
        size: compaySize,
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('companysize', 'post', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/company-size');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/company-size">Add Company Size</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Company Size</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="compaySize" className="form-label">
                            Company Size
                          </label>
                          <input
                            name="compaySize"
                            type="text"
                            className="form-control"
                            id="compaySize"
                            value={formik.values.compaySize}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[A-Za-z0-9\s<>/]*$/.test(value)) {
                                formik.setFieldValue(
                                  'compaySize',
                                  value.trimStart(),
                                );
                              }
                            }}
                          />
                          {formik.touched.compaySize &&
                            formik.errors.compaySize && (
                              <div className="text-danger">
                                {formik.errors.compaySize}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Add Company Size"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddCompany;
