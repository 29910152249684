import React, {useContext, useEffect, useState} from 'react';
import CommonButton from '../../../components/CommonButton/CommonButton';
import Processing from '../../../components/Processing/Processing';
import {DataContext} from '../../../context/dataContext';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import {toast} from 'react-toastify';
import {fetchDataFromAPI} from '../../../utils/api';
import {getUser} from '../../../utils/constants';
import {FormGroup} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RapaportPriceList = () => {
  const dataContext = useContext(DataContext);
  const user = JSON.parse(getUser());
  const [url, setURL] = useState(null);

  useEffect(() => {
    getRepNetPriceList();
  }, []);

  const getRepNetPriceList = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('rapaportpricelist', 'get', '', user?.authToken)
      .then((res) => {
        console.log('res', res);
        formik.setFieldValue(
          'html_content',
          res?.data?.rapaportdiamond_price?.html_content,
        );
        formik.setFieldValue('pdfs', res?.data?.rapaportdiamond_price?.pdfs);

        formik.setFieldValue(
          'rapaport_price_list',
          res?.data?.rapaport_price_list,
        );
        formik.setFieldValue(
          'format',
          res?.data?.format?.map((feature) => ({
            html_content: feature.html_content,
            title: feature.title,
          })),
        );

        dataContext?.setLoading(false);
      })
      .catch((error) => {
        dataContext?.setLoading(false);
        console.log('error', error);
      });
  };

  const handleFileChangePdfPrice = (e, index) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    dataContext.setLoading(true);
    fetchDataFromAPI('upload', 'post', formData, '')
      .then((res) => {
        console.log('res', res);
        const updatedPdfs = [...formik.values.rapaport_price_list];
        updatedPdfs[index].link = res?.data; // Assuming the URL is in res.data.url
        formik.setFieldValue('rapaport_price_list', updatedPdfs);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  const handlePdfChangePrice = (e, index) => {
    const {name, value} = e.target;
    const updatedPdfs = [...formik.values.rapaport_price_list];
    updatedPdfs[index][name] = value;
    formik.setFieldValue('rapaport_price_list', updatedPdfs);
  };

  const handleDateChangePrice = (date, index) => {
    const updatedPdfs = [...formik.values.rapaport_price_list];
    updatedPdfs[index].time = date ? date.toISOString().split('T')[0] : '';
    formik.setFieldValue('rapaport_price_list', updatedPdfs);
  };

  const handleFileChangePdf = (e, index) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    dataContext.setLoading(true);
    fetchDataFromAPI('upload', 'post', formData, '')
      .then((res) => {
        console.log('res', res);
        const updatedPdfs = [...formik.values.pdfs];
        updatedPdfs[index].link = res?.data; // Assuming the URL is in res.data.url
        formik.setFieldValue('pdfs', updatedPdfs);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  const handlePdfChange = (e, index) => {
    const {name, value} = e.target;
    const updatedPdfs = [...formik.values.pdfs];
    updatedPdfs[index][name] = value;
    formik.setFieldValue('pdfs', updatedPdfs);
  };

  const handleDateChange = (date, index) => {
    const updatedPdfs = [...formik.values.pdfs];
    updatedPdfs[index].time = date ? date.toISOString().split('T')[0] : '';
    formik.setFieldValue('pdfs', updatedPdfs);
  };

  const handleEditorChange = (html) => {
    formik.setFieldValue('html_content', html);
  };

  const validationSchema = Yup.object().shape({
    // html_content: Yup.string().required('Please enter value'),
    // pdfs: Yup.array().of(
    //   Yup.object().shape({
    //     links: Yup.string().required('PDF link is required'),
    //     name: Yup.string().required('PDF name is required'),
    //     time: Yup.string().required('Date is required'),
    //   }),
    // ),
  });

  const formik = useFormik({
    initialValues: {
      html_content: '',
      pdfs: [
        {
          name: '',
          link: '',
          time: '',
        },
      ],
      rapaport_price_list: [
        {
          name: '',
          links: '',
          date: '',
        },
      ],
      format: [{html_content: '', title: ''}],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);
      dataContext?.setLoading(true);
      const body = {
        rapaportdiamond_price: {
          html_content: values.html_content,
          pdfs: values.pdfs,
        },
        rapaport_price_list: values.rapaport_price_list,
        format: values.format,
      };

      console.log('body', body);

      fetchDataFromAPI(`rapaportpricelist`, 'put', body, user?.authToken)
        .then((res) => {
          dataContext?.setLoading(false);
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          getRepNetPriceList();
        })
        .catch((error) => {
          dataContext?.setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
        });
    },
  });

  const addFormatField = () => {
    formik.setFieldValue('format', [
      ...formik.values.format,
      {html_content_format: '', title_format: ''},
    ]);
  };

  const removeFormatField = (index) => {
    const newFormats = [...formik.values.format];
    newFormats.splice(index, 1);
    formik.setFieldValue('format', newFormats);
  };
  return (
    <main id="main" className="main">
      {dataContext?.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Rapaport Price</span>
            </li>
          </ol>
        </nav>
      </div>
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  <form onSubmit={formik.handleSubmit}>
                    {/* <span>Rapaport Diamond Prices - PDF Format</span> */}

                    <div className="card mt-4">
                      <div className="card-header">
                        <div className="divider-container">
                          <span className="divider-text">
                            Rapaport Diamond Prices - PDF Format
                          </span>
                          {/* <hr className="divider-line" /> */}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row mb-3">
                          <div className="col-md-12 mt-3">
                            <label
                              htmlFor="html_content"
                              className="form-label">
                              Description
                            </label>
                            <div className="mb-5">
                              <ReactQuill
                                theme="snow"
                                value={formik.values.html_content}
                                onChange={handleEditorChange}
                                style={{height: 150}}
                              />
                            </div>
                            {formik.touched.html_content &&
                              formik.errors.html_content && (
                                <div className="text-danger">
                                  {formik.errors.html_content}
                                </div>
                              )}
                          </div>
                        </div>

                        {formik.values?.pdfs?.map((pdf, index) => (
                          <div className="row mb-3" key={index}>
                            <div className="row mb-3 mt-3">
                              <div className="col-md-4">
                                <label
                                  htmlFor={`rapaportDiamondlink-${index}`}
                                  className="form-label">
                                  Choose PDF
                                </label>
                                <FormGroup>
                                  <div className="d-flex">
                                    <input
                                      className="form-control"
                                      type="file"
                                      name={`rapaportDiamondlink-${index}`}
                                      accept=".pdf"
                                      id={`rapaportDiamondlink-${index}`}
                                      onChange={(e) =>
                                        handleFileChangePdf(e, index)
                                      }
                                    />
                                    <div className="col-md-2 d-flex align-items-end">
                                      <button
                                        type="button"
                                        className="btn btn-warning ms-2"
                                        onClick={() => {
                                          const urlOpen = pdf.link;
                                          // Replace with the URL you want to open
                                          window.open(urlOpen, '_blank');
                                        }}>
                                        <i class="bi bi-eye-fill"></i>
                                      </button>
                                    </div>
                                  </div>
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor={`rapaportDiamondName-${index}`}
                                  className="form-label">
                                  PDF Name
                                </label>

                                <input
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  id={`rapaportDiamondName-${index}`}
                                  value={formik.values.pdfs[index].name}
                                  onChange={(e) => handlePdfChange(e, index)}
                                />
                              </div>

                              <div className="col-md-4">
                                <label
                                  htmlFor={`rapaportDiamondDate-${index}`}
                                  className="form-label">
                                  Date :
                                </label>
                                <DatePicker
                                  selected={
                                    formik.values.pdfs[index].time
                                      ? new Date(formik.values.pdfs[index].time)
                                      : null
                                  }
                                  onChange={(date) =>
                                    handleDateChange(date, index)
                                  }
                                  dateFormat="yyyy-MM-dd"
                                  className="form-control"
                                  id={`rapaportDiamondDate-${index}`}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="card mt-4">
                      <div className="card-header">
                        <div className="divider-container">
                          <span className="divider-text">
                            Rapaport Price List In Rupees
                          </span>
                          {/* <hr className="divider-line" /> */}
                        </div>
                      </div>

                      <div className="card-body">
                        {formik.values?.rapaport_price_list?.map(
                          (pdf, index) => (
                            <div className="row mb-3" key={index}>
                              <div className="row mb-3 mt-3">
                                <div className="col-md-4">
                                  <label
                                    htmlFor={`rapaport_price_list-${index}`}
                                    className="form-label">
                                    Choose PDF
                                  </label>
                                  <FormGroup>
                                    <div className="d-flex ms-2">
                                      <input
                                        className="form-control"
                                        type="file"
                                        name={`rapaport_price_list-${index}`}
                                        accept=".pdf"
                                        id={`rapaport_price_list-${index}`}
                                        onChange={(e) =>
                                          handleFileChangePdfPrice(e, index)
                                        }
                                      />
                                      <div className="col-md-2 d-flex align-items-end">
                                        <button
                                          type="button"
                                          className="btn btn-warning ms-2"
                                          onClick={() => {
                                            const urlOpen = pdf?.link;
                                            // Replace with the URL you want to open
                                            window.open(urlOpen, '_blank');
                                          }}>
                                          <i class="bi bi-eye-fill"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <label
                                    htmlFor={`rapaport_price_list-${index}`}
                                    className="form-label">
                                    PDF Name
                                  </label>
                                  <input
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    id={`rapaport_price_list-${index}`}
                                    value={
                                      formik.values.rapaport_price_list[index]
                                        .name
                                    }
                                    onChange={(e) =>
                                      handlePdfChangePrice(e, index)
                                    }
                                  />
                                </div>

                                <div className="col-md-4 ">
                                  <label
                                    htmlFor={`rapaport_price_list-${index}`}
                                    className="form-label">
                                    Date :
                                  </label>
                                  <DatePicker
                                    selected={
                                      formik.values.rapaport_price_list[index]
                                        .time
                                        ? new Date(
                                            formik.values.rapaport_price_list[
                                              index
                                            ].time,
                                          )
                                        : null
                                    }
                                    onChange={(date) =>
                                      handleDateChangePrice(date, index)
                                    }
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control "
                                    id={`rapaport_price_list-${index}`}
                                  />
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    </div>

                    <div className="card mt-4">
                      <div className="card-header">
                        <div className="divider-container">
                          <span className="divider-text">
                            Prices In Other Data Formats
                          </span>
                          {/* <hr className="divider-line" /> */}
                        </div>
                      </div>

                      <div className="card-body">
                        {formik.values?.format?.map((format, index) => (
                          <div className="row mb-3" key={index}>
                            {index > 0 && (
                              <div className="text-right mb-3">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => removeFormatField(index)}>
                                  Remove
                                </button>
                              </div>
                            )}
                            <div className="col-md-12 mt-3">
                              <label
                                htmlFor={`html_content_format_${index}`}
                                className="form-label">
                                Description
                              </label>
                              <div className="mb-5">
                                <ReactQuill
                                  theme="snow"
                                  value={format.html_content}
                                  onChange={(html) => {
                                    const newFormats = [
                                      ...formik.values.format,
                                    ];
                                    newFormats[index].html_content = html;
                                    formik.setFieldValue('format', newFormats);
                                  }}
                                  style={{height: 150}}
                                />
                              </div>
                              {formik.touched.html_content &&
                                formik.errors.html_content && (
                                  <div className="text-danger">
                                    {formik.errors.html_content}
                                  </div>
                                )}
                            </div>
                            <div className="row mb-3 mt-3">
                              <div className="col-md-12">
                                <label
                                  htmlFor={`title_format_${index}`}
                                  className="form-label">
                                  Title
                                </label>
                                <input
                                  name={`title_format_${index}`}
                                  type="text"
                                  className="form-control"
                                  id={`title_format_${index}`}
                                  value={format.title}
                                  onChange={(e) => {
                                    const newFormats = [
                                      ...formik.values.format,
                                    ];
                                    newFormats[index].title = e.target.value;
                                    formik.setFieldValue('format', newFormats);
                                  }}
                                />
                                {/* {formik.touched.title &&
                                  formik.errors.title && (
                                    <div className="text-danger">
                                      {formik.errors.title}
                                    </div>
                                  )} */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <button
                        type="button"
                        className="btn-custom w-25 align-self-center mb-3"
                        onClick={addFormatField}>
                        Add Format
                      </button>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        type="submit"
                        label="Edit Data"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default RapaportPriceList;
