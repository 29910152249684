import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';

const AddMeasurementPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const cleanInput = (value) => {
    // Remove any character that is not a letter, number, or whitespace
    return value.replace(/[^a-zA-Z0-9 ]/g, '');
  };

  const mmValidation = Yup.string().matches(
    /^\d+mm$/,
    'Please enter a value in mm (e.g., 10mm)',
  );

  // Custom validation for values ending with '%'
  const percentageValidation = Yup.string().matches(
    /^\d+%$/,
    'Please enter a value in percentage (e.g., 50%)',
  );

  const formik = useFormik({
    initialValues: {
      depthFrom: '',
      depthTo: '',

      depthPerFrom: '',
      depthPerTo: '',

      girdleFrom: '',
      girdleTo: '',

      lengthFrom: '',
      lengthTo: '',

      ratioFrom: '',
      ratioTo: '',

      tableFrom: '',
      tableTo: '',

      widthFrom: '',
      widthTo: '',
    },
    validationSchema: Yup.object({
      depthFrom: mmValidation.required('Please enter depth in mm'),
      depthTo: mmValidation.required('Please enter depth in mm'),

      depthPerFrom: percentageValidation.required(
        'Please enter depth percentage',
      ),
      depthPerTo: percentageValidation.required(
        'Please enter depth percentage',
      ),

      girdleFrom: percentageValidation.required(
        'Please enter girdle percentage',
      ),
      girdleTo: percentageValidation.required('Please enter girdle percentage'),

      lengthFrom: mmValidation.required('Please enter length'),
      lengthTo: mmValidation.required('Please enter length'),

      ratioFrom: Yup.string().required('Please enter ratio'),
      ratioTo: Yup.string().required('Please enter ratio'),

      tableFrom: percentageValidation.required('Please enter table percentage'),
      tableTo: percentageValidation.required('Please enter table percentage'),

      widthFrom: mmValidation.required('Please enter width'),
      widthTo: mmValidation.required('Please enter width'),
    }),
    onSubmit: (values) => {
      const {
        depthFrom,
        depthTo,
        depthPerFrom,
        depthPerTo,
        girdleFrom,
        girdleTo,
        lengthFrom,
        lengthTo,
        ratioFrom,
        ratioTo,
        tableFrom,
        tableTo,
        widthFrom,
        widthTo,
      } = values;

      const body = {
        Measurement: {
          length: {
            from: lengthFrom,
            to: lengthTo,
          },
          depth: {
            from: depthFrom,
            to: depthTo,
          },
          width: {
            from: widthFrom,
            to: widthTo,
          },
          table_per: {
            from: tableFrom,
            to: tableTo,
          },
          depth_per: {
            from: depthPerFrom,
            to: depthPerTo,
          },
          girdle_per: {
            from: girdleFrom,
            to: girdleTo,
          },
          ratio: {
            from: ratioFrom,
            to: ratioTo,
          },
        },
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI('measurement', 'post', body, '')
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/measurement');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/measurement">Measurement</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Measurement</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="depthFrom" className="form-label">
                            Depth From
                          </label>

                          <input
                            name="depthFrom"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.depthFrom}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('depthFrom', cleanedValue);
                            }}
                          />
                          {formik.touched.depthFrom &&
                            formik.errors.depthFrom && (
                              <div className="text-danger">
                                {formik.errors.depthFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="depthTo" className="form-label">
                            Depth To
                          </label>

                          <input
                            name="depthTo"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.depthTo}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('depthTo', cleanedValue);
                            }}
                          />
                          {formik.touched.depthTo && formik.errors.depthTo && (
                            <div className="text-danger">
                              {formik.errors.depthTo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3"></div>
                      <hr />
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="depthPerFrom" className="form-label">
                            Depth Percentage From
                          </label>

                          <input
                            name="depthPerFrom"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.depthPerFrom}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'depthPerFrom',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.depthPerFrom &&
                            formik.errors.depthPerFrom && (
                              <div className="text-danger">
                                {formik.errors.depthPerFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="depthPerTo" className="form-label">
                            Depth Percentage To
                          </label>

                          <input
                            name="depthPerTo"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.depthPerTo}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'depthPerTo',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.depthPerTo &&
                            formik.errors.depthPerTo && (
                              <div className="text-danger">
                                {formik.errors.depthPerTo}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3"></div>
                      <hr />
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="girdleFrom" className="form-label">
                            Girdle From
                          </label>

                          <input
                            name="girdleFrom"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.girdleFrom}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'girdleFrom',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.girdleFrom &&
                            formik.errors.girdleFrom && (
                              <div className="text-danger">
                                {formik.errors.girdleFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="girdleTo" className="form-label">
                            Girdle To
                          </label>

                          <input
                            name="girdleTo"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.girdleTo}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'girdleTo',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.girdleTo &&
                            formik.errors.girdleTo && (
                              <div className="text-danger">
                                {formik.errors.girdleTo}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3"></div>
                      <hr />
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="lengthFrom" className="form-label">
                            Length From
                          </label>

                          <input
                            name="lengthFrom"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.lengthFrom}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('lengthFrom', cleanedValue);
                            }}
                          />
                          {formik.touched.lengthFrom &&
                            formik.errors.lengthFrom && (
                              <div className="text-danger">
                                {formik.errors.lengthFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="lengthTo" className="form-label">
                            Length To
                          </label>

                          <input
                            name="lengthTo"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.lengthTo}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('lengthTo', cleanedValue);
                            }}
                          />
                          {formik.touched.lengthTo &&
                            formik.errors.lengthTo && (
                              <div className="text-danger">
                                {formik.errors.lengthTo}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3"></div>
                      <hr />
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="ratioFrom" className="form-label">
                            Ratio From
                          </label>

                          <input
                            name="ratioFrom"
                            type="text"
                            className="form-control"
                            id="ratioFrom"
                            value={formik.values.ratioFrom}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9.]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue(
                                'ratioFrom',
                                value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.ratioFrom &&
                            formik.errors.ratioFrom && (
                              <div className="text-danger">
                                {formik.errors.ratioFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="ratioTo" className="form-label">
                            Ratio To
                          </label>

                          <input
                            name="ratioTo"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.ratioTo}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9.]/g,
                                '',
                              ); // Remove non-numeric characters
                              formik.setFieldValue(
                                'ratioTo',
                                value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.ratioTo && formik.errors.ratioTo && (
                            <div className="text-danger">
                              {formik.errors.ratioTo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3"></div>
                      <hr />
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="tableFrom" className="form-label">
                            Table Percentage From
                          </label>

                          <input
                            name="tableFrom"
                            type="text"
                            className="form-control"
                            id="tableFrom"
                            value={formik.values.tableFrom}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'tableFrom',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.tableFrom &&
                            formik.errors.tableFrom && (
                              <div className="text-danger">
                                {formik.errors.tableFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="tableTo" className="form-label">
                            Table Percentage To
                          </label>

                          <input
                            name="tableTo"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.tableTo}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'tableTo',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                          {formik.touched.tableTo && formik.errors.tableTo && (
                            <div className="text-danger">
                              {formik.errors.tableTo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3"></div>
                      <hr />
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="widthFrom" className="form-label">
                            Width From
                          </label>

                          <input
                            name="widthFrom"
                            type="text"
                            className="form-control"
                            id="widthFrom"
                            value={formik.values.widthFrom}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('widthFrom', cleanedValue);
                            }}
                          />
                          {formik.touched.widthFrom &&
                            formik.errors.widthFrom && (
                              <div className="text-danger">
                                {formik.errors.widthFrom}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="widthTo" className="form-label">
                            Width To
                          </label>

                          <input
                            name="widthTo"
                            type="text"
                            className="form-control"
                            id="loaction"
                            value={formik.values.widthTo}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('widthTo', cleanedValue);
                            }}
                          />
                          {formik.touched.widthTo && formik.errors.widthTo && (
                            <div className="text-danger">
                              {formik.errors.widthTo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Add Measurement"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddMeasurementPage;
