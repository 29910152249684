import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {DataContext} from '../../context/dataContext';
import {getUser} from '../../utils/constants';
import {fetchDataFromAPI} from '../../utils/api';
import Processing from '../Processing/Processing';
import CommonButton from '../CommonButton/CommonButton';
import Select from 'react-select';

const EditSuperAdmin = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const {id} = useParams();
  const user = JSON.parse(getUser());
  const [adminData, setAdminData] = useState(null);
  const [featuresList, setFeaturesList] = useState([]);

  useEffect(() => {
    dataContext?.setLoading(true);
    fetchDataFromAPI('/admin/getAllFeature', 'get', '', user?.authToken)
      .then((res) => {
        const transformedFeaturesList =
          res?.data[0]?.features.map((feature) => ({
            value: feature.toLowerCase(),
            label: feature.charAt(0).toUpperCase() + feature.slice(1),
          })) || [];

        setFeaturesList(transformedFeaturesList);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('Error fetching features list:', error);
        dataContext.setLoading(false);
      });
  }, []);
  useEffect(() => {
    if (id) {
      getAdminData(id);
    }
  }, [id]);

  const getAdminData = (adminId) => {
    dataContext.setLoading(true);
    fetchDataFromAPI(
      `/admin/getAllAdmin?admin_id=${adminId}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        setAdminData(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        toast.error('Failed to load admin data.', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext.setLoading(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: adminData?.first_name || '',
      last_name: adminData?.last_name || '',
      phone: adminData?.phone || '',
      email: adminData?.email || '',
      password: '',
      role: adminData?.role || 'admin',
      features: adminData?.features || [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      password: Yup.string().min(8, 'Password must be at least 8 characters'),
      role: Yup.string().required('Role is required'),
      features: Yup.array()
        .min(1, 'Please select at least one feature')
        .required('Please select features'),
    }),
    onSubmit: (values) => {
      const {first_name, last_name, phone, email, password, role, features} =
        values;

      console.log('features', features);

      const body = {
        first_name,
        last_name,
        phone,
        email,

        role,
        features,
      };
      // Only include password if it's not empty
      if (password.trim() !== '') {
        body.password = password;
      }

      try {
        dataContext.setLoading(true);
        fetchDataFromAPI(`admin/update?id=${id}`, 'put', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/super-admin');
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
          });
      } catch (error) {
        console.log('Error in form submission:', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/super-admin">Super Admin</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Edit Admin</span>
              </li>
            </ol>
          </nav>
        </div>
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="first_name" className="form-label">
                            First Name
                          </label>
                          <input
                            name="first_name"
                            type="text"
                            className="form-control"
                            id="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.first_name &&
                            formik.errors.first_name && (
                              <div className="text-danger">
                                {formik.errors.first_name}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="last_name" className="form-label">
                            Last Name
                          </label>
                          <input
                            name="last_name"
                            type="text"
                            className="form-control"
                            id="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.last_name &&
                            formik.errors.last_name && (
                              <div className="text-danger">
                                {formik.errors.last_name}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="phone" className="form-label">
                            Phone
                          </label>
                          <input
                            name="phone"
                            type="text"
                            className="form-control"
                            id="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.phone && formik.errors.phone && (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            id="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            name="password"
                            type="password"
                            className="form-control"
                            id="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.password &&
                            formik.errors.password && (
                              <div className="text-danger">
                                {formik.errors.password}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="role" className="form-label">
                            Role
                          </label>
                          <select
                            name="role"
                            id="role"
                            className="form-control"
                            value={formik.values.role}
                            onChange={formik.handleChange}
                            disabled>
                            <option value="admin">Admin</option>
                          </select>
                          {formik.touched.role && formik.errors.role && (
                            <div className="text-danger">
                              {formik.errors.role}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Features */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label htmlFor="features" className="form-label">
                            Features
                          </label>
                          <Select
                            isMulti
                            name="features"
                            options={featuresList}
                            value={formik.values.features.map((feature) => ({
                              value: feature,
                              label:
                                feature.charAt(0).toUpperCase() +
                                feature.slice(1),
                            }))}
                            onChange={(selectedOptions) => {
                              formik.setFieldValue(
                                'features',
                                selectedOptions.map((option) => option.value),
                              );
                            }}
                          />
                          {formik.touched.features &&
                            formik.errors.features && (
                              <div className="text-danger">
                                {formik.errors.features}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Update Admin"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditSuperAdmin;
