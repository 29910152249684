import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';

const EditPavillionPage = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const state = useParams();

  const cleanInput = (value) => {
    // Remove any character that is not a letter, number, or whitespace
    return value.replace(/[^a-zA-Z0-9 ]/g, '');
  };

  useEffect(() => {
    getCrown();
  }, []);

  const getCrown = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`pavillion?id=${state?.id}`, 'get', '', '')
      .then((res) => {
        console.log('res crown', res?.data);
        formik.setFieldValue('angleFrom', res?.data?.pavillion?.pv_angle?.from);
        formik.setFieldValue('angleTo', res?.data?.pavillion?.pv_angle?.to);
        formik.setFieldValue(
          'heightFrom',
          res?.data?.pavillion?.pv_height?.from,
        );
        formik.setFieldValue('heightTo', res?.data?.pavillion?.pv_height?.to);
        setData(res?.data);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      angleFrom: '',
      angleTo: '',
      heightFrom: '',
      heightTo: '',
    },
    validationSchema: Yup.object({
      angleFrom: Yup.string().required('Please enter angle from'),
      angleTo: Yup.string().required('Please enter angle to'),
      heightFrom: Yup.string().required('Please enter height from'),
      heightTo: Yup.string().required('Please enter height to'),
    }),
    onSubmit: (values) => {
      const {angleFrom, angleTo, heightFrom, heightTo} = values;

      const body = {
        pavillion: {
          pv_angle: {
            from: angleFrom,
            to: angleTo,
          },
          pv_height: {
            from: heightFrom,
            to: heightTo,
          },
        },
        // Add other properties if necessary...
      };

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI(`pavillion?id=${state?.id}`, 'put', body, '')
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/pavillion');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });
  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/pavillion">Pavillion</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>Add Pavillion</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="angleFrom" className="form-label">
                            Angle From
                          </label>
                          <input
                            name="angleFrom"
                            type="text"
                            className="form-control"
                            id="angleFrom"
                            value={formik.values.angleFrom}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('angleFrom', cleanedValue);
                            }}
                          />
                          {formik.touched.angleFrom &&
                            formik.errors.angleFrom && (
                              <div className="text-danger">
                                {formik.errors.angleFrom}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="angleTo" className="form-label">
                            Angle To
                          </label>
                          <input
                            name="angleTo"
                            type="text"
                            className="form-control"
                            id="angleTo"
                            value={formik.values.angleTo}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('angleTo', cleanedValue);
                            }}
                          />
                          {formik.touched.angleTo && formik.errors.angleTo && (
                            <div className="text-danger">
                              {formik.errors.angleTo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="heightFrom" className="form-label">
                            Height From
                          </label>
                          <input
                            name="heightFrom"
                            type="text"
                            className="form-control"
                            id="heightFrom"
                            value={formik.values.heightFrom}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('heightFrom', cleanedValue);
                            }}
                          />
                          {formik.touched.heightFrom &&
                            formik.errors.heightFrom && (
                              <div className="text-danger">
                                {formik.errors.heightFrom}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="heightTo" className="form-label">
                            Height To
                          </label>
                          <input
                            name="heightTo"
                            type="text"
                            className="form-control"
                            id="heightTo"
                            value={formik.values.heightTo}
                            onChange={(e) => {
                              const cleanedValue = cleanInput(
                                e.target.value.trimStart(),
                              );
                              formik.setFieldValue('heightTo', cleanedValue);
                            }}
                          />
                          {formik.touched.heightTo &&
                            formik.errors.heightTo && (
                              <div className="text-danger">
                                {formik.errors.heightTo}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          type="submit"
                          label="Edit Pavillion"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditPavillionPage;
