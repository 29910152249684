import React, {useContext, useEffect, useState} from 'react';
import CommonButton from '../../../components/CommonButton/CommonButton';
import Processing from '../../../components/Processing/Processing';
import {DataContext} from '../../../context/dataContext';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';

import * as Yup from 'yup';
import ReactQuill from 'react-quill';

import {toast} from 'react-toastify';
import {fetchDataFromAPI} from '../../../utils/api';
import {getUser} from '../../../utils/constants';
import {FormGroup} from 'react-bootstrap';

const ConductPage = () => {
  const dataContext = useContext(DataContext);
  const user = JSON.parse(getUser());

  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`policies?title=CodeofConduct`, 'get', '', user?.authToken)
      .then((res) => {
        setData(res?.data);
        console.log('res', res?.data);
        formik.setFieldValue('title', res?.data?.title);
        formik.setFieldValue('content', res?.data?.content);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  const handleEditorChange = (html) => {
    formik.setFieldValue('content', html);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.mixed().required('Please enter title'),
    content: Yup.string().required('Please enter content'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('values', values);

      dataContext?.setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        title: values?.title,
        content: values?.content,
      };

      console.log('body', body);

      fetchDataFromAPI(
        `policies?policy_id=${data?._id}`,
        'put',
        body,
        user?.authToken,
      )
        .then((res) => {
          dataContext?.setLoading(false);

          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          console.log('content res:', res?.data);

          //   formik.resetForm();
          //   navigate('/blog');
        })
        .catch((error) => {
          dataContext?.setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('Error while updating member: ', error);
        });
    },
  });

  return (
    <main id="main" className="main">
      {dataContext?.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            {/* <li className="breadcrumb-item">
              <Link to="/driver">Content Edit</Link>
            </li> */}
            <li className="breadcrumb-item active">
              <span>Code of Conduct</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="row mb-3 mt-3">
                        <div className="col-md-12">
                          <label htmlFor="title" className="form-label">
                            Title
                          </label>
                          <input
                            name="title"
                            type="text"
                            disabled
                            placeholder="Title"
                            className="form-control"
                            id="title"
                            value={formik.values.title}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                '',
                              ); // Allow only numbers
                              formik.setFieldValue(
                                'title',
                                e.target.value.trimStart(),
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mt-3">
                        <label htmlFor="lastName" className="form-label">
                          Content
                        </label>

                        <div className="mb-5">
                          <ReactQuill
                            theme="snow"
                            value={formik.values.content}
                            onChange={handleEditorChange}
                            style={{height: 150}}

                            // style={{height: 200}}
                          />
                        </div>
                        {formik.touched.content && formik.errors.content && (
                          <div className="text-danger">
                            {formik.errors.content}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        type="submit"
                        label="Edit Code of Conduct"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ConductPage;
