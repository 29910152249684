import React, {useContext, useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {FormGroup} from 'react-bootstrap';
import * as Yup from 'yup';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';

const EditSalesExecutive = () => {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [url, setURL] = useState(null);
  const user = JSON.parse(getUser());
  const state = useParams();
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const [salseData, setSalseData] = useState();

  const countryOptions = [
    {code: 'AF', phone: '+93'},
    {code: 'AL', phone: '+355'},
    {code: 'DZ', phone: '+213'},
    {code: 'AS', phone: '+1-684'},
    {code: 'AD', phone: '+376'},
    {code: 'AO', phone: '+244'},
    {code: 'AI', phone: '+1-264'},
    {code: 'AG', phone: '+1-268'},
    {code: 'AR', phone: '+54'},
    {code: 'AM', phone: '+374'},
    {code: 'AW', phone: '+297'},
    {code: 'AU', phone: '+61'},
    {code: 'AT', phone: '+43'},
    {code: 'AZ', phone: '+994'},
    {code: 'BS', phone: '+1-242'},
    {code: 'BH', phone: '+973'},
    {code: 'BD', phone: '+880'},
    {code: 'BB', phone: '+1-246'},
    {code: 'BY', phone: '+375'},
    {code: 'BE', phone: '+32'},
    {code: 'BZ', phone: '+501'},
    {code: 'BJ', phone: '+229'},
    {code: 'BM', phone: '+1-441'},
    {code: 'BT', phone: '+975'},
    {code: 'BO', phone: '+591'},
    {code: 'BA', phone: '+387'},
    {code: 'BW', phone: '+267'},
    {code: 'BR', phone: '+55'},
    {code: 'BN', phone: '+673'},
    {code: 'BG', phone: '+359'},
    {code: 'BF', phone: '+226'},
    {code: 'BI', phone: '+257'},
    {code: 'KH', phone: '+855'},
    {code: 'CM', phone: '+237'},
    {code: 'CV', phone: '+238'},
    {code: 'KY', phone: '+1-345'},
    {code: 'CF', phone: '+236'},
    {code: 'TD', phone: '+235'},
    {code: 'CL', phone: '+56'},
    {code: 'CN', phone: '+86'},
    {code: 'CO', phone: '+57'},
    {code: 'KM', phone: '+269'},
    {code: 'CD', phone: '+243'},
    {code: 'CG', phone: '+242'},
    {code: 'CR', phone: '+506'},
    {code: 'HR', phone: '+385'},
    {code: 'CU', phone: '+53'},
    {code: 'CY', phone: '+357'},
    {code: 'CZ', phone: '+420'},
    {code: 'DK', phone: '+45'},
    {code: 'DJ', phone: '+253'},
    {code: 'DM', phone: '+1-767'},
    {code: 'DO', phone: '+1-809'},
    {code: 'EC', phone: '+593'},
    {code: 'EG', phone: '+20'},
    {code: 'SV', phone: '+503'},
    {code: 'GQ', phone: '+240'},
    {code: 'ER', phone: '+291'},
    {code: 'EE', phone: '+372'},
    {code: 'ET', phone: '+251'},
    {code: 'FJ', phone: '+679'},
    {code: 'FI', phone: '+358'},
    {code: 'FR', phone: '+33'},
    {code: 'GA', phone: '+241'},
    {code: 'GM', phone: '+220'},
    {code: 'GE', phone: '+995'},
    {code: 'DE', phone: '+49'},
    {code: 'GH', phone: '+233'},
    {code: 'GR', phone: '+30'},
    {code: 'GD', phone: '+1-473'},
    {code: 'GT', phone: '+502'},
    {code: 'GN', phone: '+224'},
    {code: 'GW', phone: '+245'},
    {code: 'GY', phone: '+592'},
    {code: 'HT', phone: '+509'},
    {code: 'HN', phone: '+504'},
    {code: 'HU', phone: '+36'},
    {code: 'IS', phone: '+354'},
    {code: 'IN', phone: '+91'},
    {code: 'ID', phone: '+62'},
    {code: 'IR', phone: '+98'},
    {code: 'IQ', phone: '+964'},
    {code: 'IE', phone: '+353'},
    {code: 'IL', phone: '+972'},
    {code: 'IT', phone: '+39'},
    {code: 'JM', phone: '+1-876'},
    {code: 'JP', phone: '+81'},
    {code: 'JO', phone: '+962'},
    {code: 'KZ', phone: '+7'},
    {code: 'KE', phone: '+254'},
    {code: 'KI', phone: '+686'},
    {code: 'KR', phone: '+82'},
    {code: 'KW', phone: '+965'},
    {code: 'KG', phone: '+996'},
    {code: 'LA', phone: '+856'},
    {code: 'LV', phone: '+371'},
    {code: 'LB', phone: '+961'},
    {code: 'LS', phone: '+266'},
    {code: 'LR', phone: '+231'},
    {code: 'LY', phone: '+218'},
    {code: 'LI', phone: '+423'},
    {code: 'LT', phone: '+370'},
    {code: 'LU', phone: '+352'},
    {code: 'MG', phone: '+261'},
    {code: 'MW', phone: '+265'},
    {code: 'MY', phone: '+60'},
    {code: 'MV', phone: '+960'},
    {code: 'ML', phone: '+223'},
    {code: 'MT', phone: '+356'},
    {code: 'MH', phone: '+692'},
    {code: 'MR', phone: '+222'},
    {code: 'MU', phone: '+230'},
    {code: 'MX', phone: '+52'},
    {code: 'FM', phone: '+691'},
    {code: 'MD', phone: '+373'},
    {code: 'MC', phone: '+377'},
    {code: 'MN', phone: '+976'},
    {code: 'ME', phone: '+382'},
    {code: 'MA', phone: '+212'},
    {code: 'MZ', phone: '+258'},
    {code: 'MM', phone: '+95'},
    {code: 'NA', phone: '+264'},
    {code: 'NR', phone: '+674'},
    {code: 'NP', phone: '+977'},
    {code: 'NL', phone: '+31'},
    {code: 'NZ', phone: '+64'},
    {code: 'NI', phone: '+505'},
    {code: 'NE', phone: '+227'},
    {code: 'NG', phone: '+234'},
    {code: 'NO', phone: '+47'},
    {code: 'OM', phone: '+968'},
    {code: 'PK', phone: '+92'},
    {code: 'PW', phone: '+680'},
    {code: 'PA', phone: '+507'},
    {code: 'PG', phone: '+675'},
    {code: 'PY', phone: '+595'},
    {code: 'PE', phone: '+51'},
    {code: 'PH', phone: '+63'},
    {code: 'PL', phone: '+48'},
    {code: 'PT', phone: '+351'},
    {code: 'QA', phone: '+974'},
    {code: 'RO', phone: '+40'},
    {code: 'RU', phone: '+7'},
    {code: 'RW', phone: '+250'},
    {code: 'WS', phone: '+685'},
    {code: 'SM', phone: '+378'},
    {code: 'ST', phone: '+239'},
    {code: 'SA', phone: '+966'},
    {code: 'SN', phone: '+221'},
    {code: 'RS', phone: '+381'},
    {code: 'SC', phone: '+248'},
    {code: 'SL', phone: '+232'},
    {code: 'SG', phone: '+65'},
    {code: 'SK', phone: '+421'},
    {code: 'SI', phone: '+386'},
    {code: 'SB', phone: '+677'},
    {code: 'SO', phone: '+252'},
    {code: 'ZA', phone: '+27'},
    {code: 'ES', phone: '+34'},
    {code: 'LK', phone: '+94'},
    {code: 'SD', phone: '+249'},
    {code: 'SR', phone: '+597'},
    {code: 'SZ', phone: '+268'},
    {code: 'SE', phone: '+46'},
    {code: 'CH', phone: '+41'},
    {code: 'SY', phone: '+963'},
    {code: 'TW', phone: '+886'},
    {code: 'TJ', phone: '+992'},
    {code: 'TZ', phone: '+255'},
    {code: 'TH', phone: '+66'},
    {code: 'TG', phone: '+228'},
    {code: 'TO', phone: '+676'},
    {code: 'TT', phone: '+1-868'},
    {code: 'TN', phone: '+216'},
    {code: 'TR', phone: '+90'},
    {code: 'TM', phone: '+993'},
    {code: 'TV', phone: '+688'},
    {code: 'UG', phone: '+256'},
    {code: 'UA', phone: '+380'},
    {code: 'AE', phone: '+971'},
    {code: 'GB', phone: '+44'},
    {code: 'US', phone: '+1'},
    {code: 'UY', phone: '+598'},
    {code: 'UZ', phone: '+998'},
    {code: 'VU', phone: '+678'},
    {code: 'VA', phone: '+39'},
    {code: 'VE', phone: '+58'},
    {code: 'VN', phone: '+84'},
    {code: 'YE', phone: '+967'},
    {code: 'ZM', phone: '+260'},
    {code: 'ZW', phone: '+263'},
  ];

  useEffect(() => {
    getExecutive();
  }, []);

  // get Shape api call
  const getExecutive = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`contact?id=${state?.id}`, 'get', '', user?.authToken)
      .then((res) => {
        console.log('res -->', res?.data);
        setSalseData(res?.data);
        formik.setFieldValue('first_name', res?.data?.first_name);
        formik.setFieldValue('last_name', res?.data?.last_name);
        formik.setFieldValue('email', res?.data?.email);
        formik.setFieldValue('email_link', res?.data?.email_link);
        formik.setFieldValue('phone_no', res?.data?.phone);
        formik.setFieldValue('country_code', res?.data?.country_code);
        formik.setFieldValue('skype', res?.data?.skype_id);
        formik.setFieldValue('whatsapp', res?.data?.whatsapp_id);
        formik.setFieldValue('profile', res?.data?.profile_image);
        formik.setFieldValue('buy_requests', res?.data?.is_buyer_request);
        formik.setFieldValue('hold_requests', res?.data?.is_hold_request);
        formik.setFieldValue('Order', res?.data?.is_order);
        formik.setFieldValue('chat', res?.data?.is_chat);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext?.setLoading(false);
      });
  };

  // const getData = () => {
  //   dataContext.setLoading(true);
  //   formik.setFieldValue('image', data?.image);
  //   formik.setFieldValue('shapeName', data?.name);
  //   dataContext.setLoading(false);
  // };

  const formik = useFormik({
    initialValues: {
      profile_image: '',
      first_name: '',
      last_name: '',
      email: '',
      email_link: '',
      phone_no: '',
      skype: '',
      whatsapp: '',
      country_code: '',
      newPass: '',
      conNewPass: '',
      buy_requests: true,
      hold_requests: true,
      Order: true,
      chat: true,
    },
    validationSchema: Yup.object({
      first_name: Yup.mixed().required('Please enter first name'),
      last_name: Yup.mixed().required('Please enter last name'),
      email: Yup.string()
        .email('Invalid email')
        .required('Please enter your email'),
      email_link: Yup.string().notRequired(),
      phone_no: Yup.string().required('Phone number is required'),
      country_code: Yup.string().required('Country code is required'),
      skype: Yup.string().notRequired(),
      whatsapp: Yup.string().notRequired(),
      newPass: Yup.string()
        // Scenario 1: No validation restrictions
        // .notRequired()

        // Scenario 2: Relaxed validation criteria
        .min(8, 'Password must be at least 8 characters long.')
        .notRequired(),

      conNewPass: Yup.string()
        .oneOf([Yup.ref('newPass'), null], 'Passwords must match.')
        .notRequired(),

      buy_requests: Yup.bool().notRequired(),
      hold_requests: Yup.bool().notRequired(),
      Order: Yup.bool().notRequired(),
      chat: Yup.bool().notRequired(),
    }),
    onSubmit: (values) => {
      const {
        profile_image,
        last_name,
        first_name,
        email,
        email_link,
        phone_no,
        skype,
        whatsapp,
        country_code,
        conNewPass,
        buy_requests,
        hold_requests,
        Order,
        chat,
      } = values;

      let body;

      // Conditionally construct body based on the password field
      if (conNewPass && conNewPass !== '') {
        body = {
          first_name: first_name,
          last_name: last_name,
          profile_image: url ? url : salseData?.profile_image,
          email: email,
          phone: phone_no,
          email_link: email_link,
          skype_id: skype,
          whatsapp_id: whatsapp,
          country_code: country_code,
          password: conNewPass, // Password is included
          is_buyer_request: buy_requests,
          is_hold_request: hold_requests,
          is_order: Order,
          is_chat: chat,
        };
      } else {
        body = {
          first_name: first_name,
          last_name: last_name,
          profile_image: url ? url : salseData?.profile_image,
          email: email,
          phone: phone_no,
          email_link: email_link,
          skype_id: skype,
          whatsapp_id: whatsapp,
          country_code: country_code,
          is_buyer_request: buy_requests,
          is_hold_request: hold_requests,
          is_order: Order,
          is_chat: chat,
        };
      }

      console.log('body', body);

      try {
        dataContext.setLoading(true);

        fetchDataFromAPI(
          `contact?id=${state?.id}`,
          'put',
          body,
          user?.authToken,
        )
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            navigate('/sales-executive');
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  const handleFileChangePdf = (e) => {
    formik.setFieldValue('profile', e.target.files[0]);
    // const pdfFile = e?.target?.files[0];
    const data = new FormData();
    data.append('image', e?.target?.files[0]);
    // Reset value for the pdfFile field
    // Clear any validation error for the pdfFile field
    dataContext.setLoading(true);
    fetchDataFromAPI('upload', 'post', data, '')
      .then((res) => {
        console.log('res: ', res?.data);

        setURL(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/sales-executive">Sales Executive</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Sales Executive</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                          First Name
                        </label>

                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          value={formik.values.first_name}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[A-Za-z\s]*$/.test(value)) {
                              formik.setFieldValue(
                                'first_name',
                                value.trimStart(),
                              );
                            }
                          }}
                        />
                        {formik.touched.first_name &&
                          formik.errors.first_name && (
                            <div className="text-danger">
                              {formik.errors.first_name}
                            </div>
                          )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                          Last Name
                        </label>

                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          value={formik.values.last_name}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[A-Za-z\s]*$/.test(value)) {
                              formik.setFieldValue(
                                'last_name',
                                value.trimStart(),
                              );
                            }
                          }}
                        />
                        {formik.touched.last_name &&
                          formik.errors.last_name && (
                            <div className="text-danger">
                              {formik.errors.last_name}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>

                        <input
                          email="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="phone_no" className="form-label">
                          Phone
                        </label>

                        <input
                          name="phone_no"
                          type="text"
                          className="form-control"
                          id="phone_no"
                          value={formik.values.phone_no}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue('phone_no', value.trimStart());
                          }}
                          maxLength={10}
                        />
                        {formik.touched.phone_no && formik.errors.phone_no && (
                          <div className="text-danger">
                            {formik.errors.phone_no}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="country_code" className="form-label">
                          Country Code
                        </label>

                        <select
                          name="country_code"
                          id="country_code"
                          className="form-control"
                          value={formik.values.country_code}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'country_code',
                              e.target.value,
                            );
                          }}>
                          <option value="">Select Country Code</option>
                          {countryOptions?.map((country) => (
                            <option key={country.code} value={country.phone}>
                              {country.phone} ({country.code})
                            </option>
                          ))}
                        </select>

                        {formik.touched.country_code &&
                          formik.errors.country_code && (
                            <div className="text-danger">
                              {formik.errors.country_code}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label htmlFor="email_link" className="form-label">
                          Email Link
                        </label>

                        <input
                          name="email_link"
                          type="text"
                          className="form-control"
                          id="email_link"
                          value={formik.values.email_link}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email_link',
                              e.target.value.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.email_link &&
                          formik.errors.email_link && (
                            <div className="text-danger">
                              {formik.errors.email_link}
                            </div>
                          )}
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="skype" className="form-label">
                          Skype
                        </label>

                        <input
                          name="skype"
                          type="text"
                          className="form-control"
                          id="skype"
                          value={formik.values.skype}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'skype',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.skype && formik.errors.skype && (
                          <div className="text-danger">
                            {formik.errors.skype}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="whatsapp" className="form-label">
                          Whatsapp
                        </label>

                        <input
                          name="whatsapp"
                          type="text"
                          className="form-control"
                          id="whatsapp"
                          value={formik.values.whatsapp}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue('whatsapp', value.trimStart());
                          }}
                          maxLength={10}
                          //   pattern="[0-9]*"
                        />
                        {formik.touched.whatsapp && formik.errors.whatsapp && (
                          <div className="text-danger">
                            {formik.errors.whatsapp}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="pdfFile" className="form-label">
                          Choose Profile Image
                        </label>
                        <FormGroup>
                          <input
                            className="form-control"
                            type="file"
                            name="pdfFile"
                            accept="image/*"
                            id="pdfFile"
                            onChange={handleFileChangePdf}
                          />
                          {formik.touched.profile &&
                            formik.errors.profile &&
                            !url && (
                              <div className="text-danger">
                                {formik.errors.profile}
                              </div>
                            )}
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          New Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="newPass"
                            type={showNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="newPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'newPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowNewPass(!showNewPass)}>
                            {showNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.newPass && formik.errors.newPass && (
                          <div className="text-danger">
                            {formik.errors.newPass}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Confirm Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="conNewPass"
                            type={showConNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="conNewPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'conNewPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowConNewPass(!showConNewPass)}>
                            {showConNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.conNewPass &&
                          formik.errors.conNewPass && (
                            <div className="text-danger">
                              {formik.errors.conNewPass}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <h5 className="text-center"> Hide Modules </h5>
                        <hr />
                        <div className="row mb-3">
                          <div className="col-md-4 d-flex">
                            <label htmlFor="buy_requests" className="me-2">
                              Buy Requests
                            </label>
                            <input
                              name="buy_requests"
                              type="checkbox"
                              className="form-check-input"
                              id="buy_requests"
                              checked={formik.values.buy_requests}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'buy_requests',
                                  e.target.checked,
                                );
                              }}
                            />
                            {formik.touched.buy_requests &&
                              formik.errors.buy_requests && (
                                <div className="text-danger">
                                  {formik.errors.buy_requests}
                                </div>
                              )}
                          </div>

                          <div className="col-md-4 d-flex">
                            <label htmlFor="hold_requests" className="me-2">
                              Hold Requests
                            </label>
                            <input
                              name="hold_requests"
                              type="checkbox"
                              className="form-check-input"
                              id="hold_requests"
                              checked={formik.values.hold_requests}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'hold_requests',
                                  e.target.checked,
                                );
                              }}
                            />
                            {formik.touched.hold_requests &&
                              formik.errors.hold_requests && (
                                <div className="text-danger">
                                  {formik.errors.hold_requests}
                                </div>
                              )}
                          </div>

                          <div className="col-md-4 d-flex ">
                            <label htmlFor="order" className="me-2">
                              Orders
                            </label>
                            <input
                              name="Order"
                              type="checkbox"
                              className="form-check-input "
                              id="Order"
                              checked={formik.values.Order}
                              onChange={(e) => {
                                formik.setFieldValue('Order', e.target.checked);
                              }}
                            />
                            {formik.touched.Order && formik.errors.Order && (
                              <div className="text-danger">
                                {formik.errors.Order}
                              </div>
                            )}
                          </div>

                          {/* <div className="col-md-3 d-flex ">
                            <label htmlFor="chat" className="me-2">
                              Chat
                            </label>
                            <input
                              name="chat"
                              type="checkbox"
                              className="form-check-input "
                              id="chat"
                              checked={formik.values.chat}
                              onChange={(e) => {
                                formik.setFieldValue('chat', e.target.checked);
                              }}
                            />
                            {formik.touched.chat && formik.errors.chat && (
                              <div className="text-danger">
                                {formik.errors.chat}
                              </div>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Edit Sales Executive"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditSalesExecutive;
