import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import moment from 'moment';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {getUser} from '../../../utils/constants';
import {Pagination} from 'react-bootstrap';

const GetDiamond = () => {
  const navigate = useNavigate();
  const [diamond, setDiamond] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const user = JSON.parse(getUser());
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    getDiamonds(currentPage);
  }, [currentPage]);

  // this use Effect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  const getDiamonds = (page) => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `diamond/getdiamond?page=${page}&diamond_id=${searchQuery}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res diamond', res?.data);
        setDiamond(res?.data);
        setTotalPages(res?.pages?.totalPages);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getDiamonds(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getDiamonds(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            className={'custom-pagination'}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
            linkClassName={i === currentPage ? 'link-pagination' : ''}>
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `diamond/deleteDiamond?id=${deleteId}`,
      'delete',
      '',
      user?.authToken,
    )
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getDiamonds();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  const btnAction = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(`/diamond/exportAllDiamond`, 'post', '', user?.authToken)
      .then((res) => {
        dataContext?.setLoading(false);
        console.log('res ---->', res);
        // fileDownload(res, 'excel.xlsx');
        const downloadUrl = res?.data;
        if (downloadUrl) {
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = 'filename'; // Optionally set the filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        dataContext?.setLoading(false);

        console.log('error', error);
      });
  };

  return (
    <div>
      {' '}
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Diamond</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-lg-between btn-View">
                    <div>
                      <button
                        type="submit"
                        className="btn-custom"
                        onClick={() => navigate('/upload-diamond')}>
                        Upload Diamond
                      </button>
                    </div>

                    <div className="col-md-6">
                      <div className="input-group rounded-1 overflow-hidden">
                        {/* <label htmlFor="reg" className="form-label">
                          Reg:
                        </label> */}
                        <input
                          className=""
                          type="text"
                          name="query"
                          placeholder="Diamond Id"
                          title="Enter search keyword"
                          style={{
                            flex: 1,
                            padding: '8px',
                            border: '1px solid #ccc',
                          }}
                          value={searchQuery}
                          onChange={(event) =>
                            setSearchQuery(event.target.value)
                          }
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            getDiamonds();
                            // setCurrentPage(1);
                          }}
                          type="submit"
                          title="Search"
                          style={{
                            backgroundColor: '#BC923F',
                            color: 'white',
                            border: 'none',
                            padding: '8px 15px',
                          }}>
                          <i className="bi bi-search"></i>
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn-custom"
                        onClick={() => btnAction()}>
                        Export Diamond
                      </button>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn-custom"
                        onClick={() => navigate('/add-diamond')}>
                        Add Diamond
                      </button>
                    </div>
                  </div>
                  <h5 className="card-title">Diamond Data</h5>
                  {diamond?.length === 0 ? (
                    <p className="text-center">No data available</p>
                  ) : (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Certificate Id</th>
                          <th scope="col">Shape</th>
                          <th scope="col">Carat</th>
                          <th scope="col">Price</th>
                          <th scope="col">Discount</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {diamond?.map((item, index) => (
                          <tr className="align-middle" key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.cert_num ? item?.cert_num : '-'}</td>
                            <td>{item?.shape ? item?.shape : '-'}</td>
                            <td>{item?.carat ? item?.carat : '-'}</td>

                            <td>
                              $
                              {item?.price?.price
                                ? item?.price?.price?.toFixed(2)
                                : '-'}
                            </td>
                            <td>
                              %
                              {item?.price?.discount
                                ? item?.price?.discount?.toFixed(2)
                                : '-'}
                            </td>
                            <td>
                              $
                              {item?.price?.amount
                                ? item?.price?.amount?.toFixed(2)
                                : '-'}
                            </td>

                            <td className="">
                              <div className="d-flex">
                                <div
                                  className="edit-icon btn btn-sm btn-primary me-2"
                                  role="button"
                                  onClick={() => {
                                    navigate(`/edit-diamond/${item?._id}`);
                                  }}>
                                  <i className="bi bi-pencil-square"></i>
                                </div>
                                <div
                                  role="button"
                                  className="delete-icon btn btn-sm btn-danger"
                                  onClick={() => {
                                    setDeleteId(item?._id);
                                  }}>
                                  <i className="bi bi-trash"></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            {totalPages > 1 && (
              <Pagination className="align-items-center justify-content-center">
                {currentPage !== 1 && ( // Check if it's not the first page
                  <p
                    className="text-center mb-sm-0 pe-3 cursor-pointer"
                    onClick={handlePrevious}
                    role="button">
                    Previous
                  </p>
                )}
                {getPaginationItems()}
                {currentPage !== totalPages &&
                  diamond?.length > 0 && ( // Check if it's not the last page and there's data
                    <p
                      className="text-center mb-sm-0 ps-3 cursor-pointer"
                      onClick={handleNext}
                      role="button">
                      Next
                    </p>
                  )}
              </Pagination>
            )}
          </div>
        </section>
        <DeleteModal
          show={dataContext.showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Diamond"
          body="Are you sure you want to delete this record?"
        />
      </main>
    </div>
  );
};

export default GetDiamond;
