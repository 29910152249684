import React, {useContext, useEffect} from 'react';
import {fetchDataFromAPI} from '../../../utils/api';
import {DataContext} from '../../../context/dataContext';
import Processing from '../../../components/Processing/Processing';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {getUser} from '../../../utils/constants';
import {Link} from 'react-router-dom';
const MarkupPage = () => {
  const dataContext = useContext(DataContext);
  const user = JSON.parse(getUser());
  useEffect(() => {
    getMarkup();
  }, []);
  const getMarkup = () => {
    dataContext.setLoading(true);
    fetchDataFromAPI('AdminMarkup', 'get', '', '')
      .then((res) => {
        console.log('res', res?.data);
        formik.setFieldValue('mark_up', res?.data?.mark_up);
        formik.setFieldValue(
          'additional_mark_up',
          res?.data?.additional_mark_up,
        );
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        dataContext.setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      mark_up: '',
      additional_mark_up: '',
    },
    validationSchema: Yup.object({
      mark_up: Yup.string().required('Please enter markup'),
      additional_mark_up: Yup.string().required(
        'Please enter additional mark up',
      ),
    }),
    onSubmit: (values) => {
      const {mark_up, additional_mark_up} = values;

      const body = {
        mark_up: mark_up,
        additional_mark_up: additional_mark_up,
      };

      console.log('body', body);

      dataContext.setLoading(true);
      try {
        fetchDataFromAPI(`AdminMarkup`, 'put', body, user?.authToken)
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            dataContext.setLoading(false);
            getMarkup();
            console.log('res', res);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext.setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        dataContext.setLoading(false);
      }
    },
  });

  return (
    <div>
      <main id="main" className="main">
        {dataContext.loading && <Processing />}
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              {/* <li className="breadcrumb-item">
                <Link to="/location">Location</Link>
              </li> */}
              <li className="breadcrumb-item active">
                <span>Markup Price</span>
              </li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}

                <div className="tab-content">
                  <div className="pt-3" id="profile-edit">
                    {/* Profile Edit Form */}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mb-3"></div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="from" className="form-label">
                            Mark Up%
                          </label>

                          <input
                            name="color"
                            type="text"
                            className="form-control"
                            id="color"
                            value={formik.values.mark_up}
                            onChange={(e) => {
                              const cleanedValue = e.target.value.trim(); // Trim whitespace
                              const numericValue = cleanedValue.replace(
                                /[^0-9+-]/g, // Allow only digits, '+' and '-'
                                '',
                              );

                              formik.setFieldValue('mark_up', numericValue); // Set cleaned numeric value to 'mark_up' field
                            }}
                          />
                          {formik.touched.mark_up && formik.errors.mark_up && (
                            <div className="text-danger">
                              {formik.errors.mark_up}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="from" className="form-label">
                            Additional Mark Up $
                          </label>

                          <input
                            name="color"
                            type="text"
                            className="form-control"
                            id="color"
                            value={formik.values.additional_mark_up}
                            onChange={(e) => {
                              const cleanedValue = e.target.value.trim(); // Trim whitespace
                              const numericValue = cleanedValue.replace(
                                /[^0-9+-]/g, // Allow only digits, '+' and '-'
                                '',
                              ); // Remove non-numeric characters

                              formik.setFieldValue(
                                'additional_mark_up',
                                numericValue,
                              ); // Set cleaned numeric value to 'mark_up' field
                            }}
                          />
                          {formik.touched.additional_mark_up &&
                            formik.errors.additional_mark_up && (
                              <div className="text-danger">
                                {formik.errors.additional_mark_up}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext.loading}
                          onClick={formik.handleSubmit}
                          label="Edit Markup"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default MarkupPage;
