import React, {useContext, useEffect, useState} from 'react';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import {Link, useNavigate} from 'react-router-dom';
import {DataContext} from '../../../context/dataContext';
import {fetchDataFromAPI} from '../../../utils/api';
import {toast} from 'react-toastify';
import moment from 'moment';
import Processing from '../../../components/Processing/Processing';
import {getUser} from '../../../utils/constants';
import {Pagination} from 'react-bootstrap';
import ViewClientModel from '../../../components/ViewClientModel/ViewClientModel';

const OrderList = () => {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const dataContext = useContext(DataContext);
  const [deleteId, setDeleteId] = useState(null);
  const user = JSON.parse(getUser());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [clientModel, setClientModel] = useState(false);
  const [clientData, setClientData] = useState();

  useEffect(() => {
    getOrderList(currentPage);
  }, [currentPage]);

  const closeClientModel = () => {
    setClientModel(false);
  };

  const clientModelShow = (item) => {
    setClientData(item);
    setClientModel(true);
  };

  const getOrderList = (page) => {
    dataContext?.setLoading(true);

    fetchDataFromAPI(
      `admin/getAllOrderList?page=${page}`,
      'get',
      '',
      user?.authToken,
    )
      .then((res) => {
        console.log('res ---->', res);
        setOrderList(res?.data);
        setTotalPages(res?.pages?.totalPages);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('error overtone', error);
        dataContext?.setLoading(false);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getOrderList(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getOrderList(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            className={'custom-pagination'}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button"
            linkClassName={i === currentPage ? 'link-pagination' : ''}>
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  // this use Dffect for delete id
  useEffect(() => {
    if (deleteId) {
      dataContext.setShowDeleteModal(true);
    } else {
      dataContext.setShowDeleteModal(false);
    }
  }, [deleteId]);

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete Color Type
  const deleteContent = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPI(
      `admin/deleteBuyerRequest?buyer_request_id=${deleteId}`,
      'delete',
      '',
      user?.authToken,
    )
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getOrderList();
        setDeleteId(null);
        dataContext?.setShowDeleteModal(false);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Content error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        dataContext?.setLoading(false);
      });
  };

  const updateStatus = (id, status) => {
    console.log('id', id);
    console.log('status', status);

    // setUpdatingId(id);
    dataContext?.setLoading(true);

    const body = {
      status: status,
    };

    fetchDataFromAPI(`PlaceOrder?id=${id}`, 'put', body, user?.authToken)
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-success text-white',
        });
        getOrderList();
        // setUpdatingId(null);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        console.log('Status update error: ', error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        // setUpdatingId(null);
        dataContext?.setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        {/* <h1>intensity</h1> */}
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>

            <li className="breadcrumb-item active">Order Data</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-lg-between btn-View">
                  <div>
                    {/* <h3 className=" font-weight-bold">Users List</h3> */}
                  </div>
                </div>
                <h5 className="card-title">Order Data</h5>
                {/* Default Table */}
                <div className="table-responsive">
                  <table className="table table-hover text-wrap">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        {/* <th scope="col">Image</th> */}
                        <th scope="col">Diamond Certificate</th>
                        <th scope="col">Carat</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Total Psc</th>
                        <th scope="col">User Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">View Seller</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderList?.length === 0 && (
                        <tr>
                          <td colSpan={12} className="text-center">
                            No Order Data Available
                          </td>
                        </tr>
                      )}
                      {orderList?.map((item, index) => {
                        return (
                          <tr className="align-middle">
                            <th scope="row">{index + 1}</th>
                            {/* <td>
                          <img
                            src={
                              item?.image
                                ? item?.image
                                : require('../../../assets/images/account.png')
                            }
                            alt="Profile"
                            style={{height: 50, width: 50}}
                            className="rounded-circle"
                          />
                        </td> */}
                            <td>
                              {item?.diamond_ids?.filter(
                                (diamondItem) => diamondItem?.diamond?.cert_num,
                              )?.length > 0 ? (
                                item.diamond_ids
                                  .filter(
                                    (diamondItem) =>
                                      diamondItem?.diamond?.cert_num,
                                  ) // Filter out null diamond items
                                  .map((diamondItem, index, filteredArray) => (
                                    <span key={index}>
                                      {diamondItem?.diamond?.cert_num}
                                      {/* Only show a comma if it's not the last valid item */}
                                      {index < filteredArray?.length - 1 &&
                                        ', '}
                                    </span>
                                  ))
                              ) : (
                                <span>-</span>
                              )}
                            </td>

                            <td>{item?.size ? item?.size?.toFixed(2) : '-'}</td>
                            <td>
                              {item?.total_amt
                                ? `$${item?.total_amt?.toFixed(2)}`
                                : '-'}
                            </td>
                            <td>{item?.total_psc ? item?.total_psc : '-'}</td>
                            <td>
                              {item?.user_details?.first_name &&
                              item?.user_details?.last_name
                                ? `${item.user_details.first_name} ${item.user_details.last_name}`
                                : '-'}
                            </td>

                            <td>
                              {item?.user_details?.email
                                ? item?.user_details?.email
                                : '-'}
                            </td>
                            <td>
                              {item?.user_details?.phone
                                ? item?.user_details?.phone
                                : '-'}
                            </td>
                            <td className="text-center">
                              <div
                                className="edit-icon btn btn-sm btn-primary"
                                onClick={() => clientModelShow(item)}>
                                <i class="bi bi-eye-fill"></i>
                              </div>
                            </td>
                            <td>
                              <select
                                className="form-select w-auto"
                                value={item?.status}
                                onChange={(e) =>
                                  updateStatus(item?._id, e.target.value)
                                }
                                // disabled={updatingId === item?._id}
                              >
                                <option value="Checking for availability">
                                  Checking for availability
                                </option>
                                <option value="Ready for payment">
                                  Ready for payment
                                </option>
                                <option value="Payment Confirmed">
                                  Payment Confirmed
                                </option>
                                <option value="Payment cancelled">
                                  Payment cancelled
                                </option>
                                <option value="Checking for QC">
                                  Checking for QC
                                </option>
                                <option value="QC Approved">QC Approved</option>
                                <option value="QC Cancelled">
                                  QC Cancelled
                                </option>
                                <option value="Ready for Shipment">
                                  Ready for Shipment
                                </option>
                                <option value="Delivered">Delivered</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                            </td>

                            {/* <td className="">
                            <div className="d-flex">
                              <div
                                className="edit-icon btn btn-sm btn-primary me-2"
                                role="button"
                                onClick={(e) => {
                                  navigate(`/edit-over-tone/${item?._id}`, {
                                    state: {user: item},
                                  });
                                }}>
                                <i class="bi bi-pencil-square"></i>
                              </div>
                              <div
                                role="button"
                                className="delete-icon btn btn-sm btn-danger"
                           
                                onClick={(e) => {
                                  setDeleteId(item?._id);
                                }}>
                                <i class="bi bi-trash"></i>
                              </div>
                            </div>
                          </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* End Default Table Example */}
              </div>
            </div>
          </div>
        </div>
        {totalPages > 1 && (
          <Pagination className="align-items-center justify-content-center">
            {currentPage !== 1 && ( // Check if it's not the first page
              <p
                className="text-center mb-sm-0 pe-3 cursor-pointer"
                onClick={handlePrevious}
                role="button">
                Previous
              </p>
            )}
            {getPaginationItems()}
            {currentPage !== totalPages &&
              orderList?.length > 0 && ( // Check if it's not the last page and there's data
                <p
                  className="text-center mb-sm-0 ps-3 cursor-pointer"
                  onClick={handleNext}
                  role="button">
                  Next
                </p>
              )}
          </Pagination>
        )}
      </section>

      <ViewClientModel
        show={clientModel}
        onHide={closeClientModel}
        data={clientData}
      />

      <DeleteModal
        show={dataContext.showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={deleteContent}
        title="Delete Order List"
        body="Are you sure you want to delete this record?"
      />
    </main>
  );
};

export default OrderList;
